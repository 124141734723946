import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { DropdownOption } from 'src/app/models/dropdown_option';
import { RegionService } from 'src/app/services/region.service';
import { SpeciesService } from 'src/app/services/species/species.service';
// import { SpeciesService } from 'src/app/services/species.service';

@Component({
  selector: 'app-add-edit-species',
  templateUrl: './add-edit-species.component.html',
  styleUrls: ['./add-edit-species.component.scss']
})
export class AddEditSpeciesComponent implements OnInit {
  public form: FormGroup;

  public common_name = new FormControl('', Validators.required);
  public scientific_name = new FormControl('', Validators.required);
  public group_name = new FormControl('', Validators.required);
  public region = new FormControl('');

  public submitted: boolean;
  public selectedFiles?: File;
  public selectedFileNames: string;
  public previews: string[] = [];
  public currentImage: string;
  public regions: DropdownOption[];
  private selectedRegion: string;

  isEditComponent = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _regionSvc: RegionService,
    private fb: FormBuilder,
    private _spcSvc: SpeciesService,
    public dialogRef: MatDialogRef<AddEditSpeciesComponent>,
    private matSnackBar: MatSnackBar,
  ) {
    this.form = fb.group({
      'common_name': this.common_name,
      'scientific_name': this.scientific_name,
      'group_name': this.group_name,
      'region': this.region
    })

    this.regions = []
    this.submitted = false;
    this.selectedRegion = ''

    this._regionSvc.findApi(0, 100)
      .subscribe(data => {
        data.rows.forEach(region => this.regions.push({
          title: region.name, value: region.id.toString()
        }))

        if (this.isEditComponent) {
          this.form.controls['region'].setValue(this.data.specie.region_id + '')
        }
      })
  }

  get f(): any { return this.form.controls; }

  ngOnInit() {
    if (this.data && this.data.isEditComponent) {
      this.isEditComponent = true;
      this.form.controls['common_name'].setValue(this.data.specie.common_name);
      this.form.controls['scientific_name'].setValue(this.data.specie.scientific_name);
      this.form.controls['group_name'].setValue(this.data.specie.group_name);
      this.currentImage = this.data.specie.image_url;
    }
  }

  selectFiles(event: any): void {
    this.selectedFiles = event.target.files[0];
    this.selectedFileNames = this.selectedFiles.name;

    this.previews = [];

    if (this.selectedFiles) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.currentImage = e.target.result
      };

      reader.readAsDataURL(this.selectedFiles);
    }
  }

  uploadFiles(specieId?) {
    if (this.selectedFiles) {
      this._spcSvc.uploadApi(this.selectedFiles, specieId ? specieId : this.data.specie.id).subscribe(
        res => {
        },
        err => {
          this.matSnackBar.open('Error uploading image: ' + err, "Cerrar", { duration: 3000 });
        }
      )
    }
  }

  submit(): void {
    this.submitted = true;
    if (this.form.valid) {
      let specie = {
        common_name: this.form.get('common_name').value,
        scientific_name: this.form.get('scientific_name').value,
        group_name: this.form.get('group_name').value,
        region_id: this.form.get('region').value,
      }

      if (this.isEditComponent) {
        this._spcSvc.updateApi(this.data.specie.id, specie).subscribe(
          response => {
            if (response.confirmation == true) {
              this.uploadFiles()
              this.matSnackBar.open('Specie updated succesfully', 'Cerrar', { duration: 4000 });
            }
            this.dialogRef.close();
          },
          err => {
            console.log(err);
            if (err.error.confirmation == false) {
              this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', { duration: 4000 });
            }
          }
        )
        return;
      }

      this._spcSvc.createApi(specie).subscribe(
        response => {
          if (response.confirmation == true) {
            this.uploadFiles(response.specie.id)
            this.matSnackBar.open('Specie added succesfully', 'Cerrar', { duration: 4000 });
          }
          this.dialogRef.close();
        },
        err => {
          console.log(err);
          if (err.error.confirmation == false) {
            this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', { duration: 4000 });
          }
        }
      )
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
