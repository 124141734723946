import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatMenuModule, MatSelectModule } from '@angular/material';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { LoaderComponent } from './loader/loader.component';
import { PrimaryButtonComponent } from './primary-button/primary-button.component';
import { TextComponent } from './text/text.component';
import { SecondaryButtonComponent } from './secondary-button/secondary-button.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { DividerHorizontalComponent } from './divider-horizontal/divider-horizontal.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectorSearcherComponent } from './selector-searcher/selector-searcher.component';
import { InputSearcherComponent } from './input-searcher/input-searcher.component';
import { TableHeaderToggleComponent } from './table-header-toggle/table-header-toggle.component';
import { TableHeaderMenuComponent } from './table-header-menu/table-header-menu.component';
import { SearcherInputComponent } from './searcher-input/searcher-input.component';

import { LottieModule } from 'ngx-lottie';
import { NetworkStatusErrorComponent } from './network-status-error/network-status-error.component';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    DividerHorizontalComponent,
    HeaderMenuComponent, // TODO - delete
    InputSearcherComponent,
    LoaderComponent,
    PaginatorComponent,
    PrimaryButtonComponent,
    SearcherInputComponent,
    SecondaryButtonComponent,
    SelectorSearcherComponent,
    TableHeaderMenuComponent,
    TableHeaderToggleComponent,
    TextComponent,
    NetworkStatusErrorComponent,
  ],
  imports: [
    CommonModule,


    // forms
    FormsModule,
    ReactiveFormsModule,


    // material
    MatAutocompleteModule,
    MatButtonModule,
    MaterialModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,

    LottieModule.forRoot({ player: playerFactory }),

  ],
  exports: [
    DividerHorizontalComponent,
    HeaderMenuComponent,
    InputSearcherComponent,
    LoaderComponent,
    PaginatorComponent,
    PrimaryButtonComponent,
    SearcherInputComponent,
    SecondaryButtonComponent,
    SelectorSearcherComponent,
    TableHeaderMenuComponent,
    TableHeaderToggleComponent,
    TextComponent,
    NetworkStatusErrorComponent,
  ],
})
export class ComponentsModule { }
