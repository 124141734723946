import { AbstractControl, FormControl, ValidationErrors } from "@angular/forms";

// if property SHOULD BE validate then set <<the rules>> into method [validator]
// if property SHOULD NOT BE validate then return <<null>> into method [validator]

export abstract class Input<T> {

    public FIELD_NAME: string;
    private _formControl = new FormControl("", [this.validator]);

    public get formControl(): FormControl {
        return this._formControl
    };

    public get value(): T { return this.formControl.value; }
    public set value(label: T) { this._formControl.setValue(label); }

    abstract validator(formControl: AbstractControl): ValidationErrors | null;

    public getErrors(): string | null {

        const errorsObject = this._formControl.errors;
        if (errorsObject == null) return null;
        return Object.values(errorsObject)[0];

    }

    public setErrors(validationError: ValidationErrors) {

        this._formControl.setErrors(validationError);

    }
}
