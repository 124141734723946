import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MaterialModule } from './material.module';
import { MatIconModule } from '@angular/material/icon';
import { LoginComponent } from './components/login/login.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Auth } from './guards/auth.guard';
import { BoatsTableComponent } from './components/boat/boats-table/boats-table.component';
import { UsersTableComponent } from './components/user/users-table/users-table.component';
import { LogsTableComponent } from './components/logentry/logs-table/logs-table.component';
import { OrganizationsTableComponent } from './components/organization/organizations-table/organizations-table.component';
import { PermitsTableComponent } from './components/permit/permits-table/permits-table.component';
import { RolesTableComponent } from './components/role/roles-table/roles-table.component';
import { AddBoatComponent } from './components/boat/add-boat/add-boat.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AddUserComponent } from './components/user/add-user/add-user.component';
import { AddEditOrganizationComponent } from './components/organization/add-edit-organization/add-edit-organization.component';
import { OrgSettingsComponent } from './components/organization/org-settings/org-settings.component';
import { PostsComponent } from './components/posts/posts.component';

import { NgImageSliderModule } from 'ng-image-slider';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { DemographyComponent } from './components/demography/demography.component';
import { AddEmergencyContactComponent } from './components/demography/add-emergency-contact/add-emergency-contact.component';
import { DataVisualizationComponent } from './components/data-visualization/data-visualization.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { FlaggedPostComponent } from './components/flagged/flagged-post/flagged-post.component';
import { FlaggedCommentsComponent } from './components/flagged/flagged-comments/flagged-comments.component';
import { FlaggedComponent } from './components/flagged/flagged.component';
import { EditDemographyComponent } from './components/demography/edit-demography/edit-demography.component';
import { FishingPermitsComponent } from './components/fishing-permits/fishing-permits.component';
import { AddFishingPermitsComponent } from './components/fishing-permits/add-fishing-permits/add-fishing-permits.component';
import { HarborMasterPermitComponent } from './components/harbor-master-permit/harbor-master-permit.component';
import { HealthCertificationComponent } from './components/health-certification/health-certification.component';
import { AddEditHarborMasterComponent } from './components/harbor-master-permit/add-edit-harbor-master/add-edit-harbor-master.component';
import { AddEditHealthCertificationComponent } from './components/health-certification/add-edit-health-certification/add-edit-health-certification.component';
import { AddCertificateComponent } from './components/certificates/add-certificate/add-certificate.component';
import { CertificatesComponent } from './components/certificates/certificates.component';
import { SolutionsComponent } from './components/solutions/solutions.component';
import { AddEditSolutionComponent } from './components/solutions/add-edit-solution/add-edit-solution.component';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { SpeciesComponent } from './components/species/species.component';
import { AddEditSpeciesComponent } from './components/species/add-edit-species/add-edit-species.component';
import { SurveysComponent } from './components/surveys/surveys.component';
import { SurveyAnswersComponent } from './components/surveys/survey-answers/survey-answers.component';
import { SurveyAnalyticsComponent } from './components/survey-analytics/survey-analytics.component'
import { AlertsModule } from './shared/alerts/alerts.module';


import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { AuthenticateModule } from './authenticate/authenticate.module';
import { ComponentsModule } from './shared/components/components.module';
import { ModalLogoutComponent } from './components/modal-logout/modal-logout.component';
import { MAT_DATE_LOCALE } from '@angular/material';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

registerLocaleData(localeEs)
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainLayoutComponent,
    BoatsTableComponent,
    UsersTableComponent,
    LogsTableComponent,
    OrganizationsTableComponent,
    PermitsTableComponent,
    RolesTableComponent,
    AddBoatComponent,
    ResetPasswordComponent,
    AddUserComponent,
    AddEditOrganizationComponent,
    OrgSettingsComponent,
    PostsComponent,
    VerifyEmailComponent,
    DemographyComponent,
    AddEmergencyContactComponent,
    DataVisualizationComponent,
    FlaggedPostComponent,
    FlaggedCommentsComponent,
    FlaggedComponent,
    EditDemographyComponent,
    FishingPermitsComponent,
    AddFishingPermitsComponent,
    HarborMasterPermitComponent,
    HealthCertificationComponent,
    AddEditHarborMasterComponent,
    AddEditHealthCertificationComponent,
    AddCertificateComponent,
    CertificatesComponent,
    SolutionsComponent,
    AddEditSolutionComponent,
    SpeciesComponent,
    AddEditSpeciesComponent,
    SurveysComponent,
    SurveyAnswersComponent,
    SurveyAnalyticsComponent,
    ModalLogoutComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgImageSliderModule,
    HighchartsChartModule,
    MaterialFileInputModule,
    FlexLayoutModule,
    AuthenticateModule,
    ComponentsModule,
    // F3
    AlertsModule,
    // thirds
    LottieModule.forRoot({ player: playerFactory }),

  ],
  entryComponents: [
    ModalLogoutComponent
  ],
  exports: [
    MatIconModule
  ],
  providers: [
    Auth,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
