import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatTable } from '@angular/material';
import { merge, observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { BoatService } from 'src/app/services/boat.service';
import { HarborMasterPermitsService } from 'src/app/services/harbor-master-permits.service';
import { AddEditHarborMasterComponent } from './add-edit-harbor-master/add-edit-harbor-master.component';

@Component({
  selector: 'app-harbor-master-permit',
  templateUrl: './harbor-master-permit.component.html',
  styleUrls: ['./harbor-master-permit.component.scss']
})
export class HarborMasterPermitComponent implements AfterViewInit {
  public data: any = [];
  public displayedColumns: string[] = ['actions', 'permit_id', 'boat_id', 'start_date', 'expiration_date', 'renewal_reminder', 'renewal_reminder_date'];
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  
  public isLoadingResults: boolean;
  public resultsLength: number;
  public deleteHarborIdHolder;
  public deleteDialogRef;


  constructor(
    private _hmPermitService: HarborMasterPermitsService,
    public dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private _boatService: BoatService
  ) {
    this.isLoadingResults = true;
    this.resultsLength = 0;
   }


  ngAfterViewInit(): void {
    this.loadData();
  }


  loadData() {
    merge(this.paginator.page, this.paginator.pageSize)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this._hmPermitService.findApi(this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data["count"];
          return data.rows;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe((data) => {
        this.data = data;
      })
  }

  edit(id) {
    let currentHmPermit;
    this.data.forEach(harbor => {
      if(harbor['id'] == id) {
        currentHmPermit = harbor;
      }
    })

    let editDialogRef = this.dialog.open(AddEditHarborMasterComponent, {
      width: '500px',
      height: 'auto',
      data: {isEditComponent: true, harbor: currentHmPermit}
    })

    editDialogRef.afterClosed().subscribe(result => {
      this.loadData();
    })
  }

  delete(deletedialog, id) {
    this.deleteHarborIdHolder = id;
    this.deleteDialogRef = this.dialog.open(deletedialog, {
      width: '500px'
    })

    this.deleteDialogRef.afterClosed().subscribe(
      result => {
        this.loadData();
      }
    )
  }

  confirmDelete() {
    this._hmPermitService.deleteApi(this.deleteHarborIdHolder)
      .subscribe(
        result => {
          if(result.confirmation) {
            this.data.foreach((harbor, index) => {
              if(harbor.id == this.deleteHarborIdHolder) {
                this.data.splice(index, 1);
                this.table.renderRows();
              }
            })
            this.matSnackBar.open('Permiso de capitanía de puerto borrado con éxito', 'Cerrar', {duration: 4000});
            return;
          }
          if(!result.confirmation) {
            this.matSnackBar.open('Error al borrar permiso de capitanía de puerto', 'Cerrar', {duration: 4000});
          }
        },
        err => {
          console.log(err);
        }
      )
      this.deleteDialogRef.close();
  }

  cancelDeleteDialog() {
    this.deleteDialogRef.close();
  }

  openAddDialog() {
      const dialogRef = this.dialog.open(AddEditHarborMasterComponent, {
        width: '500px',
        height: 'auto'
      })

      dialogRef.afterClosed().subscribe(result => {
        this.loadData();
      })
  }

}
