import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { MainLayoutComponent } from "./components/main-layout/main-layout.component";
import { Auth } from "./guards/auth.guard";
import { UsersTableComponent } from "./components/user/users-table/users-table.component";
import { OrganizationsTableComponent } from "./components/organization/organizations-table/organizations-table.component";
import { BoatsTableComponent } from "./components/boat/boats-table/boats-table.component";
import { LogsTableComponent } from "./components/logentry/logs-table/logs-table.component";
import { RolesTableComponent } from "./components/role/roles-table/roles-table.component";
import { AddBoatComponent } from "./components/boat/add-boat/add-boat.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { AddUserComponent } from "./components/user/add-user/add-user.component";
import { AddEditOrganizationComponent } from "./components/organization/add-edit-organization/add-edit-organization.component";
import { RoleGuard } from "./guards/authorization.guard";
import { ADMIN_ROLE, CO_ADMIN_ROLE } from "./models/roles";
import { OrgSettingsComponent } from "./components/organization/org-settings/org-settings.component";
import { PostsComponent } from "./components/posts/posts.component";
import { VerifyEmailComponent } from "./components/verify-email/verify-email.component";
import { DemographyComponent } from "./components/demography/demography.component";
import { AddEmergencyContactComponent } from "./components/demography/add-emergency-contact/add-emergency-contact.component";
import { DataVisualizationComponent } from "./components/data-visualization/data-visualization.component";
import { FlaggedPostComponent } from "./components/flagged/flagged-post/flagged-post.component";
import { FlaggedCommentsComponent } from "./components/flagged/flagged-comments/flagged-comments.component";
import { FlaggedComponent } from "./components/flagged/flagged.component";
import { EditDemographyComponent } from "./components/demography/edit-demography/edit-demography.component";
import { FishingPermitsComponent } from "./components/fishing-permits/fishing-permits.component";
import { AddFishingPermitsComponent } from "./components/fishing-permits/add-fishing-permits/add-fishing-permits.component";
import { HarborMasterPermitComponent } from "./components/harbor-master-permit/harbor-master-permit.component";
import { HealthCertificationComponent } from "./components/health-certification/health-certification.component";
import { AddEditHarborMasterComponent } from "./components/harbor-master-permit/add-edit-harbor-master/add-edit-harbor-master.component";
import { AddEditHealthCertificationComponent } from "./components/health-certification/add-edit-health-certification/add-edit-health-certification.component";
import { CertificatesComponent } from "./components/certificates/certificates.component";
import { AddCertificateComponent } from "./components/certificates/add-certificate/add-certificate.component";
import { SolutionsComponent } from "./components/solutions/solutions.component";
import { AddEditSolutionComponent } from "./components/solutions/add-edit-solution/add-edit-solution.component";
import { SpeciesComponent } from "./components/species/species.component";
import { AddEditSpeciesComponent } from "./components/species/add-edit-species/add-edit-species.component";
import { SurveysComponent } from "./components/surveys/surveys.component";
import { SurveyAnswersComponent } from "./components/surveys/survey-answers/survey-answers.component";
import { SurveyAnalyticsComponent } from "./components/survey-analytics/survey-analytics.component";

const routes: Routes = [


  // ============================================================
  // LOGIN
  // ============================================================
  { path: "", component: LoginComponent },
  { path: "login", component: LoginComponent },
  {
    path: "login2",
    loadChildren: () =>
      import("./login/login.module").then((module) => module.LoginModule),
  },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "verify-email", component: VerifyEmailComponent },


  // ============================================================
  // DASHBOARD
  // ============================================================
  {
    path: "dashboard",
    component: MainLayoutComponent,
    canActivate: [Auth, RoleGuard],
    data: {
      roles: [ADMIN_ROLE, CO_ADMIN_ROLE],
    },
    children: [
      // ============================================================
      // Dashboard Routes
      // ============================================================
      {
        path: "main",
        canActivate: [Auth, RoleGuard],
        data: { roles: [ADMIN_ROLE, CO_ADMIN_ROLE] },
        loadChildren: () =>
          import("./dashboard/dashboard.module").then(
            (module) => module.DashboardModule
          ),
      },
      // ============================================================
      // users
      // ============================================================
      // NEW
      {
        path: "users2",
        loadChildren: () => import("./users/users.module").then((module) => module.UsersModule),
        canActivate: [Auth, RoleGuard],
        data: {
          roles: [ADMIN_ROLE, CO_ADMIN_ROLE]
        }
      },
      {
        path: "users-op",
        loadChildren: () =>
          import("./usersop/users-op.module").then(
            (module) => module.UsersOpModule
          ),
        // loadChildren: () => import('./welcome/welcome.module').then(module => module.WelcomeModule),
        // canActivate: [Auth, RoleGuard],
        // data: {
        //   roles: [ADMIN_ROLE, CO_ADMIN_ROLE]
        // }
      },
      // OLD
      {
        path: "users",
        component: UsersTableComponent,
        canActivate: [Auth, RoleGuard],
        data: {
          roles: [ADMIN_ROLE, CO_ADMIN_ROLE],
        },
      },
      {
        path: "users/add",
        component: AddUserComponent,
        canActivate: [Auth, RoleGuard],
        data: {
          roles: [ADMIN_ROLE, CO_ADMIN_ROLE],
        },
      },

      // ============================================================
      // Organization Routes
      // ============================================================
      {
        path: "cooperativas",
        canActivate: [Auth, RoleGuard],
        data: { roles: [ADMIN_ROLE] },
        loadChildren: () =>
          import("./cooperativas/cooperativas.module").then(
            (module) => module.CooperativasModule
          ),
      },
      // Organization Routes
      {
        path: "cooperativas-op",
        loadChildren: () =>
          import("./cooperativas-op/cooperativas-op.module").then(
            (module) => module.CooperativasOpModule
          ),
      },
      {
        path: "organizations",
        component: OrganizationsTableComponent,
        canActivate: [Auth, RoleGuard],
        data: {
          roles: [ADMIN_ROLE],
        },
      },
      {
        path: "organizations/add",
        component: AddEditOrganizationComponent,
        canActivate: [Auth, RoleGuard],
        data: {
          roles: [ADMIN_ROLE],
        },
      },
      {
        path: "organizations/settings",
        component: OrgSettingsComponent,
        canActivate: [Auth, RoleGuard],
        data: {
          roles: [CO_ADMIN_ROLE],
        },
      },

      // Boats Routes
      {
        path: "boats2",
        loadChildren: () => import("./boats/boats.module").then((module) => module.BoatsModule),
        canActivate: [Auth, RoleGuard],
        data: {
          roles: [ADMIN_ROLE, CO_ADMIN_ROLE],
        },
      },
      {
        path: "boats-op",
        loadChildren: () =>
          import("./boats-op/boats-op.module").then(
            (module) => module.BoatsOpModule
          ),
      },
      {
        path: "boats",
        component: BoatsTableComponent,
        canActivate: [Auth, RoleGuard],
        data: {
          roles: [ADMIN_ROLE, CO_ADMIN_ROLE],
        },
      },
      {
        path: "boats/add",
        component: AddBoatComponent,
        canActivate: [Auth, RoleGuard],
        data: {
          roles: [ADMIN_ROLE, CO_ADMIN_ROLE],
        },
      },

      // Logs Routes
      {
        path: "logs_2",
        loadChildren: () => import("./logs/logs.module").then((module) => module.LogsModule),
        canActivate: [Auth, RoleGuard],
        data: {
          roles: [ADMIN_ROLE, CO_ADMIN_ROLE],
        },
      },
      {
        path: "logs",
        component: LogsTableComponent,
        canActivate: [Auth, RoleGuard],
        data: {
          roles: [ADMIN_ROLE, CO_ADMIN_ROLE],
        },
      },
      // Users Routes
      {
        path: "logs-op",
        loadChildren: () =>
          import("./logs-op/logs-op.module").then(
            (module) => module.LogsOpModule
          ),
        // loadChildren: () => import('./welcome/welcome.module').then(module => module.WelcomeModule),
        // canActivate: [Auth, RoleGuard],
        // data: {
        //   roles: [ADMIN_ROLE, CO_ADMIN_ROLE]
        // }
      },
      {
        path: "roles",
        component: RolesTableComponent,
        canActivate: [Auth],
        data: {
          roles: [ADMIN_ROLE, CO_ADMIN_ROLE],
        },
      },

      // Marketplace Routes
      {
        path: "marketplace",
        component: PostsComponent,
        canActivate: [Auth, RoleGuard],
        data: {
          roles: [ADMIN_ROLE],
        },
      },
      // Analytics
      {
        path: "new_demography",
        canActivate: [Auth, RoleGuard],
        data: { roles: [ADMIN_ROLE] },
        loadChildren: () =>
          import("./ubicaciones/ubicaciones.module").then(
            (module) => module.UbicacionesModule
          ),
      },
      // demography Routes
      {
        path: "demography",
        component: DemographyComponent,
        canActivate: [Auth],
        data: {
          roles: [ADMIN_ROLE],
        },
      },
      {
        path: "demography/edit",
        component: EditDemographyComponent,
        canActivate: [Auth],
        data: {
          roles: [ADMIN_ROLE],
        },
      },
      //emergencyContact
      {
        path: "demography/add",
        component: AddEmergencyContactComponent,
        canActivate: [Auth],
        data: {
          roles: [ADMIN_ROLE],
        },
      },

      // ============================================================
      // Data Visualization
      // ============================================================
      {
        path: "statistics",
        component: DataVisualizationComponent,
        canActivate: [Auth],
        data: {
          roles: [CO_ADMIN_ROLE],
        },
      },

      // ============================================================
      // analysis
      // ============================================================
      {
        path: "analisis",
        loadChildren: () => import("./analytics/analytics.module").then((module) => module.AnalyticsModule),
        canActivate: [Auth],
        data: {
          roles: [CO_ADMIN_ROLE],
        },
      },

      // Flagged MarketPlace
      {
        path: "flagged",
        component: FlaggedComponent,
        canActivate: [Auth],
        data: {
          roles: [ADMIN_ROLE],
        },
        children: [
          {
            path: "posts",
            component: FlaggedPostComponent,
            canActivate: [Auth],
            data: {
              roles: [ADMIN_ROLE],
            },
          },
          {
            path: "comments",
            component: FlaggedCommentsComponent,
            canActivate: [Auth],
            data: {
              role: [ADMIN_ROLE],
            },
          },
        ],
      },

      // Fishing permits
      {
        path: "fishing-permits",
        component: FishingPermitsComponent,
        canActivate: [Auth],
        data: {
          role: [CO_ADMIN_ROLE],
        },
      },

      // Analytics
      {
        path: "fishing-permits-op",
        // canActivate: [Auth, RoleGuard],
        // data: { roles: [ADMIN_ROLE] },
        loadChildren: () =>
          import("./permits-fishing/permits-fishing.module").then(
            (module) => module.PermitsBoatsModule
          ),
      },

      {
        path: "fishing-permits/add",
        component: AddFishingPermitsComponent,
        canActivate: [Auth],
        data: {
          role: [CO_ADMIN_ROLE],
        },
      },

      // Harbor master permits

      //============================================================
      //
      // captaincy permits
      //
      // {
      //   path: "harbor-master-permits",
      //   component: HarborMasterPermitComponent,
      //   canActivate: [Auth],
      //   data: {
      //     role: [CO_ADMIN_ROLE],
      //   },
      // },
      // {
      //   path: "harbor-master-permits/add",
      //   component: AddEditHarborMasterComponent,
      //   canActivate: [Auth],
      //   data: {
      //     role: [CO_ADMIN_ROLE],
      //   },
      // },
      //
      //============================================================
      {
        path: "captaincy-permits",
        canActivate: [Auth],
        data: {
          role: [
            CO_ADMIN_ROLE,
          ],
        },
        loadChildren: () => import("./captaincy-permits/captaincy-permits.module").then(module => module.CaptaincyPermitsModule)
      },


      //============================================================
      //
      // captaincy permits
      //
      //
      //============================================================
      {
        path: 'health-certification-dev',
        canActivate: [Auth],
        data: {
          role: [CO_ADMIN_ROLE],
        },
        loadChildren: () => import("./health-certification/health-certification.module").then(module => module.HealthCertificationModule),
      },


      {
        path: "health-certification",
        component: HealthCertificationComponent,
        canActivate: [Auth],
        data: {
          role: [CO_ADMIN_ROLE],
        },
      },
      {
        path: "health-certification/add",
        component: AddEditHealthCertificationComponent,
        canActivate: [Auth],
        data: {
          role: [CO_ADMIN_ROLE],
        },
      },


      //============================================================
      // sustainability certification
      //============================================================
      {
        path: 'sustainability-certification',
        canActivate: [Auth],
        data: {
          role: [CO_ADMIN_ROLE],
        },
        loadChildren: () => import("./sustainability-certification/sustainability-certification.module").then(module => module.SustainabilityCertificationModule),
      },

      {
        path: "certifications",
        component: CertificatesComponent,
        canActivate: [Auth],
        data: {
          role: [CO_ADMIN_ROLE],
        },
      },
      {
        path: "certifications/add",
        component: AddCertificateComponent,
        canActivate: [Auth],
        data: {
          role: [CO_ADMIN_ROLE],
        },
      },



      // ============================================================
      // Solutions
      // ============================================================
      {
        path: 'soluciones',
        canActivate: [Auth, RoleGuard],
        data: {
          roles: [ADMIN_ROLE]
        },
        loadChildren: () => import("./solutions/solutions.module").then((module) => module.SolutionsModule),
      },
      {
        path: "solutions",
        component: SolutionsComponent,
        canActivate: [Auth],
        data: {
          role: [ADMIN_ROLE],
        },
      },
      {
        path: "solutions/add",
        component: AddEditSolutionComponent,
        canActivate: [Auth],
        data: {
          role: [ADMIN_ROLE],
        },
      },

      // ============================================================
      // 
      // ============================================================

      {
        path: "species2",
        // component: SpeciesComponent,

        loadChildren: () =>
          import("./fish-species/fish-species.module").then((module) => module.FishSpeciesModule),
        canActivate: [Auth],
        data: {
          role: [ADMIN_ROLE],
        },
      },

      //Species
      {
        path: "species",
        component: SpeciesComponent,
        canActivate: [Auth],
        data: {
          role: [ADMIN_ROLE],
        },
      },
      {
        path: "species/add",
        component: AddEditSpeciesComponent,
        canActivate: [Auth],
        data: {
          role: [ADMIN_ROLE],
        },
      },

      //Surveys
      {
        path: "surveys",
        component: SurveysComponent,
        canActivate: [Auth],
        data: {
          role: [CO_ADMIN_ROLE],
        },
      },
      // New surveys
      {
        path: "new_surveys",

        loadChildren: () =>
          import("./development-survey/development-survey.module").then(
            (module) => module.DevelopmentSurveyModule
          ),
      },
      // Contribs Routes
      {
        path: "contributions",
        canActivate: [Auth, RoleGuard],
        data: {
          roles: [ADMIN_ROLE]
        },
        loadChildren: () =>
          import("./contributions/contributions.module").then(
            (module) => module.ContributionsModule
          ),
      },
      {
        path: "answers",
        component: SurveyAnswersComponent,
        canActivate: [Auth],
        data: {
          role: [ADMIN_ROLE],
        },
      },
      // Analytics
      {
        path: "new_analytics",
        canActivate: [Auth, RoleGuard],
        data: { roles: [ADMIN_ROLE] },
        loadChildren: () =>
          import("./analytics/analytics.module").then((module) => module.AnalyticsModule),
      },

      // ============================================================
      // Analytics AG
      // ============================================================
      //Survey Analytics
      {
        path: "analytics",
        component: SurveyAnalyticsComponent,
        canActivate: [Auth],
        data: {
          role: [ADMIN_ROLE],
        },
      },
      {
        path: "analytics-dev",
        canActivate: [Auth],
        data: {
          roles: [ADMIN_ROLE]
        },
        loadChildren: () => import('./analytics-ag/analytics-ag.module').then(module => module.AnalyticsAgModule),
      },
      {
        path: '**',
        redirectTo: 'users2'
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
