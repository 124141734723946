import {Component, ViewChild, AfterViewInit, OnInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DropdownOption } from 'src/app/models/dropdown_option';
import { CooperativaService } from 'src/app/services/organization.service';
import { PostService } from 'src/app/services/posts.service';
import { isDateSet, dateFormat } from '../helpers/date_helper';
import { CommentService } from 'src/app/services/comment.service';


@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements AfterViewInit {
  displayedColumns: string[] = ['actions', 'user_fullname', 'type', 'title', 'description',  'price', 'sold', 'flagged', 'createdAt'];
  data: any;

  deletePostIdHolder;
  editPostIdHolder;
  deletePostDialogRef;

  viewPostDialogRef;
  currentPost;
  images: Array<object> = [];
  comments: any[];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  resultsLength: number;
  commentsLength: number;
  isLoadingResults: boolean;
  isLoadingComments: boolean;
  userSession: User;
  searchQuery: string;
  page: number;
  pageSize: number;

  constructor(
    public dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private authSvc: AuthService,
    private _postSvc: PostService,
    private commentSrv: CommentService
  ) {
    this.data = [];
    this.comments = [];
    this.resultsLength = 0;
    this.isLoadingResults = true;
    this.isLoadingComments = true;
    this.userSession = new User();
    let us = this.authSvc.getUserData();
    if (!us) {
      this.matSnackBar.open('Error loading user session.', 'Cerrar', {duration: 4000});
      return;
    }
    this.searchQuery = '';
    this.userSession = us;
    this.page = 0;
    this.pageSize = 5;
    this.commentsLength = 0;
  }

  ngAfterViewInit() {
    this.loadData();
  }

  deletePost(deleteDialog, id) {
    this.deletePostIdHolder = id;
    this.deletePostDialogRef = this.dialog.open(deleteDialog, {
      width: '500px'
    });
  }

  loadComments(id, page = 0, pageSize = 5) {
    this.commentSrv.findApi(page, pageSize, id).subscribe(
      (res) => {
        this.comments = res.comments.rows;
        this.commentsLength = res.comments.count;
        this.isLoadingComments = false;
      },
      (err) => {
        console.log(err);
      }
    )
  }

  viewPost(viewPostDialog, post) {
    this.currentPost = post;
    this.editPostIdHolder = this.currentPost.id
    this.loadComments(this.currentPost.id);
    this.currentPost.images.forEach(img => {
      this.images.push({
        image: img.path,
        thumbImage: img.path
      })
    })
    this.viewPostDialogRef = this.dialog.open(viewPostDialog, {
      width: '600px',
      height: '80vh',
    })
    this.viewPostDialogRef.afterClosed().subscribe(d => {
      this.currentPost = null;
      this.images = [];
      this.comments = [];
    })
  }

  deleteComment(id) {
    this.commentSrv.deleteApi(id).subscribe(
      (res) => {
        if(res.confirmation) {
          this.loadComments(this.editPostIdHolder);
        }
      },
      (err) => {
        console.log(err);
      }
    )
  }
  reviewComment(comment) {
    this.commentSrv.updateApi(comment.id, {
      reviewed: !comment.reviewed
    }).subscribe(
      res => {
        this.loadComments(this.editPostIdHolder);
      },
      (err) => {
        console.log(err);
      }
    )
  }

  cancelDialog() {
    this.deletePostDialogRef.close();
  }

  cancelPostDialog() {
    this.isLoadingComments = true;
    this.viewPostDialogRef.close();
  }

  confirmPostDelete() {
    this._postSvc.deleteApi(this.deletePostIdHolder)
      .subscribe(
        result => {
          if (result.confirmation == true) {
            this.data.forEach((user, index) => {
              if(user.id == this.deletePostIdHolder) {
                this.data.splice(index, 1)
                this.table.renderRows();
              }
            })
            this.matSnackBar.open('Post deleted succesfully', 'Cerrar', {duration: 4000});
            return;
          }
          if (result.confirmation == false) {
            this.matSnackBar.open('Error deleting post.', 'Cerrar', {duration: 4000});
          }
        },
        err => {
          console.log(err);
        }
      )
    this.deletePostDialogRef.close();
  }

  changePage(event) {
    this.loadComments(this.editPostIdHolder, event.pageIndex, this.pageSize);
  }

  filter() {
    this.loadData();
  }

  loadData() {
   merge(this.paginator.page, this.paginator.pageSize)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        return this._postSvc.findApi(this.paginator.pageIndex, this.paginator.pageSize, this.searchQuery);
      }),
      map(data => {
        this.isLoadingResults = false;
        this.resultsLength = data["count"];

        return data['rows'];
      }),
      catchError((err) => {
        console.log(err);
        this.isLoadingResults = false;
        return observableOf([]);
      })
    ).subscribe(data => this.data = data);
  }

  printDate(d: Date): string {
		let df = '01/02/2006 03:04 PM';
		if (isDateSet(d)) {
			return dateFormat(d, df);
		}
		return '';
  }
}
