import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from './httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationContactService {
  apiUrl: string;

  constructor(private httpClient: HttpclientService){
    this.apiUrl = environment.apiUrl;
  }

  findApi(page: number, pageSize:  number): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/coop/details?page=${page}&pageSize=${pageSize}`);
  }
  createApi(organizationContact: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/coop/details`, organizationContact);
  }
  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/coop/details/${id}`);
  }
  updateApi(id: number, organizationContact: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/coop/details/${id}`, organizationContact)
  }
  findByIdApi(id: number): Observable <any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/coop/details/${id}`)
  }

  uploadApi(files, id: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('image', files, files.name);

    return this.httpClient.sentFileWithAuth(`${this.apiUrl}/coop/details/${id}/image`, formData)
  }

}
