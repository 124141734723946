import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.scss']
})
export class SecondaryButtonComponent implements OnInit {

  @Output() clicked = new EventEmitter();
  @Input() public label: string = '[NOT-LABEL]';
  @Input() public isDisabled: boolean = false;

  constructor() { }

  ngOnInit() { }

  public click() {
    this.clicked.emit();
  }

}
