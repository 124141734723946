import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatListOption, MatPaginator, MatSelectionList, MatSelectionListChange, MatSnackBar, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { RegionService } from 'src/app/services/region.service';
import { StateService } from 'src/app/services/states/state.service';
import { CommunityService } from 'src/app/services/community/community.service';
import { State } from 'src/app/models/state';
import { Region } from 'src/app/models/region_list';
import { Community } from 'src/app/models/community';
import { SelectionModel } from '@angular/cdk/collections';
import { IfStmt, ThrowStmt } from '@angular/compiler';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmergencyContactService } from 'src/app/services/emergency-contact.service';
import { AddEmergencyContactComponent } from './add-emergency-contact/add-emergency-contact.component';
import { EditDemographyComponent } from './edit-demography/edit-demography.component';
import { Country } from 'src/app/models/country';
import { CountryService } from 'src/app/services/country/country.service';

@Component({
  selector: 'app-demography',
  templateUrl: './demography.component.html',
  styleUrls: ['./demography.component.scss'],
})
export class DemographyComponent implements AfterViewInit {
  displayedColumns: string[] = ['actions', 'name', 'home', 'type', 'grade', 'email', 'position', 'operation', 'cisco', 'phone'];
  emergencyContactData: any[];
  resultsLength: number;
  stateData: State[];
  deleteDialogRef;
  deleteId;
  deleteType;
  isLoadingResults;
  stateList: State[];
  regionList: Region[];
  communityList: Community[];
  countryList: Country[];
  regionId: number;
  stateId: number;
  countryId: number;
  stateAdd: State;
  communityAdd: Community;
  regionAdd: Region;
  countryAdd: Country;
  deleteIdEmergencyContact;

  regionForm: FormGroup;
  stateForm: FormGroup;
  communityForm: FormGroup;
  countryForm: FormGroup;


  public stateName = new FormControl('', Validators.required);
  public regionName = new FormControl('', Validators.required);
  public communityName = new FormControl('', Validators.required);
  public countryName = new FormControl('', Validators.required);

  selectionStateId: number;
  regionSubmitted: boolean;
  stateSubmitted: boolean;
  communitySubmitted: boolean;
  countrySubmitted: boolean;

  @ViewChild('region', { static: false }) region: MatSelectionList;
  @ViewChild('state', { static: false }) state: MatSelectionList;
  @ViewChild('country', { static: false }) country: MatSelectionList;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;


  constructor(private stateService: StateService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private regionService: RegionService,
    private communityService: CommunityService,
    private countryService: CountryService,
    private fb: FormBuilder,
    private emergencyContactService: EmergencyContactService,
  ) {
    this.stateData = [];
    this.deleteType = '';
    this.isLoadingResults = true;
    this.stateList = [];
    this.regionList = [];
    this.communityList = [];
    this.countryList = []
    this.regionId = 0;
    this.stateId = 0;
    this.countryId = 0;
    this.emergencyContactData = [];
    this.resultsLength = 0;
    this.deleteIdEmergencyContact = 0;


    this.stateForm = fb.group({
      'stateName': this.stateName
    })
    this.regionForm = fb.group({
      'regionName': this.regionName
    })
    this.communityForm = fb.group({
      'communityName': this.communityName
    })
    this.countryForm = fb.group({
      'countryName': this.countryName
    })

    this.regionSubmitted = false;
    this.stateSubmitted = false;
    this.communitySubmitted = false;
    this.countrySubmitted = false;
    this.stateAdd = new State();
    this.regionAdd = new Region();
    this.communityAdd = new Community();
    this.countryAdd = new Country();

  }


  ngAfterViewInit() {
    this.region.selectionChange.subscribe((s: MatSelectionListChange) => {
      this.region.deselectAll();
      this.region.selectedOptions.select(s.option);
    })

    this.state.selectionChange.subscribe((s: MatSelectionListChange) => {
      this.state.deselectAll();
      this.state.selectedOptions.select(s.option);
    })

    this.country.selectionChange.subscribe((s: MatSelectionListChange) => {
      this.country.deselectAll();
      this.country.selectedOptions.select(s.option);
    })

    this.loadData();
  }

  get fr(): any { return this.regionForm.controls; }
  get fs(): any { return this.stateForm.controls; }
  get fc(): any { return this.communityForm.controls; }
  get fco(): any { return this.countryForm.controls; }


  editDemo(obj, type) {
    let editDemographyDialogRef = this.dialog.open(EditDemographyComponent, {
      width: '500px',
      height: 'auto',
      data: { editOf: type, obj: obj }
    })

    editDemographyDialogRef.afterClosed().subscribe(result => {
      this.loadData();
    })
  }


  onDeleteCommunity(deletedialog, element, attribute) {
    this.deleteType = attribute;
    this.deleteId = element.id;
    this.deleteDialogRef = this.dialog.open(deletedialog, {
      width: '500px'
    });

    this.deleteDialogRef.afterClosed().subscribe(
      result => {
        setTimeout(() => {
          this.loadData()
        }, 1000);
      }
    )
  }
  onDeleteRegion(deletedialog, element, attribute) {
    this.deleteType = attribute;
    this.deleteId = element.id;
    this.deleteDialogRef = this.dialog.open(deletedialog, {
      width: '500px'
    });

    this.deleteDialogRef.afterClosed().subscribe(
      result => {
        setTimeout(() => {
          this.loadData()
        }, 1000);
      }
    )
  }
  onDeleteStates(deletedialog, element, attribute) {
    this.deleteType = attribute;
    this.deleteId = element.id;
    this.deleteDialogRef = this.dialog.open(deletedialog, {
      width: '500px'
    });

    this.deleteDialogRef.afterClosed().subscribe(
      result => {
        setTimeout(() => {
          this.loadData()
        }, 1000)

      }
    )
  }

  onDeleteCountry(deletedialog, element, attribute) {
    this.deleteType = attribute;
    this.deleteId = element.id;
    console.log("Elemento: ",element)
    this.deleteDialogRef = this.dialog.open(deletedialog, {
      width: '500px'
    });

    this.deleteDialogRef.afterClosed().subscribe(
      result => {
        setTimeout(() => {
          this.loadData()
        }, 1000)

      }
    )
  }

  deleteEM(deletedialog, id) {
    this.deleteIdEmergencyContact = id;
    this.deleteDialogRef = this.dialog.open(deletedialog, {
      width: '500px'
    })

    this.deleteDialogRef.afterClosed().subscribe(
      result => {
        this.loadEmergencyContactData();
      }
    )

  }
  cancelDeleteDialog() {
    this.deleteDialogRef.close();
  }
  confirmDeleteEmergencyContact() {
    this.emergencyContactService.deleteApi(this.deleteIdEmergencyContact)
      .subscribe(
        result => {
          if (result.confirmation) {
            this.matSnackBar.open('Emergency contact deleted successfully', 'Cerrar', { duration: 4000 })
          }
          if (!result.confirmation) {
            this.matSnackBar.open('Error deleting Emergency Contact', 'Cerrar', { duration: 4000 })
          }
        },
        err => {
          console.log(err);
        }
      )

    this.deleteDialogRef.close();
  }

  confirmDelete() {
    if (this.deleteType == 'region') {
      this.regionService.deleteApi(this.deleteId)
        .subscribe(
          result => {
            if (result.confirmation) {
              this.matSnackBar.open('Region deleted successfully', 'Cerrar', { duration: 4000 })
            }
            if (!result.confirmation) {
              this.matSnackBar.open('Error deleting Region', 'Cerrar', { duration: 4000 })
            }
          }
        )
      err => {
        console.log(err);
      }
    }

    if (this.deleteType == 'country') {
      this.countryService.deleteApi(this.deleteId)
        .subscribe(
          result => {
            if (result.confirmation) {
              this.matSnackBar.open('Country deleted successfully', 'Cerrar', { duration: 4000 })
            }
            if (!result.confirmation) {
              this.matSnackBar.open('Error deleting Country', 'Cerrar', { duration: 4000 })
            }
          }
        )
      err => {
        console.log(err);
      }
    }

    if (this.deleteType == 'community') {
      this.communityService.deleteApi(this.deleteId)
        .subscribe(
          result => {
            if (result.confirmation) {
              this.matSnackBar.open('Community deleted successfully', 'Cerrar', { duration: 4000 })
            }
            if (!result.confirmation) {
              this.matSnackBar.open('Error deleting Community', 'Cerrar', { duration: 4000 })
            }
          }
        )
      err => {
        console.log(err);
      }
      console.log()
    }
    if (this.deleteType == '') {
      this.stateService.deleteApi(this.deleteId)
        .subscribe(
          result => {
            if (result.confirmation) {
              this.matSnackBar.open('State deleted succesfully', 'Cerrar', { duration: 4000 });
              return;
            }
            if (!result.confirmation) {
              this.matSnackBar.open('Error deleting State.', 'Cerrar', { duration: 4000 });
            }
          },
          err => {
            console.log(err);
          }
        )
    }
    console.log(this.deleteType);
    this.deleteDialogRef.close();
  }
  tabSelection(e) {
    if (e.index == 1) {
      this.loadEmergencyContactData();
    }
  }

  loadEmergencyContactData() {
    merge(this.paginator.page, this.paginator.pageSize)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.emergencyContactService.findApi(this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {
          this.resultsLength = data["count"];
          this.isLoadingResults = false;
          return data;
        }),
        catchError((err) => {
          console.log(err)
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.emergencyContactData = data.rows
      })
  }

  loadData() {
    if (this.countryId != 0) {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            return this.stateService.findApi(0, 50, this.countryId);
          }),
          map(data => {
            this.isLoadingResults = false;
            return data;
          }),
          catchError((err) => {
            console.log(err)
            this.isLoadingResults = false;
            return observableOf([]);
          })
        ).subscribe(data => {
          this.stateList = data.rows;
        });
    }

    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.countryService.findApi(0, 50);
        }),
        map(data => {
          this.isLoadingResults = false;
          return data;
        }),
        catchError((err) => {
          console.log(err)
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.countryList = data.rows;
      });

    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.regionService.findApi(0, 50);
        }),
        map(data => {
          this.isLoadingResults = false;
          return data;
        }),
        catchError((err) => {
          console.log(err)
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.regionList = data.rows;
      });

    if (this.stateId != 0) {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            return this.communityService.findApi(0, 50, this.stateId);

          }),
          map(data => {
            this.isLoadingResults = false;
            return data;
          }),
          catchError((err) => {
            console.log(err)
            this.isLoadingResults = false;
            return observableOf([]);
          })
        ).subscribe(data => {
          this.communityList = data.rows;
        });
    }
  }


  getRegionId(region) {
    this.regionId = region.id;
    this.stateId = 0;
    this.communityList = [];
    this.stateList = [];
    this.countryId = 0;
    this.loadData();
  }

  getStateId(state) {
    this.stateId = state.id;
    this.loadData();
  }

  getCountryId(country) {
    this.countryId = country.id;
    this.stateId = 0;
    this.stateList = [];
    this.loadData();
  }

  addState() {
    this.stateSubmitted = true;

    // this.stateAdd.name = this.stateForm.get('stateName').value;
    // this.stateAdd.countryId = this.countryId;

    const requestToCreateANewSate = {
      name: this.stateForm.get('stateName').value,
      countryId: this.countryId
    }
    if (this.countryId == 0) {
      this.matSnackBar.open('Seleccione una región para mostrar los estados', 'Cerrar', { duration: 5000 })
      return;
    }
    if (this.stateForm.valid) {
      this.stateService.createApi(requestToCreateANewSate).subscribe(
        res => {
          if (res.confirmation) {
            this.matSnackBar.open('State added succesfully', 'Cerrar', { duration: 4000 })
            return;
          }
          if (!res.confirmation) {
            this.matSnackBar.open('Error adding State', 'Cerrar', { duration: 4000 })
          }

        },
        err => {
          console.log(err);
        },
        () => {
          this.loadData();
          this.stateForm.reset();
        }
      )
    }
    this.deleteDialogRef.close();
  }


  addCommunity() {
    this.communitySubmitted = true;

    //  this.communityAdd.name = this.communityForm.get('communityName').value;
    //  this.communityAdd.stateId = this.stateId;

    if (this.stateId == 0) {
      this.matSnackBar.open('Seleccione un estado para mostrar las comunidades', 'Cerrar', { duration: 5000 })
      return;
    }

    const requestToCreateANewCommunity = {
      name: this.communityForm.get('communityName').value,
      stateId: this.stateId,
    }

    if (this.communityForm.valid) {
      this.communityService.createApi(requestToCreateANewCommunity).subscribe(
        res => {
          if (res.confirmation) {
            this.matSnackBar.open('Community added succesfully', 'Cerrar', { duration: 4000 })
            return;
          }
          if (!res.confirmation) {
            this.matSnackBar.open('Error adding Community', 'Cerrar', { duration: 4000 })
          }

        },
        err => {
          console.log(err);
        },
        () => {
          this.loadData();
          this.communityForm.reset();
        }
      )
    }
  }

  addRegion() {
    this.regionSubmitted = true;
    //  this.regionAdd.name = this.regionForm.get('regionName').value;
    const dataToCreateANewRegion = {
      name: this.regionForm.get('regionName').value,
    }
    if (this.regionForm.valid) {
      this.regionService.createApi(dataToCreateANewRegion).subscribe(
        res => {
          if (res.confirmation) {
            this.matSnackBar.open('Region added succesfully', 'Cerrar', { duration: 4000 })
            return;
          }
          if (!res.confirmation) {
            this.matSnackBar.open('Error adding Region', 'Cerrar', { duration: 4000 })
          }

        },
        err => console.log(err),
        () => {
          this.loadData();
          this.regionForm.reset();
        }
      )
    }

  }
  addCountry() {
    this.countrySubmitted = true;

    //  this.countryAdd.name = this.countryForm.get('countryName').value;
    //  this.countryAdd.regionId = this.regionId;

    const requestToCreateANewCountry = {
      name: this.countryForm.get('countryName').value,
    }

    if (this.countryForm.valid) {
      this.countryService.createApi(requestToCreateANewCountry).subscribe(
        res => {
          if (res.confirmation) {
            this.matSnackBar.open('Country added succesfully', 'Cerrar', { duration: 4000 })
            return;
          }
          if (!res.confirmation) {
            this.matSnackBar.open('Error adding Country', 'Cerrar', { duration: 4000 })
          }

        },
        err => {
          console.log(err);
        },
        () => {
          this.loadData();
          this.countryForm.reset();
        }
      )
    }

  }


  edit(id): void {
    let currentEmergencyContact;
    this.emergencyContactData.forEach(emergencyContact => {
      if (emergencyContact['id'] == id) {
        currentEmergencyContact = emergencyContact
      }
    })

    let editDialogRef = this.dialog.open(AddEmergencyContactComponent, {
      width: '500px',
      height: 'auto',
      data: { isEditComponent: true, currentEmergencyContact: currentEmergencyContact }
    })

    editDialogRef.afterClosed().subscribe(result => {
      this.loadEmergencyContactData();
    })
  }


  openAddDialog(): void {
    const dialogRef = this.dialog.open(AddEmergencyContactComponent, {
      width: '500px',
      height: 'auto',
    })

    dialogRef.afterClosed().subscribe(result => {
      this.loadEmergencyContactData();
    })
  }
}

