import { Component, OnInit } from '@angular/core';
import { NetworkStatusListenerService } from './utils/services/network-status-listener.service';
import { NetworkStatus } from './utils/models/network-status.enum';

import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'Pescadata-Dashboard';

  private networkStatus$: Subscription = Subscription.EMPTY;

  constructor(
    private _networkStatusListenerService: NetworkStatusListenerService,
  ) { }

  ngOnInit(): void {
    this.networkStatus$ = this._getSubscribeToNetworkStatus();
  }

  private _getSubscribeToNetworkStatus(): Subscription {
    return merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this._networkStatusListenerService.updateStatus(
          status
            ? NetworkStatus.ONLINE
            : NetworkStatus.OFFLINE
        );
      });
  }

}
