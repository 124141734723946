import { Component, OnInit, Inject } from '@angular/core';
import { Organization } from 'src/app/models/organization';
import { MatPaginator, MatTable, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CooperativaService } from 'src/app/services/organization.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DropdownOption } from 'src/app/models/dropdown_option';
import { CountryService } from 'src/app/services/country/country.service';
import { StateService } from 'src/app/services/states/state.service';

@Component({
  selector: 'app-add-edit-organization',
  templateUrl: './add-edit-organization.component.html',
  styleUrls: ['./add-edit-organization.component.scss']
})
export class AddEditOrganizationComponent implements OnInit {
  public orgForm: FormGroup;
  public name = new FormControl('', Validators.required);
  public phone = new FormControl('', Validators.required);
  public email = new FormControl('', Validators.compose([Validators.required, Validators.email]));
  public address = new FormControl('');
  public website = new FormControl('');
  public active = new FormControl(true);
  public claimed = new FormControl(true);
  public states: DropdownOption[];
  public countries: DropdownOption[];
  public selectedState: string;
  public selectedRegion: string;
  public selectedCountry: string;

  submitted = false;
  isEditComponent = false;

  constructor(private orgSvc: CooperativaService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddEditOrganizationComponent>,
    private matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private stateSvc: StateService,
    private countrySvc: CountryService,
    private router: Router)
    {
      this.orgForm = fb.group({
        'name': this.name,
        'active': this.active,
        'phone': this.phone,
        'email': this.email,
        'address': this.address,
        'website': this.website,
        'claimed': this.claimed
      });
      this.states = [];
      this.countries = [];
      this.selectedRegion = '';
      this.selectedState = '';
      this.selectedCountry = '';
  }

  ngOnInit() {
    if (this.data.isEditComponent) {
      this.isEditComponent = true;
      this.orgForm.controls['name'].setValue(this.data.org.name);
      this.orgForm.controls['active'].setValue(this.data.org.active);
      this.orgForm.controls['claimed'].setValue(this.data.org.claimed);
      this.orgForm.controls['email'].setValue(this.data.org.email);
      this.orgForm.controls['phone'].setValue(this.data.org.phone);
      this.orgForm.controls['address'].setValue(this.data.org.address);
      this.orgForm.controls['website'].setValue(this.data.org.website);
    }

    this.countrySvc.findApi(0,50)
      .subscribe(data => {
        for(let con of data.rows) {
          this.countries.push({title: con.name, value: con.id.toString()})
          
        }
        if(this.data.isEditComponent) {
          this.selectedCountry = this.data.org.country_id + '';
          this.onCountrySelect();
        }
      },
        err => {
          this.matSnackBar.open("Error: "+ err.error.message, 'Close', {duration: 4000})
        }
      )
  }

  close(): void {
    this.dialogRef.close();
  }

  get f(): any { return this.orgForm.controls; }

  submit(): void {
    this.submitted = true;
    if (this.orgForm.valid) {

      let orgReq = {
        name: this.orgForm.get('name').value,
        email: this.orgForm.get('email').value,
        phone: this.orgForm.get('phone').value,
        address: this.orgForm.get('address').value,
        website: this.orgForm.get('website').value,
        state_id : parseInt(this.selectedState),
        country_id : parseInt(this.selectedCountry),

        //============================================================
        // this properties is added into modify line 140
        //============================================================
        // claimed: this.orgForm.get('claimed').value,
        // active: this.orgForm.get('active').value,
        // region_id : parseInt(this.selectedRegion),
      }

      // let orgReq = new Organization();
      // orgReq.name = this.orgForm.get('name').value;
      // orgReq.active = this.orgForm.get('active').value;
      // orgReq.email = this.orgForm.get('email').value;
      // orgReq.phone = this.orgForm.get('phone').value;
      // orgReq.address = this.orgForm.get('address').value;
      // orgReq.website = this.orgForm.get('website').value;
      // orgReq.claimed = this.orgForm.get('claimed').value;

      if (this.selectedState == '') {
        this.matSnackBar.open("You must select a state.", 'Cerrar', {duration: 4000});
        return;
      }
      if(this.selectedCountry == '') {
        this.matSnackBar.open("You must select country.", 'Cerrar', {duration: 4000});
        return;
      }

      if (this.selectedState == '') {
        this.matSnackBar.open("You must select a state.", 'Cerrar', {duration: 4000});
        return;
      }
      if(this.selectedCountry == '') {
        this.matSnackBar.open("You must select country.", 'Cerrar', {duration: 4000});
        return;
      }

      if (this.isEditComponent) {
        const organizationToModify = {
          ...orgReq,
          claimed: this.orgForm.get('claimed').value,
          active: this.orgForm.get('active').value,
        }
        this.orgSvc.updateApi(this.data.org.id, organizationToModify).subscribe(
          response => {
            if (response.confirmation == true) {
              this.matSnackBar.open('Detalles de la organización actualizado con éxito', 'Cerrar', {duration: 4000});
            }
            this.dialogRef.close();
          },
          err => {
            console.log(err);
            if (err.error.confirmation == false) {
              this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000});
            }
          }
        )
        return;
      }

      if (orgReq.address == "") delete orgReq.address;
      if (orgReq.website == "") delete orgReq.website;

      this.orgSvc.createApi(orgReq).subscribe(
        response => {
          if (response.confirmation == true) {
            this.matSnackBar.open('Organization added succesfully', 'Cerrar', {duration: 4000});
          }
          this.dialogRef.close();
        },
        err => {
          console.log(err);
          if (err.error.confirmation == false) {
            this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000});
          }
        }
      )
    }
  }

  onCountrySelect() {
    this.states = [];
    this.stateSvc.findApi(0,50,parseInt(this.selectedCountry))
      .subscribe(data => {
        for (let state of data.rows) {
          this.states.push({ title: state.name, value: state.id.toString() });
        }
        if (this.isEditComponent) {
          this.selectedState = this.data.org.state.id.toString();
        }
      }, err => {
        console.log(err);
        this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000});
      });

  }
}
