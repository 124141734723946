import { Injectable } from '@angular/core';
import { HttpclientService } from '../httpclient.service';
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';
import { ListStateResponse } from './interfaces.list-states-response';
import { State } from 'src/app/models/models_fixed/babel.models';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private API: string = environment.apiUrl;

  apiUrl: string;

  constructor(private httpClient: HttpclientService) {
    this.apiUrl = environment.apiUrl;
  }

  // ============================================================
  // CREATE
  // ============================================================


  // ============================================================
  // READ
  // ============================================================

  public async listStatesByCountryID(countryID: number): Promise<ListStateResponse> {
    const urlFormed = `${this.API}/states?page=0&pageSize=10000&countryId=${countryID}`;
    const response = await this.httpClient.getWithAuth(urlFormed).toPromise();
    return response as ListStateResponse;
  }

  public async listStates(): Promise<ListStateResponse> {
    const urlFormed = `${this.API}/states?page=0&pageSize=10000&countryId=1`;
    const response = await this.httpClient.getWithAuth(urlFormed).toPromise();
    return response as ListStateResponse;
  }

  public async findByID(id: number): Promise<State> {
    try {
      const urlFormed = `${this.API}/states/${id}`;
      const response = await this.httpClient.getWithAuth(urlFormed).toPromise();
      return response as State;
    } catch (error) {
      return null;
    }
  }

  public async getAllStates(): Promise<State[]> {

    try {

      // {{local}}/states?page=0&pageSize=9999999
      const urlFormed = `${this.API}/states?page=0&pageSize=9999999`;
      const response = await this.httpClient.getWithAuth(urlFormed).toPromise();
      const responseServer = response as { "count": number, "rows": State[], }

      return responseServer.rows;

    } catch (error) {

      return [];

    }

  }


  // ============================================================
  // UPDATE
  // ============================================================


  // ============================================================
  // DELETE
  // ============================================================




  // * ✅✅✅

  findApi(page: number, pageSize: number, countryId: number = 0): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/states?page=${page}&pageSize=${pageSize}&countryId=${countryId}`);
  }
  createApi(state: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/states`, state)
  }
  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/states/${id}`);
  }
  updateApi(id: number, state: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/states/${id}`, state);
  }
  findByIdApi(id: number): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/states/${id}`);
  }
}
