import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';


type CloseSessionStatesUI = "CONFIRMATION" | "IS_LOADING" | "CLOSE";


@Component({
  selector: 'app-close-session-modal',
  templateUrl: './close-session-modal.component.html',
  styleUrls: ['./close-session-modal.component.scss']
})
export class CloseSessionModalComponent implements OnInit {

  public currentState: CloseSessionStatesUI = "CONFIRMATION";

  constructor(
    public _authService: AuthService,
    public dialogRef: MatDialogRef<CloseSessionModalComponent>,
  ) { }

  ngOnInit() { }


  public closeModal() {
    this.dialogRef.close();
  }

  private changeCurrentState(state: CloseSessionStatesUI) {
    this.currentState = state;
  }

  public renderContainer(state: CloseSessionStatesUI) {
    return this.currentState === state;
  }

  public closeSession() {
    this.changeCurrentState('IS_LOADING');
    this._authService.logout();
    this.closeModal();
  }


}
