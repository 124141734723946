import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar } from '@angular/material';
import { merge,  of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { CommentService } from 'src/app/services/comment.service';

@Component({
  selector: 'app-flagged-comments',
  templateUrl: './flagged-comments.component.html',
  styleUrls: ['./flagged-comments.component.scss']
})
export class FlaggedCommentsComponent implements AfterViewInit {
  loadingComments: boolean;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  comments: any;
  commentsLength: number;
  deleteCommentDialogRef;
  deleteCommentIdHolder;

  constructor(
    private commentSvc: CommentService,
    public dialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) { 
    this.loadingComments = true;
    this.comments = [];
    this.commentsLength = 0;
    this.deleteCommentIdHolder = 0;
  }

  ngAfterViewInit() {
    this.loadData();
  }

  deleteComment(deleteDialog, comment) {
    this.deleteCommentIdHolder = comment.id;
    this.deleteCommentDialogRef = this.dialog.open(deleteDialog, {
      width: '500px'
    })
  }

  cancelDialog() {
    this.deleteCommentDialogRef.close();
  }

  confirmCommentDelete() {
    this.commentSvc.deleteApi(this.deleteCommentIdHolder)
      .subscribe(
        result => {
          if(result.confirmation) {
            this.loadData();
          }
          this.matSnackBar.open('Comment deleted successfully', 'Cerrar', {duration: 4000})
        },
        err => {
          console.log(err);
        }
      )


      this.deleteCommentDialogRef.close();
  }


  reviewComment(id) {
    this.commentSvc.updateApi(id, {
      reviewed: true
    }).subscribe(
      (res) => {
        this.matSnackBar.open('Comment reviewed', 'Cerrar', {duration: 3000})
      },
      (err) => {
        console.log(err);
        this.matSnackBar.open('Comment error:', 'Cerrar', {duration: 3000})
      }
    )
  }

  loadData() {
    merge(this.paginator.page, this.paginator.pageIndex)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingComments = true;
          return this.commentSvc.findApi(this.paginator.pageIndex, this.paginator.pageSize, '', 'true')
        }),
        map(data => {
          this.loadingComments = false;
          this.commentsLength = data.comments.count;

          return data.comments.rows;
        }),
        catchError((err) => {
          console.log(err);
          this.loadingComments = false;
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.comments = data;
      })
  }

}
