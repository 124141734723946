export class OrganizationDetails {
    id: number;
    email: string;
    phone_numbers: string[];
    links: string[];
    img_url: string;

    constructor() {
        this.id = 0;
        this.email = "";
        this.phone_numbers = [];
        this.links = [];
        this.img_url = "";
    }
}