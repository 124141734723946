import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flagged',
  templateUrl: './flagged.component.html',
  styleUrls: ['./flagged.component.scss']
})
export class FlaggedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
