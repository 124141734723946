export enum SurveyQuestionSelectionEnum {
    Acopio = "Acopio",
    Procesamiento = "Procesamiento",
    Preembarque = "Pre-embarque",
    Embarque = "Embarque",
    Administración = "Administración",
    Comercialización = "Comercialización",
    Monitoreo = "Monitoreo",
    Vigilancia = "Vigilancia",
    
    //Those are for custom selection
    CustomSelection1 = "No realizamos estas actividades",
    CustomSelection2 = "No, nunca he tenido conflictos."
}