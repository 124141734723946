export function dateFormat(dt: Date, format: string): string {
	let day = ("0" + dt.getDate()).slice(-2);
	let month = ("0" + (dt.getMonth() + 1)).slice(-2);
	let year = dt.getFullYear().toString();
	let h = dt.getHours();
	let ampm = (h % 12 || 12)
		+ ':' + ("0" + dt.getMinutes()).slice(-2)
		+ ' ' + (h < 12 ? 'A' : 'P') + 'M';

	switch (format) {
		case '01-02-2006 03:04 PM':
			return `${month}-${day}-${year} ${ampm}`;
		case '01/02/2006 03:04 PM':
			return `${month}/${day}/${year} ${ampm}`;
		case '01/02/2006':
			return `${month}/${day}/${year}`;

		default:
			return dt.toString();
	}
}

export function isDateSet(d: Date): boolean {
	if (d && typeof (d) === "object" && d.getFullYear() > 1901) {
		return true;
	}
	return false;
}