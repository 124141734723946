import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormBuilder } from '@angular/forms';
import { UserAuthRequest, ErrorKeysUserAuthResponse } from './models/UserAuthRequest.interface';
import { LoginForm } from './models/login.form';
import { EMAIL_ERRORS_TYPE } from './models/email-error-controller';
import { PASS_ERRORS_TYPE } from './models/pass-error-controller';
import { AlertService } from '../../shared/alerts/services/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: LoginForm;
  public isHiddenPass: boolean = true;

  constructor(
    private router: Router,
    private auth: AuthService,
    private alertService: AlertService,
    formBuilder: FormBuilder
  ) {
    this.loginForm = new LoginForm(formBuilder);
  }

  ngOnInit() {
    this.auth.verifyUserLogged();
  }

  public goToResetPass() {
    this.router.navigate(['/login2']);
  }

  public async submitForm() {

    if (this.loginForm.form.valid == false) {
      this.loginForm.markAllFormAsTouched();
      this.checkErrors(this.loginForm.getKeyErrorEmail(), this.loginForm.getKeyErrorPass());
      return;
    }

    const userAuthRequest: UserAuthRequest = this.loginForm.userAuthRequest;
    const responseAuthUserApi = await this.auth.logIn(userAuthRequest);

    try {
      const responseErrorKeys = responseAuthUserApi as ErrorKeysUserAuthResponse<EMAIL_ERRORS_TYPE, PASS_ERRORS_TYPE>
      this.loginForm.markAllFormAsTouched();
      this.checkErrors(responseErrorKeys.username_email, responseErrorKeys.password);
    } catch (error) {
      return;
    }
  }


  private checkErrors(
    keyErrorEmail?: EMAIL_ERRORS_TYPE,
    keyErrorPass?: PASS_ERRORS_TYPE
  ) {

    // INVALID PASS
    const isInvalidPass = keyErrorPass == "INVALID" || keyErrorEmail == "REQUIRED";
    if (isInvalidPass) {
      this.alertService.launch({
        typeAlert: 'error',
        message: "Contraseña inválida, verifica los datos e intenta nuevamente.",
        showIn: "InAllApp"
      });
      keyErrorEmail && this.loginForm.showErrorPassWithCustomMessage(keyErrorPass);
    }

    // INVALID EMAIL
    const isInvalidEmail = keyErrorEmail == "INVALID" && keyErrorPass == "REQUIRED";
    if (isInvalidEmail) {
      this.alertService.launch({
        typeAlert: 'error',
        message: "Correo electrónico inválido, verifica los datos e intenta nuevamente.",
        showIn: "InAllApp"
      });
      keyErrorEmail && this.loginForm.showErrorEmailWithCustomMessage(keyErrorEmail);
    }

    // REQUIRED FORM
    const isRequiredData = keyErrorEmail == "REQUIRED" && keyErrorPass == "REQUIRED";
    if (isRequiredData) {
      this.alertService.launch({
        typeAlert: 'error',
        message: "Completa los campos requeridos para poder continuar.",
        showIn: "InAllApp"
      });
      keyErrorEmail && this.loginForm.showErrorEmailWithCustomMessage(keyErrorEmail);
      keyErrorPass && this.loginForm.showErrorPassWithCustomMessage(keyErrorPass);
    }

    // INVALID FORM
    const isInvalidData = keyErrorEmail == "INVALID" && keyErrorPass == "INVALID";
    if (isInvalidData) {
      this.alertService.launch({
        typeAlert: 'error',
        message: "El correo o la contraseña no son válidos, verifica los datos e intenta nuevamente.",
        showIn: "InAllApp"
      });
      keyErrorEmail && this.loginForm.showErrorEmailWithCustomMessage(keyErrorEmail);
      keyErrorPass && this.loginForm.showErrorPassWithCustomMessage(keyErrorPass);
    }


    // Response api

    // EMAIL NOT REGISTERED
    const isEmailNotRegistered = keyErrorEmail == "NOT_REGISTERED";
    if (isEmailNotRegistered) {
      this.alertService.launch({
        typeAlert: 'error',
        message: "Dirección de correo no registrada, verifica los datos e intenta nuevamente.",
        showIn: "InAllApp"
      });
      keyErrorEmail && this.loginForm.showErrorEmailWithCustomMessage(keyErrorEmail);
    }

  }

}
