import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-searcher',
  templateUrl: './input-searcher.component.html',
  styleUrls: ['./input-searcher.component.scss']
})
export class InputSearcherComponent {

  @Input() public label: string = "";
  @Input() public options: string[] = [];
  @Output() public selectOptionEmit = new EventEmitter();
  @Output() public updateOptionEmit = new EventEmitter();

  public option: string = "";

  constructor() { }

  public updateOption(optionChange: string) {
    this.updateOptionEmit.emit(optionChange);
  }

  public selectOption(optionSelected: string) {
    this.selectOptionEmit.emit(optionSelected);
  }

}
