import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { BadgesService } from 'src/app/services/badges.service';
import { SolutionService } from 'src/app/services/solution.service';
import { UserService } from 'src/app/services/users/user.service';

@Component({
  selector: 'app-add-edit-solution',
  templateUrl: './add-edit-solution.component.html',
  styleUrls: ['./add-edit-solution.component.scss']
})
export class AddEditSolutionComponent implements OnInit {
  public solutionForm: FormGroup;

  public title = new FormControl('');
  public description = new FormControl('');
  public problem_name = new FormControl('');
  public challenges = new FormControl('');
  public allow_contact = new FormControl(false);
  public participants = new FormControl();
  public other = new FormControl();
  public value_chain_stage = new FormControl();
  public faoBadges = new FormControl([]);
  public sdgBadges = new FormControl([]);
  public men_participant_count = new FormControl();
  public women_participant_count = new FormControl();
  public men_benefiting = new FormControl();
  public women_benefiting = new FormControl();
  public username = new FormControl('');

  public anpName = new FormControl('');
  public anpNames: [] = [];

  public tagName = new FormControl('');
  public tagNames: [] = []

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  isEditComponent: boolean;

  selectedAnpNames: any[] = [];
  selectedTags: any[] = [];
  data: any;
  images: string[] = [];
  selectedFileNames: string[] = [];
  selectedFiles: any[] = [];
  files: File[] = [];

  users: any[] = [];
  totalFiles: number = 0;

  @ViewChild('fruitInput', { static: true }) fruitInput: ElementRef<HTMLInputElement>;

  public participantsList = ['Solo', 'Mi comunidad', 'Gobierno local', 'Gobierno federal', 'Organizaciones de sector civil', 'Organizaciones internacionales', 'Otro'];
  public valueChains = [
    { name: 'Preproducción', img_url: 'https://pescadata-images.s3.us-west-1.amazonaws.com/Preproduccio%CC%81n.png' },
    { name: 'Producción', img_url: 'https://pescadata-images.s3.us-west-1.amazonaws.com/Produccio%CC%81n.png' },
    { name: 'Postproducción', img_url: 'https://pescadata-images.s3.us-west-1.amazonaws.com/Postproduccio%CC%81n.png' },
    { name: 'No aplica' }
  ]

  public faoBadgesList = [];
  public sdgBadgesList = [];

  constructor(
    private fb: FormBuilder,
    // public dialogRef: MatDialogRef<AddEditSolutionComponent>,
    private matSnackBar: MatSnackBar,
    private _solutionSvc: SolutionService,
    private _badgeSvc: BadgesService,
    private _userSvc: UserService,
    private router: Router,
  ) {
    this.solutionForm = fb.group({
      'title': this.title,
      'description': this.description,
      'problem_name': this.problem_name,
      'challenges': this.challenges,
      'allow_contact': this.allow_contact,
      'participants': this.participants,
      'other': this.other,
      'value_chain_stage': this.value_chain_stage,
      'faoBadges': this.faoBadges,
      'sdgBadges': this.sdgBadges,
      'anpName': this.anpName,
      'tagName': this.tagName,
      'men_participant_count': this.men_participant_count,
      'women_participant_count': this.women_participant_count,
      'men_benefiting': this.men_benefiting,
      'women_benefiting': this.women_benefiting,
      'username': this.username
    })

    this._badgeSvc.getFaoBadges().subscribe(
      response => {
        this.faoBadgesList = response;
      },
      err => {
        console.log(err)
      }
    )

    this._badgeSvc.getSdgBadges().subscribe(
      response => {
        this.sdgBadgesList = response
      },
      err => {
        console.log(err)
      }
    )

    if (this.router.getCurrentNavigation().extras.state) {
      let state = this.router.getCurrentNavigation().extras.state;

      if (state) {
        this.isEditComponent = true;
        this.data = {
          solution: state.solution
        }
        this.images = this.data.solution.images;
        this.selectedAnpNames = this.data.solution.anp_names
        this.selectedTags = this.data.solution.tags
        this.solutionForm.controls['title'].setValue(this.data.solution.title);
        this.solutionForm.controls['description'].setValue(this.data.solution.description);
        this.solutionForm.controls['problem_name'].setValue(this.data.solution.problem_name);
        this.solutionForm.controls['challenges'].setValue(this.data.solution.challenges);
        this.solutionForm.controls['allow_contact'].setValue(this.data.solution.allow_contact);
        this.solutionForm.controls['participants'].setValue(this.data.solution.participants);
        this.solutionForm.controls['other'].setValue(this.data.solution.other);
        this.solutionForm.controls['value_chain_stage'].setValue(this.data.solution.value_chain_stage);
        this.solutionForm.controls['men_participant_count'].setValue(this.data.solution.men_participant_count);
        this.solutionForm.controls['women_participant_count'].setValue(this.data.solution.women_participant_count);
        this.solutionForm.controls['men_benefiting'].setValue(this.data.solution.men_benefiting);
        this.solutionForm.controls['women_benefiting'].setValue(this.data.solution.women_benefiting);
        this.solutionForm.controls['username'].setValue(`${this.data.solution.user.first_name} ${this.data.solution.user.last_name}`)
        this.faoBadges.setValue(this.data.solution.fao_badges.map(badge => badge.id));
        this.sdgBadges.setValue(this.data.solution.sdg_badges.map(badge => badge.id));

      }
    }
  }

  ngOnInit() {
    let anpName = this.solutionForm.get('anpName');
    let tagName = this.solutionForm.get('tagName');

    anpName.valueChanges.pipe(
      startWith(null),
      debounceTime(400),
      distinctUntilChanged(),
    )
      .subscribe(res => {
        this.filterAnpNames();
      })

    tagName.valueChanges.pipe(
      startWith(null),
      debounceTime(400),
      distinctUntilChanged(),
    )
      .subscribe(res => {
        this.filterTags();
      })

    let username = this.solutionForm.get('username')
    username.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    )
      .subscribe(res => {
        this.filterUsers()
      })
  }

  close(): void {
    this.router.navigate(['/dashboard/solutions']);
  }

  filterUsers() {
    let userName = this.solutionForm.get('username').value;

    this._userSvc.typeaemailApi(userName).subscribe(
      res => {
        this.users = res
      },
      err => {
        console.log(err)
      }
    )
  }

  filterAnpNames() {
    let anpName = this.solutionForm.get('anpName').value

    this._solutionSvc.typeaheadAnpNames(anpName).subscribe(
      res => {
        this.anpNames = res;
      },
      err => {
        console.log(err);
      }
    )
  }

  filterTags() {
    let tagName = this.solutionForm.get('tagName').value;

    this._solutionSvc.typeaheadTags(tagName).subscribe(
      res => {
        this.tagNames = res;
      },
      err => {
        console.log(err);
      }
    )
  }

  remove(index): void {
    if (index >= 0) {
      this.selectedAnpNames.splice(index, 1);
    }
  }

  removeTag(index): void {
    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedAnpNames.push({
      id: event.option.value,
      name: event.option.viewValue
    });

    this.fruitInput.nativeElement.value = '';
    this.anpName.setValue(null);
  }

  selectedTag(event: MatAutocompleteSelectedEvent): void {
    this.selectedTags.push({
      id: event.option.value,
      name: event.option.viewValue
    });

    this.fruitInput.nativeElement.value = '';
    this.tagName.setValue(null);
  }

  deleteImage(index: number, image): void {
    if (!!image.id) {
      this._solutionSvc.deleteImage(image.id).subscribe(
        response => {

        },
        err => {
          this.matSnackBar.open('cannot delete image!', 'Cerrar', { duration: 4000 });
        }
      )
    }
    this.images.splice(index, 1);

  }

  selectFiles(event: any): void {
    this.selectedFileNames = [];
    this.selectedFiles.push(event.target.files);
    this.totalFiles += 1;
    if (this.selectedFiles && this.selectedFiles[0]) {
      if (this.images.length > 3) {
        this.matSnackBar.open('Sube hasta 3 fotos!', 'Cerrar', { duration: 4000 });
        return;
      }
      this.selectedFiles.forEach((file, index) => {
        if (index >= this.totalFiles - 1) {
          const reader = new FileReader();

          reader.onload = (e: any) => {
            this.images.push(e.target.result);
          };
          reader.readAsDataURL(file[0]);

          this.selectedFileNames.push(file[0].name);
        }
      })

    }

  }

  async submit() {

    let userId;

    let username = this.solutionForm.get('username').value;

    if (username) {
      for (let user of this.users) {
        if (user.email === username) {
          userId = user.id;
        }
      }
    }

    let participants = this.solutionForm.get('participants').value;

    let req = {
      title: this.solutionForm.get('title').value,
      description: this.solutionForm.get('description').value,
      problem_name: this.solutionForm.get('problem_name').value,
      challenges: this.solutionForm.get('challenges').value,
      allow_contact: this.solutionForm.get('allow_contact').value,
      participants: participants ? participants.toString() : '',
      other: this.solutionForm.get('other').value,
      value_chain_stage: this.solutionForm.get('value_chain_stage').value,
      faoBadges: this.solutionForm.get('faoBadges').value,
      sdgBadges: this.solutionForm.get('sdgBadges').value,
      men_participant_count: this.solutionForm.get('men_participant_count').value,
      women_participant_count: this.solutionForm.get('women_participant_count').value,
      men_benefiting: this.solutionForm.get('men_benefiting').value,
      women_benefiting: this.solutionForm.get('women_benefiting').value,
      anpNames: this.selectedAnpNames.map(item => item.id),
      tags: this.selectedTags.map(item => item.id).toString(),
      user_id: userId
    }

    if (this.isEditComponent) {
      this._solutionSvc.updateById(this.data.solution.id, req).subscribe(
        response => {
          if (response.confirmation == true) {
            this.matSnackBar.open('Solution updated succesfully', 'Cerrar', { duration: 4000 }).afterDismissed().subscribe(() => {
              this.router.navigate(['/dashboard/solutions']).then(() => {
                window.location.reload();
              });
            });
          }
          if (this.selectedFiles.length != 0) {
            this._solutionSvc.uploadImages(this.selectedFiles, this.data.solution.id).subscribe(
              res => { },
              err => { }
            )
          }
        },
        err => {
          if (err.error.confirmation == false) {
            this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', { duration: 4000 });
          }
        },
      )
      return;
    }

    this._solutionSvc.createApi(req).subscribe(
      response => {
        if (response.confirmation == true) {
          this.matSnackBar.open('Solution added succesfully', 'Cerrar', { duration: 4000 });
        }
        if (this.selectedFiles.length != 0) {
          this._solutionSvc.uploadImages(this.selectedFiles, response.solution.id).subscribe(
            err => {
              console.log(err);
            }
          )
        }

      },
      err => {
        console.log(err);
        if (err.error.confirmation == false) {
          this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', { duration: 4000 });
        }
      }

    )

    this.router.navigate(['/dashboard/solutions']);
  }

}
