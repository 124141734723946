import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpclientService } from './httpclient.service';
import { environment } from '../../environments/environment'
import { Boat } from '../models/models_fixed/babel.models';
import { QueryBoat } from '../boats/models/interface.query-boat';
import { ResponseQueryBoats } from '../boats/models/interface.response.query-boats';
import { CreateBoatResponseLocal, ResponseCreateError, ResponseCreateOK } from '../boats/models/interface.response.create';
import { ResponseUpdateBoat, ResponseUpdateBoatOK } from '../boats/models/interface.response.update-boat';
import { DeleteBoatByIDResponseLocal, DeleteBoatByIDResponseServer } from '../boats/models/interface.delete-boat-by-id.response';
import { SearchByFiltersRequest } from '../boats/models/interface.search-by-filters.request';

@Injectable({
  providedIn: 'root'
})
export class BoatService {

  private API: string = environment.apiUrl;

  constructor(private httpClientService: HttpclientService) { }

  public async findByTypeAheadApi(query: string = ""): Promise<Boat[]> {
    try {

      const urlFormed = `${this.API}/boat/typeahead/${query}`;
      const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();
      return response as Boat[];

    } catch (error) {

      return [];

    }
  }

  public async findById(id: number): Promise<Boat> {
    const urlFormed = `${this.API}/boat/${id}`;
    const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();
    return response as Boat;
  }

  public async searchByCooperativaID(cooperativaID: number): Promise<Boat[]> {
    const urlFormed = `${this.API}/boat/sync?orgId=${cooperativaID}`;
    const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();
    return response as Boat[];
  }

  public async searchByFilters(request: SearchByFiltersRequest): Promise<ResponseQueryBoats> {

    try {

      const queryCooperativaKeys = Object.keys(request);
      const partsQuery = queryCooperativaKeys.map((key: string) => `${key}=${request[key]}`);
      let urlBody = partsQuery.join('&');

      const urlFormed = `${this.API}/boat?${urlBody}`;

      const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();
      const responseServer = response as ResponseQueryBoats;

      return responseServer;

    } catch (error) {

      return {
        count: 0,
        rows: [],
      }

    }
  }

  public async searchByQuery(queryBoat: QueryBoat): Promise<ResponseQueryBoats> {
    const urlFormed = `${this.API}/boat` +
      `?page=${queryBoat.page}` +
      `&pageSize=${queryBoat.pageSize}` +
      `&orgId=${queryBoat.orgId}` +
      `&boatName=${queryBoat.boatName}` +
      `&registrationNumber=${queryBoat.registrationNumber}`;
    const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();
    return response as ResponseQueryBoats;
  }

  public async archiveByID(id: number): Promise<boolean> {
    try {
      const urlFormed = `${this.API}/boat/${id}/archive`;
      const response: any = await this.httpClientService.putWithAuth(urlFormed, {}).toPromise();
      return response.confirmation
    } catch (error) {
      return false;
    }
  }

  public async create(boat: Boat): Promise<CreateBoatResponseLocal> {

    const messageError = "Algo salió mal, no se pudo agregar la embarcación, inténtalo nuevamente."
    const messageSuccess = "Embarcación agregada con éxito."

    try {

      // Need this to fix object before sending to backend
      Object.keys(boat).forEach(
        (key, index) => {
          if (boat[key] === '' || boat[key] === 0 || boat[key] == undefined || boat[key] == null) {
            delete boat[key]
          }
        }
      );

      const urlFormed = `${this.API}/boat`;
      const response = await this.httpClientService.postWithAuth(urlFormed, boat).toPromise();

      const responseCreateOK = response as ResponseCreateOK;
      const message = responseCreateOK.confirmation ? messageSuccess : messageError;

      return {
        wasSuccess: responseCreateOK.confirmation,
        message: message,
      };

    } catch (error) {

      const responseCreateError = error.error as ResponseCreateError;

      // const messageError = responseCreateError
      //   ? responseCreateError.message
      //   : "Algo salió mal, no se pudo agregar la embarcación, inténtalo nuevamente.";

      return { wasSuccess: false, message: messageError };

    }
  }

  public async updateByID(id: number, boat: Boat): Promise<ResponseUpdateBoat> {

    try {

      // Need this to fix object before sending to backend
      // drop values null, zeros, undefined
      Object.keys(boat).forEach((key, index) => {
        if (boat[key] === '' || boat[key] === 0 || boat[key] == undefined || boat[key] == null) {
          delete boat[key]
        }
      });

      const urlFormed = `${this.API}/boat/${id}`;
      const response = await this.httpClientService.putWithAuth(urlFormed, boat).toPromise();
      const responseUpdateBoatOK = response as ResponseUpdateBoatOK;
      return {
        wasSuccess: responseUpdateBoatOK.confirmation,
        message: "Cambios realizados con éxito."
      }
    } catch (error) {

      return {
        wasSuccess: false,
        message: "Algo salió mal, no se pudo actualizar la información, inténtalo nuevamente."
      };

    }

  }



  public async deleteByID(id: number): Promise<DeleteBoatByIDResponseLocal> {

    const messageError: string = "Algo salió mal, no se pudo eliminar la embarcación, inténtalo nuevamente."
    const messageSuccess: string = "Embarcación eliminada.";

    try {

      const response = await this
        .httpClientService
        .deleteWithAuth(`${this.API}/boat/${id}`).toPromise();

      const deleteBoatByIDResponse = response as DeleteBoatByIDResponseServer;

      return {
        success: deleteBoatByIDResponse.confirmation,
        message: deleteBoatByIDResponse.confirmation
          ? messageSuccess
          : messageError,
      }

    } catch (error) {

      return {
        success: false,
        message: messageError,
      }
    }
  }


  // * ✅✅✅

  findApi(page: number, pageSize: number, orgId: string, boatName: string, registrationNumber: string): Observable<any> {
    return this.httpClientService.getWithAuth(`${this.API} /boat?page=${page}&pageSize=${pageSize}&orgId=${orgId}&boatName=${boatName}&registrationNumber=${registrationNumber}`);
  }

  findByIdApi(id: number): Observable<any> {
    return this.httpClientService.getWithAuth(`${this.API}/boat/${id}`)
  }

  createApi(boat: any): Observable<any> {
    return this.httpClientService.postWithAuth(`${this.API}/boat`, boat)
  }

  deleteApi(id: number): Observable<any> {
    return this.httpClientService.deleteWithAuth(`${this.API}/boat/${id}`)
  }

  updateApi(id: number, boat: any): Observable<any> {
    return this.httpClientService.putWithAuth(`${this.API}/boat/${id}`, boat)
  }

  typeAheadApi(query: string = ""): Observable<any> {
    return this.httpClientService.getWithAuth(`${this.API}/boat/typeahead/${query}`)
  }

  archiveApi(id: number): Observable<any> {
    return this.httpClientService.putWithAuth(`${this.API}/boat/${id}/archive`, {})
  }
}
