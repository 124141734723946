
const INVALID_CHAR_SPECIAL = { "INVALID_CHAR_SPECIAL": "Un caracter especial" };
const INVALID_CAPITAL_LETTER = { "INVALID_CAPITAL_LETTER": "Una mayúscula" };
const INVALID_LOWER_LETTER = { "INVALID_LOWER_LETTER": "Una minúscula" };
const INVALID_A_NUMBER = { "INVALID_A_NUMBER": "Un número" };
const PASS_ARE_NOT_SAME = { "PASS_ARE_NOT_SAME": "Error, las contraseñas no coinciden" };
const PASS_ARE_SAME = { "PASS_ARE_NOT_SAME": null };

export const PASS_ERRORS = {
    INVALID_CHAR_SPECIAL: INVALID_CHAR_SPECIAL,
    INVALID_CAPITAL_LETTER: INVALID_CAPITAL_LETTER,
    INVALID_LOWER_LETTER: INVALID_LOWER_LETTER,
    INVALID_A_NUMBER: INVALID_A_NUMBER,
    PASS_ARE_NOT_SAME: PASS_ARE_NOT_SAME,
    PASS_ARE_SAME: PASS_ARE_SAME,
}

export type PASS_ERRORS_TYPE = keyof typeof PASS_ERRORS;