import { Component, Inject } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../../services/users/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service';
import { ForgotPasswordRequest } from 'src/app/models/forgot_password_request';
import { DropdownOption } from 'src/app/models/dropdown_option';
import { CooperativaService } from 'src/app/services/organization.service';
import { ADMIN_ROLE, CO_ADMIN_ROLE, USER_ROLE } from 'src/app/models/roles';
import { MALE_GENDER, FEMALE_GENDER, OTHER_GENDER } from 'src/app/models/genders';
import { User } from 'src/app/models/user';
import { BoatService } from 'src/app/services/boat.service';
import { debounce, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Organization } from 'src/app/models/organization';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent {
  public userForm: FormGroup;
  public first_name = new FormControl('', Validators.required);
  public last_name = new FormControl('', Validators.required);
  public username = new FormControl('', Validators.required);
  public email = new FormControl('', Validators.required);
  public birthday = new FormControl('');
  public birthplace = new FormControl('');
  public boatName = new FormControl('');
  public orgName = new FormControl('');
  public selectedOrg: string;
  public orgs: Organization[];

  public active = new FormControl(true);
  public selectedRole = 'user';
  public roles: DropdownOption[];

  public selectedGender: string;
  public genders: DropdownOption[];
  public boats: [];
  submitted = false;
  isEditComponent = false;
  isCoop: boolean;
  userSession: User;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddUserComponent>,
    private userService: UserService,
    private matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private authSvc: AuthService,
    private orgSvc: CooperativaService,
    private boatSvc: BoatService,
  ) {
    this.userForm = fb.group({
      'first_name': this.first_name,
      'last_name': this.last_name,
      'email': this.email,
      'username': this.username,
      'birthplace': this.birthplace,
      'birthday': this.birthday,
      'active': this.active,
      'boatName': this.boatName,
      'orgName': this.orgName
    })
    this.roles = [];
    this.boats = [];
    this.orgs = [];
    this.genders = [];

    this.selectedGender = '';
    this.selectedOrg = '';
    this.isCoop = false;

    this.userSession = new User();
    let us = this.authSvc.getUserData();
    if (!us) {
      this.matSnackBar.open('Error loading user session.', 'Cerrar', { duration: 4000 });
    }
    this.userSession = us;

    if (this.userSession.role === CO_ADMIN_ROLE) {
      this.roles = [
        { value: CO_ADMIN_ROLE, title: 'Administrador de la organización pesquera' },
        { value: USER_ROLE, title: 'Usuario' },
      ];
      this.selectedOrg = this.userSession.organization_id.toString();
    } else {
      this.roles = [
        { value: ADMIN_ROLE, title: 'Administrador' },
        { value: CO_ADMIN_ROLE, title: 'Administrador de la organización pesquera' },
        { value: USER_ROLE, title: 'Usuario' },
      ];
    }
    this.selectedRole = USER_ROLE;

    this.genders = [
      { value: MALE_GENDER, title: 'Hombre' },
      { value: FEMALE_GENDER, title: 'Mujer' },
      { value: OTHER_GENDER, title: 'No especificar' },
    ];

    if (this.userSession.role === ADMIN_ROLE) {
      let orgName = this.userForm.get('orgName')
      orgName.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe(res => {
          this.filterOrganization()
        })
    }

    let queryName = this.userForm.get('boatName');
    queryName.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    )
      .subscribe(res => {
        this.filterBoats();
      })



  }

  ngOnInit() {
    this.onRoleSelect();
    if (this.data.isEditComponent) {
      this.isEditComponent = true;
      this.userForm.controls['first_name'].setValue(this.data.user.first_name);
      this.userForm.controls['last_name'].setValue(this.data.user.last_name);
      this.userForm.controls['username'].setValue(this.data.user.username);
      this.userForm.controls['email'].setValue(this.data.user.email);
      this.userForm.controls['birthplace'].setValue(this.data.user.birthplace);
      this.userForm.controls['birthday'].setValue(this.data.user.birthday);
      this.userForm.controls['active'].setValue(this.data.user.active);
      if (this.data.user.boat_id) {
        this.boatSvc.findByIdApi(this.data.user.boat_id).subscribe(
          res => {
            this.userForm.controls['boatName'].setValue(res.name)
          },
          err => {
            console.log(err);
          }
        )
      }
      if (this.data.user.organization_id) {
        this.orgSvc.findByIdApi(this.data.user.organization_id).subscribe(
          res => {
            this.userForm.controls['orgName'].setValue(res.name);
          },
          err => {
            console.log(err);
          }
        )
      }
      this.selectedRole = this.data.user.role;
      this.selectedGender = this.data.user.gender;
      this.onRoleSelect();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  get f(): any { return this.userForm.controls; }

  filterBoats() {
    let boatName = this.userForm.get('boatName').value
    if (boatName != "") {
      this.boatSvc.typeAheadApi(boatName).subscribe(
        res => {
          this.boats = res
        },
        err => {
          console.log(err);
        }
      )
    }
  }

  filterOrganization() {
    let orgName = this.userForm.get('orgName').value
    if (orgName != "") {
      this.orgSvc.typeaheadApi(orgName).subscribe(
        res => {
          this.orgs = res;
        },
        err => {
          console.log(err)
        }
      )
    }
  }

  submit(): void {
    this.submitted = true;
    let boatName = this.userForm.get('boatName').value;
    let orgName = this.userForm.get('orgName').value;
    let boatId = null;
    let orgId = null;
    if (boatName != "") {
      for (let boat of this.boats) {
        if (boat['name'] === boatName) {
          boatId = boat['id']
        }
      }
    }

    if (orgName != "") {
      for (let org of this.orgs) {
        if (org.name === orgName) {
          orgId = org.id;
        }
      }
    }

    if (this.userForm.valid) {
      if (this.selectedRole == '') {
        this.matSnackBar.open("Debes seleccionar un rol.", 'Cerrar', { duration: 4000 });
        return;
      }

      if (this.isCoop || this.selectedRole === CO_ADMIN_ROLE || this.userSession.role === CO_ADMIN_ROLE) {
        if (orgName == '' && this.selectedRole != USER_ROLE) {
          this.matSnackBar.open("Debes seleccionar una organización.", 'Cerrar', { duration: 4000 });
          return;
        }
        orgId = parseInt(orgId);
      }

      //============================================================
      // set [organization_id]. If the role from userRole is:
      //  - CO_ADMIN_ROLE -> Organización Pesquera: use his [organization_id]
      if (this.userSession.role === CO_ADMIN_ROLE) {
        orgId = this.userSession.organization_id.toString();
      }
      //============================================================

      let user: any = {
        first_name: this.userForm.get('first_name').value,
        last_name: this.userForm.get('last_name').value,
        username: this.userForm.get('username').value,
        email: this.userForm.get('email').value,
        active: this.userForm.get('active').value,
        organization_id: orgId,
        boat_id: boatId,
        role: this.selectedRole,
        gender: this.selectedGender,
        birthplace: this.userForm.get('birthplace').value,
        birthday: this.userForm.get('birthday').value,
        password: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      };

      // Need this to fix object before sending to backend
      Object.keys(user).forEach((key, index) => {
        if (user[key] === '' || user[key] === 0 || user[key] == undefined || user[key] == null) {
          delete user[key]
        }
      });

      if (this.isEditComponent) {
        delete user.password;
        if (user.role == 'admin') {
          user.organization_id = null;
        }

        if (!boatId) {
          user.boat_id = null;
        }
        this.userService.updateApi(this.data.user.id, user).subscribe(
          response => {
            if (response.confirmation == true) {
              this.matSnackBar.open('User updated succesfully', 'Cerrar', { duration: 4000 });
            }
            this.dialogRef.close();
          },
          err => {
            console.log(err);
            if (err.error.confirmation == false) {
              this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', { duration: 4000 });
            }
          }
        )
        return;
      }

      this.userService.createApi(user).subscribe(
        response => {
          if (response.confirmation == true) {
            this.matSnackBar.open('User added succesfully', 'Cerrar', { duration: 4000 });
            // trigger forgot password
            let fpreq = new ForgotPasswordRequest();
            fpreq.email = user.email;
            this.authSvc.forgotPasswordApi(fpreq)
              .subscribe(res => { },
                err => {
                  console.log(err);
                  if (err.error.confirmation == false) {
                    this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', { duration: 4000 });
                  }
                });
          }
          this.dialogRef.close();
        },
        err => {
          console.log(err);
          if (err.error.confirmation == false) {
            this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', { duration: 4000 });
          }
        }
      )
    }
  }

  onRoleSelect() {
    this.isCoop = false;
    if ((this.selectedRole == CO_ADMIN_ROLE || this.selectedRole == USER_ROLE) && this.userSession.role === ADMIN_ROLE) {
      this.isCoop = true;

      if (this.isEditComponent && this.data.user.organization_id) {
        this.selectedOrg = this.data.user.organization_id.toString();
      }
    }
  }

}
