import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar } from '@angular/material';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { CertificatesService } from 'src/app/services/certificates.service';
import { AddCertificateComponent } from './add-certificate/add-certificate.component';
import { merge, observable, of as observableOf } from 'rxjs';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements AfterViewInit {
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  public certificates: [];
  public loadingCertificates: boolean;
  public certificatesLength;
  public deleteDialogRef;
  public deleteCertificationIdHolder;

  constructor(
    public dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private certificatesService: CertificatesService
  ) { 
    this.certificates = [];
    this.loadingCertificates = true;
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  loadData() {
    merge(this.paginator.page, this.paginator.pageSize)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingCertificates = true;
          return this.certificatesService.findApi(this.paginator.pageIndex, this.paginator.pageSize)
        }),
        map(data => {
          this.loadingCertificates = false;
          this.certificatesLength = data['count'];

          return data['rows'];
        }),
        catchError((err) => {
          this.loadingCertificates = false;
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.certificates = data;
      })
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(AddCertificateComponent, {
      width: '500px',
      height: 'auto',
    })

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    })
  }

  editCertification(certification) {
    let editDialogRef = this.dialog.open(AddCertificateComponent, {
      width: '500px',
      height: 'auto',
      data: { isEditComponent: true, certification: certification}
    })
    
    editDialogRef.afterClosed().subscribe(result => {
      this.loadData();
    })
  }

  cancelDeleteDialog() {
    this.deleteDialogRef.close();
  }

  delete(deleteDialog, id) {
    this.deleteCertificationIdHolder = id;
    this.deleteDialogRef = this.dialog.open(deleteDialog, {
      width: '500px'
    })

    this.deleteDialogRef.afterClosed().subscribe(
      result => {
        this.loadData();
      }
    )
  }

  confirmDelete() {
    this.certificatesService.deleteApi(this.deleteCertificationIdHolder).subscribe(
      result => {
        if (result.confirmation) {
          this.matSnackBar.open('Certificación sanitaria borrado con éxito', 'Cerrar', {duration: 4000});
            return;
        }
        this.matSnackBar.open('Error al borrar certificación sanitaria', 'Cerrar', {duration: 4000});
      },
      err => {
        console.log(err);
      }
    )

    this.deleteDialogRef.close();
  }
}
