import { Injectable } from '@angular/core';
import { HttpclientService } from './httpclient.service';
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  apiUrl: string;

  constructor(private httpClient: HttpclientService) {
    this.apiUrl = environment.apiUrl;
  }

  findApi(page: number, pageSize: number, query: string, flagged: string = ''): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/post?page=${page}&pageSize=${pageSize}&query=${query}&flagged=${flagged}`)
  }

  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/post/${id}`)
  }

  updateApi(id: number, post: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/post/${id}`, post)
  }
}
