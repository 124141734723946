export class User {
    id: number;
    username: string;
    active: boolean;
    api_token: string;
    birthday: Date;
    birthplace: string;
    email: string;
    first_name: string;
    gender: string;
    last_name: string;
    oauth: string;
    organization_id: number;
    profile_img: string;
    remember_token: string;
    role: string;
    createdAt: Date;
    updatedAt: Date;

    constructor() {
        this.id = 0;
        this.username = '';
        this.active = true;
        this.api_token = '';
        this.birthday = new Date();
        this.birthplace = '';
        this.email = '';
        this.first_name = '';
        this.gender = '';
        this.last_name = '';
        this.oauth = '';
        this.organization_id = 0;
        this.profile_img = '';
        this.remember_token = '';
        this.role = '';
        this.createdAt = new Date();
        this.updatedAt = new Date();
    }
}