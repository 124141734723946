import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { ADMIN_ROLE } from 'src/app/models/roles';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { BoatService } from 'src/app/services/boat.service';
import { CooperativaService } from 'src/app/services/organization.service';
import { PermitTypeService } from 'src/app/services/permit-type.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { ViewChild } from '@angular/core';
// import { SpeciesService } from 'src/app/services/species.service';
import { PermitService } from 'src/app/services/permit.service';
import { SessionLimitService } from 'src/app/services/session-limit.service';
import { PermitsOrganizationsService } from 'src/app/services/permits-organizations.service';
import { PermitsBoatsService } from 'src/app/services/permits-boats.service';
import { PermitsSpeciesService } from 'src/app/services/permits-species.service';
import { Organization } from 'src/app/models/organization';
import { SpeciesService } from 'src/app/services/species/species.service';

@Component({
  selector: 'app-add-fishing-permits',
  templateUrl: './add-fishing-permits.component.html',
  styleUrls: ['./add-fishing-permits.component.scss']
})
export class AddFishingPermitsComponent implements OnInit {

  public fishingPermitForm: FormGroup;
  public limitForm: FormGroup;
  public associationForm: FormGroup;

  //fishing Permits form
  public permit_id = new FormControl('', Validators.required);
  public fishing_zone = new FormControl('');
  public start_date = new FormControl('', Validators.required);
  public expiration_date = new FormControl('', Validators.required);
  public limit = new FormControl(false);
  public geographic_coordinates = new FormControl(false);
  public renewal_reminder = new FormControl(false);
  public renewal_reminder_date = new FormControl('');

  //limit form
  public weight = new FormControl('');
  public quantity = new FormControl('');
  public season_start_date = new FormControl('');
  public season_end_date = new FormControl('');

  //association form
  public boatName = new FormControl('');
  public organizationName = new FormControl('');
  public specieName = new FormControl('');

  public multiple = new FormControl(false);
  public group = new FormControl(false);


  public permitTypes: [];
  public organizations: [];
  public boats: [];
  public species: [];
  public selectedPermitType: string;
  public userSession: User;
  public selectedOrg: string;
  public speciesType: boolean;

  public singleInput: number = 0;
  public groupType: string = '';
  public submitted: boolean = false;

  org: Organization;



  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  filteredFruits: Observable<string[]>;
  selectedSpecies: any[];
  selectedBoats: any[];

  currentFishingPermit;
  isEditComponent: boolean;

  @ViewChild('fruitInput', { static: true }) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('boatInput', { static: true }) boatInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private permitTypeService: PermitTypeService,
    private organizationService: CooperativaService,
    private boatService: BoatService,
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
    private specieService: SpeciesService,
    private fishingPermitService: PermitService,
    private limitService: SessionLimitService,
    private permitsOrganizationService: PermitsOrganizationsService,
    private permitsBoatsService: PermitsBoatsService,
    private permitSpeciesService: PermitsSpeciesService,
  ) {
    this.fishingPermitForm = fb.group({
      'permit_id': this.permit_id,
      'fishing_zone': this.fishing_zone,
      'start_date': this.start_date,
      'expiration_date': this.expiration_date,
      'limit': this.limit,
      'renewal_reminder': this.renewal_reminder,
      'geographic_coordinates': this.geographic_coordinates,
      'renewal_reminder_date': this.renewal_reminder_date
    })
    this.limitForm = fb.group({
      'weight': this.weight,
      'quantity': this.quantity,
      'season_start_date': this.season_start_date,
      'season_end_date': this.season_end_date
    })

    this.associationForm = fb.group({
      'boatName': this.boatName,
      'organizationName': this.organizationName,
      'specieName': this.specieName,
      'multiple': this.multiple
    })
    this.permitTypes = [];
    this.organizations = [];
    this.boats = [];
    this.species = [];
    this.selectedSpecies = [];
    this.selectedBoats = [];
    this.selectedPermitType = '';
    this.selectedOrg = '';

    this.userSession = new User();

    // this checking if the component have the task to modify permission
    if (this.router.getCurrentNavigation().extras.state) {
      let state = this.router.getCurrentNavigation().extras.state;
      if (state) {
        this.isEditComponent = true;
        this.currentFishingPermit = state.fishingPermit;

        this.fishingPermitForm.controls['permit_id'].setValue(this.currentFishingPermit.permit_id);
        this.fishingPermitForm.controls['fishing_zone'].setValue(this.currentFishingPermit.fishing_zone);
        this.fishingPermitForm.controls['start_date'].setValue(this.currentFishingPermit.start_date);
        this.fishingPermitForm.controls['expiration_date'].setValue(this.currentFishingPermit.expiration_date);
        this.fishingPermitForm.controls['limit'].setValue(this.currentFishingPermit.limit);
        this.fishingPermitForm.controls['renewal_reminder'].setValue(this.currentFishingPermit.renewal_reminder);
        this.fishingPermitForm.controls['geographic_coordinates'].setValue(this.currentFishingPermit.geographic_coordinates)
        this.fishingPermitForm.controls['renewal_reminder_date'].setValue(this.currentFishingPermit.renewal_reminder_date)
        if (this.currentFishingPermit.limit) {
          this.limitForm.controls['weight'].setValue(this.currentFishingPermit.limits[0].weight);
          this.limitForm.controls['quantity'].setValue(this.currentFishingPermit.limits[0].quantity);
          this.limitForm.controls['season_start_date'].setValue(this.currentFishingPermit.limits[0].season_start_date);
          this.limitForm.controls['season_end_date'].setValue(this.currentFishingPermit.limits[0].season_end_date);
        }
        this.permitTypeService.findApi(0, 50).subscribe(
          res => {
            console.log(res);
            this.permitTypes = res.rows
            let permitType = this.permitTypes.find((permit) => permit['id'] == this.currentFishingPermit.permits_type_fk);
            this.selectedPermitType = permitType['id'];
          },
          err => {
            console.log(err);
          }
        )

        this.selectedSpecies = this.currentFishingPermit.species;
        if (this.selectedSpecies.length > 1) {
          this.associationForm.controls['multiple'].setValue(true);
        }

        if (this.currentFishingPermit.boats != 0) {
          this.associationForm.controls['boatName'].setValue(this.currentFishingPermit.boats[0].name)
        }
      }
    }
  }

  ngOnInit() {

    let us = this.authService.getUserData();
    if (!us) {
      this.matSnackBar.open('Error loading user session.', 'Cerrar', { duration: 4000 });
    }
    this.userSession = us;

    this.filterBoats();

    this.organizationService.findByIdApi(this.userSession.organization_id)
      .subscribe(res => {
        this.org = res;
      }, err => {
        console.log(err);
        if (err.error.confirmation == false) {
          this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', { duration: 4000 });
        }
      },
        () => {
          this.associationForm.controls['organizationName'].setValue(this.org.name)
        }
      );

    this.permitTypeService.findApi(0, 50).subscribe(
      res => {
        this.permitTypes = res.rows
      },
      err => {
        console.log(err);
      }
    )

    let queryName = this.associationForm.get('boatName');
    let orgName = this.associationForm.get('organizationName');
    let specieName = this.associationForm.get('specieName');
    queryName.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    )
      .subscribe((res) => {
        this.filterBoats();
      })

    orgName.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    )
      .subscribe((res) => {
        this.filterOrganization();
      })

    specieName.valueChanges.pipe(
      startWith(null),
      debounceTime(400),
      distinctUntilChanged(),
    )
      .subscribe(res => {
        this.filterSpecies();
      })
  }


  get f(): any { return this.fishingPermitForm.controls; }

  remove(index): void {
    if (index >= 0) {
      this.selectedSpecies.splice(index, 1);
      this.singleInput = 0;
    }
  }

  removeBoat(index): void {
    if (index >= 0) {
      this.selectedBoats.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.singleInput == 1 && !this.speciesType) {
      this.matSnackBar.open('Favor de seleccionar multiples entradas', 'Cerrar', { duration: 4000 })
      return;
    }

    this.selectedSpecies.push({
      id: event.option.value,
      common_name: event.option.viewValue
    });

    this.fruitInput.nativeElement.value = '';
    this.specieName.setValue(null);
    this.singleInput += 1;
  }

  selectedBoat(event: MatAutocompleteSelectedEvent): void {
    this.selectedBoats.push({
      id: event.option.value,
      name: event.option.viewValue
    })

    this.boatInput.nativeElement.value = '';
    this.boatName.setValue(null);
  }

  filterSpecies() {
    let specieName = this.associationForm.get('specieName').value

    if (specieName != "" && specieName != null) {
      this.specieService.typeaheadApi(specieName, this.groupType).subscribe(
        res => {
          this.species = res
        },
        err => {
          console.log(err);
        }
      )
    }
  }


  clickLimit(e) {
  }
  onClickClear() {
    this.router.navigate(['/dashboard/fishing-permits'])
  }

  filterBoats() {
    let boatName = this.associationForm.get('boatName').value
    if (boatName != "") {
      this.boatService.typeAheadApi(boatName).subscribe(
        res => {
          this.boats = res
        },
        err => {
          console.log(err);
        }
      )
    }
  }

  filterOrganization() {
    let orgName = this.associationForm.get('organizationName').value;

    if (orgName != "") {
      this.organizationService.typeaheadApi(orgName).subscribe(
        res => {
          this.organizations = res;
        },
        err => {
          console.log(err);
        }
      )
    }
  }

  setValue(event) {
    this.speciesType = event.checked;
    this.associationForm.controls['multiply'].setValue(event.checked);
  }

  setGroup(event) {
    this.groupType = "0";
    if (event.checked) {
      this.groupType = "1"
    }
  }

  onSubmit() {
    this.submitted = true;

    let fishingPermit = {
      permit_id: this.fishingPermitForm.get('permit_id').value,
      fishing_zone: this.fishingPermitForm.get('fishing_zone').value,
      start_date: this.fishingPermitForm.get('start_date').value,
      expiration_date: this.fishingPermitForm.get('expiration_date').value,
      limit: this.fishingPermitForm.get('limit').value,
      geographic_coordinates: this.fishingPermitForm.get('geographic_coordinates').value,
      renewal_reminder: this.fishingPermitForm.get('renewal_reminder').value,
      renewal_reminder_date: this.fishingPermitForm.get('renewal_reminder_date').value,
      permits_type_fk: this.selectedPermitType
    }

    const isEmptyFishingZone = fishingPermit.fishing_zone == '';
    if (isEmptyFishingZone) {
      this.matSnackBar.open('Debes ingresar una zona de pesca', 'Cerrar', { duration: 4000 })
      return;
    }

    const permissionHaveRenewalReminder = this.fishingPermitForm.get('renewal_reminder').value;
    if (permissionHaveRenewalReminder == false) {
      delete fishingPermit.renewal_reminder_date;
    }

    const permitsTypeFKString = fishingPermit.permits_type_fk;
    const isValidPermissionTypeFK = new RegExp(/^[\d]+$/g).test(permitsTypeFKString);
    if (isValidPermissionTypeFK == false) {
      this.matSnackBar.open('Debes seleccionar un tipo de permiso', 'Cerrar', { duration: 4000 })
      return;
    }

    let orgName = this.associationForm.get('organizationName').value;
    let org_id;

    let boatName = this.associationForm.get('boatName').value
    let boat_id;

    if (this.isEditComponent) {
      this.fishingPermitService.updateApi(this.currentFishingPermit.id, fishingPermit).subscribe(
        res => {
          if (this.currentFishingPermit.limit == true) {
            let limit = {
              fishing_permit_fk: parseInt(this.currentFishingPermit.id),
              weight: this.limitForm.get('weight').value,
              quantity: this.limitForm.get('quantity').value,
              season_start_date: this.limitForm.get('season_start_date').value,
              season_end_date: this.limitForm.get('season_end_date').value,
            }
            this.limitService.updateApi(this.currentFishingPermit.limits[0].id, limit).subscribe(
              res => {

              },
              err => {
                console.log(err);
                this.matSnackBar.open('Error al crear Límite', 'Cerrar', { duration: 4000 })
                return;
              }
            )

            if (orgName != '') {
              for (let org of this.organizations) {
                if (org['name'] == orgName) {
                  org_id = org['id']
                }
              }

              let permitsOrganization = {
                fishing_permit_id: this.currentFishingPermit.id,
                organization_id: org_id
              }

              this.permitsOrganizationService.createApi(permitsOrganization).subscribe(
                res => {

                },
                err => {
                  console.log(err);
                  this.matSnackBar.open('Error al asociar organización', 'Cerrar', { duration: 4000 })
                  return;
                }
              )
            }

            if (this.selectedBoats.length != 0) {
              let request = {
                fishing_permit_id: this.currentFishingPermit.id,
                boat_id: []
              }

              for (let req of this.selectedBoats) {
                if (typeof req.id == 'number') {
                  request.boat_id.push(req.id)
                }
              }

              this.permitsBoatsService.createApi(request).subscribe(
                res => { },
                err => {
                  console.log(err);
                  this.matSnackBar.open('Error al asociar embarcación', 'Cerrar', { duration: 4000 })
                  return;
                }
              )
            }

            if (this.selectedSpecies.length != 0) {
              let request = {
                fishing_permit_id: this.currentFishingPermit.id,
                species_id: [],
              };

              for (let req of this.selectedSpecies) {
                if (typeof req.id == 'number') {
                  request.species_id.push(req.id)
                }
                if (typeof req.id == 'object') {
                  for (let id of req.id) {
                    request.species_id.push(id);
                  }
                }
              }

              this.permitSpeciesService.createApi(request).subscribe(
                res => { },
                err => {
                  console.log(err);
                  this.matSnackBar.open('Error al asociar especies', 'Cerrar', { duration: 4000 })
                  return;
                }
              )
            }

          }
        },
        err => {
          console.log(err);
        }
      )
      this.router.navigate(['/dashboard/fishing-permits'])
      return;
    }

    this.fishingPermitService.createApi(fishingPermit).subscribe(
      res => {
        if (res.confirmation) {
          if (res.data.limit == true) {
            let limit = {
              fishing_permit_fk: parseInt(res.data.id),
              weight: this.limitForm.get('weight').value,
              quantity: this.limitForm.get('quantity').value,
              season_start_date: this.limitForm.get('season_start_date').value,
              season_end_date: this.limitForm.get('season_end_date').value,
            }

            this.limitService.createApi(limit).subscribe(
              res => {

              },
              err => {
                console.log(err);
                this.matSnackBar.open('Error al crear Límite', 'Cerrar', { duration: 4000 })
                return;
              }
            )
          }

          if (orgName != '') {
            for (let org of this.organizations) {
              if (org['name'] == orgName) {
                org_id = org['id']
              }
            }

            let permitsOrganization = {
              fishing_permit_id: res.data.id,
              organization_id: org_id
            }

            this.permitsOrganizationService.createApi(permitsOrganization).subscribe(
              res => {

              },
              err => {
                console.log(err);
                this.matSnackBar.open('Error al asociar organización', 'Cerrar', { duration: 4000 })
                return;
              }
            )
          }

          if (this.selectedBoats.length != 0) {
            let request = {
              fishing_permit_id: res.data.id,
              boat_id: []
            }

            for (let req of this.selectedBoats) {
              if (typeof req.id == 'number') {
                request.boat_id.push(req.id)
              }
            }

            this.permitsBoatsService.createApi(request).subscribe(
              res => { },
              err => {
                console.log(err);
                this.matSnackBar.open('Error al asociar embarcación', 'Cerrar', { duration: 4000 })
                return;
              }
            )
          }


          if (this.selectedSpecies.length != 0) {
            let request = {
              fishing_permit_id: res.data.id,
              species_id: [],
            };

            for (let req of this.selectedSpecies) {
              if (typeof req.id == 'number') {
                request.species_id.push(req.id)
              }
              if (typeof req.id == 'object') {
                for (let id of req.id) {
                  request.species_id.push(id);
                }
              }
            }

            this.permitSpeciesService.createApi(request).subscribe(
              res => { },
              err => {
                console.log(err);
                this.matSnackBar.open('Error al asociar especies', 'Cerrar', { duration: 4000 })
                return;
              }
            )
          }


        }
      },
      err => {
        console.log(err);
        this.matSnackBar.open('Error al crear permiso de pesca', 'Cerrar', { duration: 4000 })
        return;
      }
    )
  }

}
