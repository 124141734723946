export class State {
    id: number;
    name: string;
    countryId: number;

    constructor() {
        this.id = 0;
        this.name = '';
        this.countryId = 0;
    }
}