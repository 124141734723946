import { AbstractControl, FormControl, ValidationErrors } from "@angular/forms";
import { Input } from "src/app/models/forms/interface.input";
import { PASS_ERRORS } from "./pass.errors";

export class PassInput extends Input<string>  {

    public FIELD_NAME: string = "PASS";

    constructor() {
        super();
    }

    validator(formControl: AbstractControl): ValidationErrors {

        let errors: ValidationErrors = {};

        const passValue: string = (formControl as FormControl).value;

        if (hasSpecialChar(passValue) == false)
            errors = Object.assign(errors, PASS_ERRORS.INVALID_CHAR_SPECIAL)

        if (hasCapitalLetter(passValue) == false)
            errors = Object.assign(errors, PASS_ERRORS.INVALID_CAPITAL_LETTER)

        if (hasLowerLetter(passValue) == false)
            errors = Object.assign(errors, PASS_ERRORS.INVALID_LOWER_LETTER)

        if (hasANumber(passValue) == false)
            errors = Object.assign(errors, PASS_ERRORS.INVALID_A_NUMBER)

        const hasErrorKeys = Object.keys(errors);

        return hasErrorKeys.length === 0 ? null : errors;
    }
}

const hasSpecialChar = (value: string) => {
    const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const regExp = new RegExp(specialChars);
    const hasSpecialChar: boolean = regExp.test(value);
    return hasSpecialChar;
}

const hasCapitalLetter = (value: string): boolean => {
    const hasCapitalLetter: boolean = /[A-Z]/.test(value);
    return hasCapitalLetter;
}

const hasLowerLetter = (value: string): boolean => {
    const hasLowerLetter: boolean = /[a-z]/.test(value);
    return hasLowerLetter;
}

const hasANumber = (value: string): boolean => {
    const hasANumber: boolean = /[0-9]/.test(value);
    return hasANumber;
}