import { Component, ViewChild, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent, merge, Observable, of as observableOf } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { UserService } from '../../../services/users/user.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddUserComponent } from '../add-user/add-user.component';
// TODO - drop this down
// import { User } from 'src/app/models/user'; T
import { AuthService } from 'src/app/services/auth.service';
import { DropdownOption } from 'src/app/models/dropdown_option';
import { CooperativaService } from 'src/app/services/organization.service';
import { ADMIN_ROLE } from 'src/app/models/roles';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileValidator } from 'ngx-material-file-input';
import { Organization } from 'src/app/models/organization';
import { User } from 'src/app/models/interface.user';


@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements AfterViewInit {

  // data
  public data: User[];
  public userSession: User;

  // ui
  // public displayedColumns: string[] = ['actions', 'first_name', 'last_name', 'org_name', 'boat_name', 'email', 'username', 'role', 'birthday', 'birthplace', 'createdAt', 'active'];
  public displayedColumns: string[] = [
    'first_name', 'last_name',
    'org_name', 'boat_name',
    'role', 'birthday',
    'createdAt', 'active'
  ];
  @ViewChild('fieldSearcherInput', { "static": true }) private searcherInput!: ElementRef<HTMLInputElement>;

  // * ✅✅✅


  public filterForm: FormGroup;
  public orgName = new FormControl('');

  deleteUserIdHolder;
  deleteUserDialogRef;
  uploadUserDialogRef;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  resultsLength: number;
  isLoadingResults: boolean;
  searchQuery: string;
  selectedOrg: string;
  public orgs: Organization[];
  userToDelete: any;
  inputUsers: File;

  public dialogTitle = '¿Estás seguro de que quieres eliminar este usare?';
  public archiveDialogButton = "Archive";

  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private authSvc: AuthService,
    private _orgSvc: CooperativaService,
    private fb: FormBuilder
  ) {

    this.loadCurrentUserLogged(); // * ✅✅✅

    this.filterForm = this.fb.group({
      'orgName': this.orgName
    })
    this.data = [];
    this.resultsLength = 0;
    this.isLoadingResults = true;

    this.searchQuery = '';
    this.selectedOrg = '';

    this.orgs = [];
    if (this.userSession.role === ADMIN_ROLE) {
      let orgName = this.filterForm.get('orgName')
      orgName.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe(res => {
          this.filterOrganization()
        })
    }
  }

  ngAfterViewInit() {
    this.loadData();
    this.subscribeToSearcherInput();
  }

  public subscribeToSearcherInput() {
    const input = this.searcherInput.nativeElement;
    const source = fromEvent(input, "keyup");
    //map to string with given event timestamp
    source
      // .pipe(
      //   map((event: Event, index: number) => {
      //     console.log(`[event] -> `, event);
      //     console.log(`[index] -> `, index);
      //   }))
      .subscribe(
        (val: Event) => console.log(`[val] -> `, val)
      );
  }

  // ========================================================================================================================
  // * ✅✅✅
  // ========================================================================================================================

  private loadCurrentUserLogged() {
    const currentUserLogged = this.authSvc.getCurrentUserLogged;
    currentUserLogged
      ? this.userSession = (currentUserLogged as unknown as User)
      : this.matSnackBar.open('Error loading user session.', 'Cerrar', { duration: 4000 });
  }

  getInputFile(evt) {
    let af = ['text/csv']

    if (evt.length > 0) {
      const file = evt[0];
      if (!af.includes(file.type)) {
        this.matSnackBar.open('Favor de subir archivo de CSV.', 'Cerrar', { duration: 4000 })
        return;
      }
      else {
        this.inputUsers = file;
      }
    }
  }

  filterOrganization() {
    let orgName = this.filterForm.get('orgName').value;
    if (orgName != "") {
      this._orgSvc.typeaheadApi(orgName).subscribe(
        res => {
          this.orgs = res;
        },
        err => {
          console.log(err)
        }
      )
    }
  }

  sendData(uploadUserDialog) {
    if (!this.inputUsers) {
      this.matSnackBar.open('Favor de subir archivo de CSV', 'Cerrar', { duration: 4000 })
      return
    }

    this.uploadUserDialogRef = this.dialog.open(uploadUserDialog, {
      width: '500px',
    });
  }
  confirmUserImport() {
    this.userService.uploadApi(this.inputUsers).subscribe(
      res => {
        this.matSnackBar.open(`Successfully imported ${res.success.length} users`, 'Cerrar', { duration: 4000 })
      },
      err => {
        console.log(err);
      },
      () => {
        this.loadData();
      }
    )

    this.uploadUserDialogRef.close();
  }

  cancelUserImport() {
    this.uploadUserDialogRef.close();
  }

  editUser(id): void {
    let currentUser
    this.data.forEach(user => {
      if (user['id'] == id) {
        currentUser = user
      }
    })
    let editDialogRef = this.dialog.open(AddUserComponent, {
      width: '500px',
      height: 'auto',
      data: { isEditComponent: true, user: currentUser }
    });

    editDialogRef.afterClosed().subscribe(result => {
      this.loadData();
    })
  }

  openAddUserDialog(): void {
    let addUserDialogRef = this.dialog.open(AddUserComponent, {
      width: '500px',
      height: 'auto',
      data: { isEditComponent: false }
    });

    addUserDialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  deleteUser(deleteDialog, id, isArchive) {
    this.dialogTitle = '¿Estás seguro de que quieres eliminar este usare?';
    this.archiveDialogButton = "Archive";
    if (!isArchive) {
      this.dialogTitle = '¿Estás seguro de que quieres deliminar este usare?';
      this.archiveDialogButton = 'Unarchive'
    }

    let currentUser;
    this.data.forEach(user => {
      if (user['id'] == id) {
        this.userToDelete = user;
      }
    })
    this.deleteUserIdHolder = id;
    this.deleteUserDialogRef = this.dialog.open(deleteDialog, {
      width: '500px',
    });
  }

  removeUser(removeDialog, id) {
    this.data.forEach(user => {
      if (user['id'] == id) {
        this.userToDelete = user;
      }
    })
    this.deleteUserIdHolder = id;
    this.deleteUserDialogRef = this.dialog.open(removeDialog, {
      width: '500px',
    });

  }

  cancelDialog() {
    this.deleteUserDialogRef.close();
  }

  confirmUserDelete() {
    delete this.userToDelete.organization;
    this.userService.archiveApi(this.deleteUserIdHolder, this.userToDelete)
      .subscribe(
        result => {
          if (result.confirmation == true) {
            this.data.forEach((user, index) => {
              this.loadData()
            })
            this.matSnackBar.open('User deleted succesfully', 'Cerrar', { duration: 4000 });
            return;
          }
          if (result.confirmation == false) {
            this.matSnackBar.open('Error deleting user.', 'Cerrar', { duration: 4000 });
          }
        },
        err => {
          console.log(err);
        }
      )
    this.deleteUserDialogRef.close();
  }

  confirmUserRemove() {
    delete this.userToDelete.organization;
    this.userService.deleteApi(this.deleteUserIdHolder, this.userToDelete)
      .subscribe(
        result => {
          if (result.confirmation == true) {
            this.data.forEach((user, index) => {
              this.loadData()
            })
            this.matSnackBar.open('User deleted succesfully', 'Cerrar', { duration: 4000 });
            return;
          }
          if (result.confirmation == false) {
            this.matSnackBar.open('Error deleting user.', 'Cerrar', { duration: 4000 });
          }
        },
        err => {
          console.log(err);
        }
      )
    this.deleteUserDialogRef.close();
  }

  filter() {
    this.loadData();
  }

  onOrgSelection(orgId) {
    this.selectedOrg = orgId
    this.loadData();
  }

  loadData() {
    merge(this.paginator.page, this.paginator.pageSize)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.userService.findApi(this.paginator.pageIndex, this.paginator.pageSize, this.searchQuery, this.selectedOrg);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data["count"];

          return data.rows as User[];
        }),
        catchError((err) => {
          console.log(err);
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);

  }
}
