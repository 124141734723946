import { Injectable } from '@angular/core';
import { HttpclientService } from '../httpclient.service';
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { ListLogsQueryRequest } from './interface.list_logs_query.request';
import { ListLogsQueryResponse } from './interface.list_logs_query.response';
import { ListByFiltersRequest } from './interface.list-by-filters.request';
import { ListByFiltersResponse } from './interface.list-by-filters.response';

@Injectable({
  providedIn: 'root'
})
export class LogEntryService {


  private static API: string = environment.apiUrl;

  constructor(private httpClientService: HttpclientService) { }


  //============================================================
  // CREATE
  //============================================================

  //============================================================
  // READ
  //============================================================

  public async listByQuery(query: ListLogsQueryRequest): Promise<ListLogsQueryResponse> {

    try {

      const queryCooperativaKeys = Object.keys(query);
      const partsQuery = queryCooperativaKeys.map((key: string) => `${key}=${query[key]}`);
      let urlBody = partsQuery.join('&');

      const urlFormed = `${LogEntryService.API}/log?${urlBody}`;

      const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();

      return response as ListLogsQueryResponse;

    } catch (error) {

      return {
        count: 0,
        rows: []
      }

    }

  }

  public async getListByFilters(filters: ListByFiltersRequest): Promise<ListByFiltersResponse> {

    try {

      const queryCooperativaKeys = Object.keys(filters);
      const partsQuery = queryCooperativaKeys.map((key: string) => `${key}=${filters[key]}`);
      let urlBody = partsQuery.join('&');

      // {{local}}/log/users?page=0&pageSize=20&search=gaby
      const urlFormed = `${LogEntryService.API}/log/users?${urlBody}`;

      const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();

      return response as ListByFiltersResponse;

    } catch (error) {

      return { count: 0, rows: [] }

    }

  }

  //============================================================
  // UPDATE
  //============================================================

  //============================================================
  // DELETE
  //============================================================

  // * ✅✅✅

  findApi(page: number, pageSize: number, orgId: string = '', startDate: string, endDate: string, fullName: string, speciesName: string): Observable<any> {
    return this.httpClientService.getWithAuth(`${LogEntryService.API}/log?page=${page}&pageSize=${pageSize}&orgId=${orgId}&startDate=${startDate}&endDate=${endDate}&fullName=${fullName}&speciesName=${speciesName}`)
  }

  createApi(log: any): Observable<any> {
    return this.httpClientService.postWithAuth(`${LogEntryService.API}/log`, log)
  }

  deleteApi(id: number): Observable<any> {
    return this.httpClientService.deleteWithAuth(`${LogEntryService.API}/log/${id}`)
  }

  updateApi(id: number, log: any): Observable<any> {
    return this.httpClientService.putWithAuth(`${LogEntryService.API}/log/${id}`, log)
  }

  exportCSV(orgId: string, startDate: string, endDate: string): Observable<any> {
    console.log(LogEntryService.API + "/log/export/csv?orgId=" + orgId + "&startDate=" + startDate + "&endDate=" + endDate)
    return this.httpClientService.getFileWithAuth(`${LogEntryService.API}/log/export/csv?orgId=${orgId}&startDate=${startDate}&endDate=${endDate}`)
      .pipe(
        map(
          (res: any) => {
            return res;
          },
          catchError(err => throwError(err))
        )
      );

  }

  exportCSV2(orgId: string, startDate: string, endDate: string): Observable<any> {
    console.log(LogEntryService.API + "/log/export/csv?orgId=" + orgId + "&startDate=" + startDate + "&endDate=" + endDate)
    return this.httpClientService.getFileWithAuth2(`${LogEntryService.API}/log/export/csv?orgId=${orgId}&startDate=${startDate}&endDate=${endDate}`)
      .pipe(map((res: any) => {
        return res;
      }, catchError(err => throwError(err))));
  }
}
