import {Component, ViewChild, AfterViewInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf, pipe, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import { LogEntryService } from '../../../services/log/log_entry.service';
import { MatTable } from '@angular/material';
import { saveAs } from 'file-saver';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-logs-table',
  templateUrl: './logs-table.component.html',
  styleUrls: ['./logs-table.component.scss']
})
export class LogsTableComponent implements AfterViewInit {
  displayedColumnsForCoAdmin: string[] = ['fullName', 'boatName', 'registrationNumber', 'fished', 'departure_date', 'checkin_date', 'reason', 'other', 'createdAt'];
  displayedColumns: string[] = ['fullName', 'departure_date', 'checkin_date', 'reason', 'other', 'createdAt'];
  data:any = [];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatTable, {static: true}) table: MatTable<any>;

  resultsLength = 0;
  isLoadingResults = true;
  userInfo;
  startDate = '';
  endDate = '';
  orgId;
  name;
  speciesName;

  nameModel: Subject<string> = new Subject<string>();
  speciesNameModel: Subject<string> = new Subject<string>();



  constructor(
    private logEntryService: LogEntryService,
    private authService: AuthService
  ) {
    this.userInfo = this.authService.getUserData();
    this.orgId = '';
    this.name = '';
    this.speciesName = '';
    if(this.userInfo.organization_id) {
      this.orgId = this.userInfo.organization_id;
    }
  }

  ngAfterViewInit() {
    this.loadData();
    
  }
  ngOnInit() {
    this.nameModel.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(res => {
        this.loadData();
      })

    this.speciesNameModel.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(res => {
      this.loadData();
    })
    
  }

  onQueryInput() {
    this.nameModel.next(this.name);
    this.speciesNameModel.next(this.speciesName);
  }

  loadData() {
   merge(this.paginator.page, this.paginator.pageSize)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        return this.logEntryService.findApi(this.paginator.pageIndex, this.paginator.pageSize, 
                                            this.orgId, this.startDate, this.endDate, this.name, this.speciesName);
      }),
      map(data => {
        console.log('data', data);
        this.isLoadingResults = false;
        this.resultsLength = data["count"];

        return data.rows;
      }),
      catchError((err) => {
        console.log(err);
        this.isLoadingResults = false;
        return observableOf([]);
      })
    ).subscribe(data => this.data = data);

  }

  downloadPDF(){
    if(this.userInfo.organization_id){
      this.logEntryService.exportCSV(this.userInfo.organization_id, this.startDate, this.endDate)
      .pipe()
      .subscribe(
        (res) => {
          this.downloadFile(res.body)
        },
        (err) => {
          console.log('error', err)
        }
        )
      }
  } 

  downloadFile(data: any){
    var blob = new Blob(["\ufeff", data], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'data.csv');
}
  
}

