import { Injectable } from '@angular/core';
import { NetworkStatus } from '../models/network-status.enum';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NetworkStatusListenerService {

  private _currentNetworkStatus: NetworkStatus = NetworkStatus.ONLINE;
  private _currentNetworkStatusSubject: Subject<NetworkStatus> = new Subject();

  private static instance: NetworkStatusListenerService;

  constructor() {
    NetworkStatusListenerService.instance = this;
    this._runCheckingStatus();
  }

  public static getInstance(): NetworkStatusListenerService {
    return NetworkStatusListenerService.instance;
  }

  /**
   * - 1 -> online
   * - 0 -> offline
   * @returns
   */
  public getNetworkStatus(): NetworkStatus {
    return this._currentNetworkStatus;
  }

  public getCurrentNetworkStatusObservable() {
    return this._currentNetworkStatusSubject.asObservable();
  }

  public updateStatus(status: NetworkStatus) {
    this._currentNetworkStatus = status;
    this._currentNetworkStatusSubject.next(this._currentNetworkStatus);
  }

  public checkOnlineStatus = async (): Promise<boolean> => {
    try {
      const online = await fetch("./assets/images/app-component/one-pexel.png");
      return online.status >= 200 && online.status < 300;
    } catch (err) {
      return false; // definitely offline
    }
  };



  private async _runCheckingStatus() {

    const checkStatus = async (): Promise<boolean> => {
      try {
        const online = await fetch("./assets/images/app-component/one-pexel.png", { cache: "no-store" });
        return online.status >= 200 && online.status < 300;
      } catch (err) {
        return false;
      }
    }

    setInterval(
      async () => {

        const newStatus = (await checkStatus())
          ? NetworkStatus.ONLINE
          : NetworkStatus.OFFLINE;

        if (this._currentNetworkStatus == newStatus) return;

        this.updateStatus(newStatus);

      },
      2000
    );

  }

}
