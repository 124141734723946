import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from './httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class HealthCertificationService {

  apiUrl: string;
  constructor(private httpClient: HttpclientService) {
    this.apiUrl = environment.apiUrl;
  }


  findApi(page: number, pageSize:  number): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/health-certification?page=${page}&pageSize=${pageSize}`);
  }
  createApi(healthCertification: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/health-certification`, healthCertification);
  }
  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/health-certification/${id}`);
  }
  updateApi(id: number, healthCertification: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/health-certification/${id}`, healthCertification)
  }
  findByIdApi(id: number): Observable <any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/health-certification/${id}`)
  }

}
