import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OptionHeaderOption } from '../table-header-menu/interface.option-header-option';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {

  @Input() public options: OptionHeaderOption[] = [];
  @Output() setOptionSelected = new EventEmitter();
  public isShowingMenu: boolean = false;

  constructor() { }

  ngOnInit() { }

  public showMenu() {
    this.isShowingMenu = true;
  }

  public selectOption(option: string) {
    this.isShowingMenu = false;
    this.setOptionSelected.emit(option);
  }

}
