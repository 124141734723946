import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BoatService } from 'src/app/services/boat.service';
import { HarborMasterPermitsService } from 'src/app/services/harbor-master-permits.service';


@Component({
  selector: 'app-add-edit-harbor-master',
  templateUrl: './add-edit-harbor-master.component.html',
  styleUrls: ['./add-edit-harbor-master.component.scss']
})
export class AddEditHarborMasterComponent implements OnInit {
  public form: FormGroup;
  
  public permit_id = new FormControl('', Validators.required);
  public start_date = new FormControl('', Validators.required);
  public expiration_date = new FormControl('', Validators.required);
  public renewal_reminder = new FormControl(false);
  public renewal_reminder_date = new FormControl('', Validators.required);
  public boatName = new FormControl('');

  

  public boats: any[];
  public submitted: boolean;
  public isEditComponent: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private _hmPermitsService: HarborMasterPermitsService,
    private _boatService: BoatService,
    private matSnackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddEditHarborMasterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.form = fb.group({
      'permit_id': this.permit_id,
      'boatName': this.boatName,
      'start_date': this.start_date,
      'expiration_date': this.expiration_date,
      'renewal_reminder': this.renewal_reminder,
      'renewal_reminder_date': this.renewal_reminder_date
    })

    this.boats = [];
    this.submitted = false;
    this.isEditComponent = false;
   }
   
  ngOnInit() {

    if(this.data && this.data.isEditComponent) {
      this.isEditComponent = true;
      this.form.controls['permit_id'].setValue(this.data.harbor.permit_id);
      this.form.controls['start_date'].setValue(this.data.harbor.start_date);
      this.form.controls['expiration_date'].setValue(this.data.harbor.expiration_date);
      this.form.controls['renewal_reminder'].setValue(this.data.harbor.renewal_reminder);
      this.form.controls['renewal_reminder_date'].setValue(this.data.harbor.renewal_reminder_date);
      if(this.data.harbor.boat) {
        this.form.controls['boatName'].setValue(this.data.harbor.boat.name);
      }
    }


    let boatName = this.form.get('boatName');

    boatName.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    )
    .subscribe(res => {
      this.filterBoats();
    })
  }

  filterBoats() {
    let boatName = this.form.get('boatName').value
    if(boatName != "") {
      this._boatService.typeAheadApi(boatName).subscribe(
        res => {
          this.boats = res
        },
        err => {
          console.log(err);
        }
      )
    }
  }

  close(): void {
    this.dialogRef.close();
  }


  get f(): any { return this.form.controls; }

  submit() {
    this.submitted = true;
    let boatName = this.form.get('boatName').value;
    let boatId;

    if(boatName != "") {
      this.boats.forEach(boat => {
        if(boat.name === boatName) {
          boatId = boat.id
        }
      })
    }

    let harborMasterPermit = {
        permit_id: this.form.get('permit_id').value,
        boat_id: boatId,
        start_date: this.form.get('start_date').value,
        expiration_date: this.form.get('expiration_date').value,
        renewal_reminder: this.form.get('renewal_reminder').value,
        renewal_reminder_date: this.form.get('renewal_reminder_date').value
    }

    const permissionHaveRenewalReminder = this.form.get('renewal_reminder').value == true;
    if (permissionHaveRenewalReminder == false) {
      delete harborMasterPermit.renewal_reminder_date;
    }

    if(this.isEditComponent) {
      this._hmPermitsService.updateApi(this.data.harbor.id, harborMasterPermit).subscribe(
        response => {
          if(response.confirmation == true) {
            this.matSnackBar.open('Permiso de capitanía de puerto actualizado con éxito', 'Cerrar', {duration: 4000});
          }
          this.dialogRef.close();
        },
        err => {
          if(err.error.confirmation == false) {
            this.matSnackBar.open('Error: ' + err.error.message, 'Cerrar', {duration: 4000});
          }
        }
      )
      return;
    }


    this._hmPermitsService.createApi(harborMasterPermit).subscribe(
      response => {
        if(response.confirmation) {
          this.matSnackBar.open('Permiso de capitanía de puerto agregado exitosamente', 'Cerrar', {duration: 3000})
        }
        this.dialogRef.close();
      },
      err => {
        this.matSnackBar.open('Error: ' + err.error.message, 'Cerrar', {duration: 4000})
      }
    )
  }

}
