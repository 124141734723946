import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-divider-horizontal',
  templateUrl: './divider-horizontal.component.html',
  styleUrls: ['./divider-horizontal.component.scss']
})
export class DividerHorizontalComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
