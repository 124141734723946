import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Organization } from 'src/app/models/organization';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { HealthCertificationService } from 'src/app/services/health-certification.service';
import { CooperativaService } from 'src/app/services/organization.service';
import { AddEditHarborMasterComponent } from '../../harbor-master-permit/add-edit-harbor-master/add-edit-harbor-master.component';

@Component({
  selector: 'app-add-edit-health-certification',
  templateUrl: './add-edit-health-certification.component.html',
  styleUrls: ['./add-edit-health-certification.component.scss']
})
export class AddEditHealthCertificationComponent implements OnInit {
  public form: FormGroup;
  
  public expiration_date = new FormControl('', Validators.required);
  public renewal_reminder = new FormControl(false);
  public renewal_reminder_date = new FormControl('', Validators.required);
  public orgName = new FormControl('');

  public organizations: any[];
  public submitted: boolean;
  public isEditComponent: boolean;
  private org: Organization;
  public userSession: User;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private _healthCertService: HealthCertificationService,
    private _orgService: CooperativaService,
    private matSnackBar: MatSnackBar,
    private authService: AuthService,
    public dialogRef: MatDialogRef<AddEditHealthCertificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.form = fb.group({
      'orgName': this.orgName,
      'expiration_date': this.expiration_date,
      'renewal_reminder': this.renewal_reminder,
      'renewal_reminder_date': this.renewal_reminder_date
    })

    this.organizations = [];
    this.submitted = false;
    this.isEditComponent = false;

    this.userSession = new User();
  }

  ngOnInit() {
    if(this.data && this.data.isEditComponent) {
      this.isEditComponent = true;
      this.form.controls['expiration_date'].setValue(this.data.certification.expiration_date);
      this.form.controls['renewal_reminder'].setValue(this.data.certification.renewal_reminder);
      this.form.controls['renewal_reminder_date'].setValue(this.data.certification.renewal_reminder_date);
      if(this.data.certification.organization) {
        this.form.controls['orgName'].setValue(this.data.certification.organization.name);
      }
    }
    let us = this.authService.getUserData();
    if (!us) {
      this.matSnackBar.open('Error loading user session.', 'Cerrar', {duration: 4000});
    }
    this.userSession = us;
    let orgName = this.form.get('orgName');

    this._orgService.findByIdApi(this.userSession.organization_id)
        .subscribe(res => {
          this.org = res;
        }, err => {
          console.log(err);
          if (err.error.confirmation == false) {
            this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000});
          }
        },
        () => {
          this.form.controls['orgName'].setValue(this.org.name)
        }
    );

    orgName.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    )
    .subscribe(res => {
      this.filterOrganizations();
    })
    
  }

  filterOrganizations() {
    let orgName = this.form.get('orgName').value
    if(orgName != "") {
      this._orgService.typeaheadApi(orgName).subscribe(
        res => {
          this.organizations = res
        },
        err => {
          console.log(err);
        }
      )
    }
  }
  close(): void {
    this.dialogRef.close();
  }


  get f(): any { return this.form.controls; }

  submit() {
    this.submitted = true;
    let orgName = this.form.get('orgName').value;
    let orgId;

    if(orgName != "") {
      this.organizations.forEach(org => {
        if(org.name === orgName) {
          orgId = org.id
        }
      })
    }

    let healthCertification = {
        organization_id: orgId,
        expiration_date: this.form.get('expiration_date').value,
        renewal_reminder: this.form.get('renewal_reminder').value,
        renewal_reminder_date: this.form.get('renewal_reminder_date').value
    }

    const healthCertificationHaveRenewalReminder = this.form.get('renewal_reminder').value == true;
    if (healthCertificationHaveRenewalReminder == false) {
      delete healthCertification.renewal_reminder_date;
    }

    if(this.isEditComponent) {
      this._healthCertService.updateApi(this.data.certification.id, healthCertification).subscribe(
        response => {
          if(response.confirmation == true) {
            this.matSnackBar.open('Certificación sanitaria actualizado con éxito', 'Cerrar', {duration: 4000});
          } 
          this.dialogRef.close();
        },
        err => {
          if(err.error.confirmation == false) {
            this.matSnackBar.open('Error: ' + err.error.message, 'Cerrar', {duration: 4000});
          }
        }
      )
      return;
    }


    this._healthCertService.createApi(healthCertification).subscribe(
      response => {
        if(response.confirmation) {
          this.matSnackBar.open('Certificación sanitaria agregado con éxito', 'Cerrar', {duration: 3000})
        }
        this.dialogRef.close();
      },
      err => {
        this.matSnackBar.open('Error: ' + err.error.message, 'Cerrar', {duration: 4000})
      }
    )
  }

}
