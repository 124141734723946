import { ErrorController } from "./error-controller";

const PASS_ERRORS_OBJECT = {

  // error from form
  REQUIRED: "Contraseña requerido",
  INVALID: "Contraseña invalida",

  // response service
  DEFAULT: "Error",
}

export type PASS_ERRORS_TYPE = keyof typeof PASS_ERRORS_OBJECT;

export const errorControllerPass: ErrorController<PASS_ERRORS_TYPE> = new ErrorController(PASS_ERRORS_OBJECT);
