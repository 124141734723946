import { ErrorController } from "./error-controller";

const EMAIL_ERRORS_OBJECT = {

  // error from form
  EMPTY: "Correo electrónico",
  REQUIRED: "Correo electrónico requerido",
  INVALID: "Por favor ingresa un correo electrónico válido",

  // response service
  NOT_REGISTERED: "Correo electrónico no registrado.",
  DEFAULT: "Error",

}

export type EMAIL_ERRORS_TYPE = keyof typeof EMAIL_ERRORS_OBJECT;

export const errorControllerEmail: ErrorController<EMAIL_ERRORS_TYPE> = new ErrorController(EMAIL_ERRORS_OBJECT);
