import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatTable } from '@angular/material';
import { Router } from '@angular/router';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
// import { SpeciesService } from 'src/app/services/species.service';
import { merge, Observable, of as observableOf, Subject } from 'rxjs';
import { AddEditSolutionComponent } from '../solutions/add-edit-solution/add-edit-solution.component';
import { AddEditSpeciesComponent } from './add-edit-species/add-edit-species.component';
import { SpeciesService } from 'src/app/services/species/species.service';

@Component({
  selector: 'app-species',
  templateUrl: './species.component.html',
  styleUrls: ['./species.component.scss']
})
export class SpeciesComponent implements AfterViewInit {
  displayedColumns: string[] = ['actions', 'common_name', 'scientific_name', 'image_url', 'group_name', 'region']
  data: any;
  deleteSpecieDialogRef;
  deleteSpecieIdHolder;
  currentSpecie;
  specieName: string;
  viewSpecieDialogRef;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  resultsLength: number;
  isLoadingResults: boolean;

  specieNameModel: Subject<string> = new Subject<string>();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private _spcService: SpeciesService
  ) {
    this.data = [];
    this.isLoadingResults = true;

    this.specieNameModel.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(res => {
        this.loadData();
      });

  }

  ngAfterViewInit() {
    this.loadData()
  }

  loadData() {
    merge(this.paginator.page, this.paginator.pageSize)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this._spcService.findApi(this.paginator.pageIndex, this.paginator.pageSize, this.specieName);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data["count"];

          return data['rows'];
        }),
        catchError((err) => {
          console.log(err);
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  edit(id) {
    let currentSpecie;
    this.data.forEach(specie => {
      if (specie['id'] == id) {
        currentSpecie = specie;
      }
    })

    let editDialogRef = this.dialog.open(AddEditSpeciesComponent, {
      width: '500px',
      height: 'auto',
      data: { isEditComponent: true, specie: currentSpecie }
    })

    editDialogRef.afterClosed().subscribe(result => {
      this.loadData();
    })
  }

  viewSpecie(viewSpecieDialog, specie) {
    this.currentSpecie = specie;
    this.deleteSpecieIdHolder = this.currentSpecie.id;

    this.viewSpecieDialogRef = this.dialog.open(viewSpecieDialog, {
      width: '600px',
      height: '80vh',
    })

    this.viewSpecieDialogRef.afterClosed().subscribe(d => {
      this.currentSpecie = null;
    })
  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(AddEditSpeciesComponent, {
      width: '500px',
      height: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  onQueryInput() {
    this.specieNameModel.next(this.specieName);
  }

  cancelSpecieDialog() {
    this.viewSpecieDialogRef.close();
  }

}
