import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from './httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  apiUrl: string;
  constructor(private httpClient: HttpclientService) {
    this.apiUrl = environment.apiUrl;
  }

  findApi(page: number, pageSize: number, postId: string = '', flagged: string = ''): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/comment?page=${page}&pageSize=${pageSize}&postId=${postId}&flagged=${flagged}`);
  }
  createApi(comment: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/comment`, comment);
  }
  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/comment/${id}`);
  }
  updateApi(id: number, comment: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/comment/${id}`, comment);
  }
  findByIdApi(id: number): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/comment/${id}`);
  }
}
