import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatTable } from '@angular/material';
import { Router } from '@angular/router';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { SolutionService } from 'src/app/services/solution.service';
import { AddEditSolutionComponent } from './add-edit-solution/add-edit-solution.component';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements AfterViewInit {
  displayedColumns: string[] = ['actions', 'title', 'description', 'problem_name', 'challenges', 'allow_contact', 'participants', 'other',
    'men_participant_count', 'women_participant_count', 'men_benefiting', 'women_benefiting']

  data: any;
  deleteSolutionDialogRef;
  deleteSolutionIdHolder;
  editSolutionIdHolder;
  viewSolutionDialogRef;
  currentSolution;
  images: Array<object> = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  resultsLength: number;
  userSession: User;
  searchQuery: string;
  isLoadingResults: boolean;
  
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private authSvc: AuthService,
    private solutionSvc: SolutionService
  ) { 
    this.data = [];
    let us = this.authSvc.getUserData();
    if (!us) {
      this.matSnackBar.open('Error loading user session.', 'Cerrar', { duration: 4000 });
      return;
    }
    this.searchQuery = '';
    this.userSession = us;
    this.isLoadingResults = true;

   
  }

  ngAfterViewInit() {
    this.loadData();
  }

  closeViewSolution() {
    this.viewSolutionDialogRef.close();
  }

  loadData() {
    merge(this.paginator.page, this.paginator.pageSize)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.solutionSvc.findApi(this.paginator.pageIndex, this.paginator.pageSize, this.searchQuery);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data["count"];

          return data['rows'];
        }),
        catchError((err) => {
          console.log(err);
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  cancelDialog() {
    this.deleteSolutionDialogRef.close();
  }

  cancelSolutionDialog() {
    this.viewSolutionDialogRef.close();
  }

  deleteSolution(deleteDialog, id) {
    this.deleteSolutionIdHolder = id;
    this.deleteSolutionDialogRef = this.dialog.open(deleteDialog, {
      width: '500px'
    });
  }

  confirmSolutionDelete() {
    this.solutionSvc.deleteApi(this.deleteSolutionIdHolder)
      .subscribe(
        result => {
          if (result.confirmation == true) {
            this.data.forEach((user, index) => {
              if (user.id == this.deleteSolutionIdHolder) {
                this.data.splice(index, 1)
                this.table.renderRows();
              }
            })
            
            this.matSnackBar.open('Solution deleted succesfully', 'Cerrar', { duration: 4000 });
            return;
          }
          if (result.confirmation == false) {
            this.matSnackBar.open('Error deleting solution.', 'Cerrar', { duration: 4000 });
          }
        },
        err => {
          console.log(err);
        }
      )
    this.deleteSolutionDialogRef.close();
  }

  editSolution(solution) {
    this.router.navigate(['/dashboard/solutions/add'], {
      state: {
        solution: solution
      }
    })
  }

  openAddDialog():void {
    this.router.navigate(['/dashboard/solutions/add'])
  }
  

  viewSolution(viewSolutionDialog, solution) {
    this.currentSolution = solution;
    this.editSolutionIdHolder = this.currentSolution.id;

    this.currentSolution.images.forEach(img => {
      this.images.push({
        image: img.path,
        thumbImage: img.path
      })
    })

    this.viewSolutionDialogRef = this.dialog.open(viewSolutionDialog, {
      width: '600px',
      height: '80vh',
      hasBackdrop: true
    })

    this.viewSolutionDialogRef.afterClosed().subscribe(d => {
      this.currentSolution = null;
      this.images = [];
    })
  }

}

