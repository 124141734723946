import { Component, OnInit } from '@angular/core';
import { Alert } from '../../models/alert.interface';
import { Subscription } from 'rxjs';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-alert-in-component',
  templateUrl: './alert-in-component.component.html',
  styleUrls: ['./alert-in-component.component.scss']
})
export class AlertInComponentComponent implements OnInit {


  public static SECONDS = 4;
  public alert?: Alert;
  public showAlert: boolean = false;
  private alert$: Subscription;

  constructor(
    alertService: AlertService,
  ) {
    this.alert$ = alertService
      .getAlertObservable()
      .subscribe((alert: Alert) => this.launch(alert))
  }

  ngOnInit() { }


  public ngOnDestroy(): void {
    this.alert$.unsubscribe();
  }

  private launch(alert: Alert): void {

    const canItShowAlert = alert.showIn === 'InAComponent' ? true : false;
    if (canItShowAlert == false) return;

    const secondsToShowAlert = (
      alert.seconds
      ||
      AlertInComponentComponent.SECONDS
    ) * 1000;

    this.alert = alert;
    this.showAlert = true;

    setTimeout(
      () => {
        this.closeAlert();
      },
      secondsToShowAlert
    );
  }

  public closeAlert() {
    this.showAlert = false;
  }

}
