import { Component, Input, OnInit } from '@angular/core';
import { Alert } from '../../models/alert.interface';

@Component({
  selector: 'app-alert-success',
  templateUrl: './alert-success.component.html',
  styleUrls: ['./alert-success.component.scss']
})
export class AlertSuccessComponent implements OnInit {

  @Input() public alert!: Alert;
  public showAlert: boolean = false;

  constructor() { }

  ngOnInit() { }

}
