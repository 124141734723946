import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar } from '@angular/material';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { merge, observable, of as observableOf, Subject } from 'rxjs';
import { SurveyService } from 'src/app/services/survey.service';

@Component({
  selector: 'app-survey-answers',
  templateUrl: './survey-answers.component.html',
  styleUrls: ['./survey-answers.component.scss']
})
export class SurveyAnswersComponent implements AfterViewInit {
  public organizations: any[];
  public organizationName: string;
  public headersCategories: string[] = [];

  viewOrganizationDialogRef;
  currentOrganization;
  dataLength: number;

  countP = 0;
  countG = 0;
  countH = 0;
  countS = 0;

  nameModel: Subject<string> = new Subject<string>();

  constructor(
    private surveyService: SurveyService,
    private matSnackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {
    this.organizations = [];
    this.organizationName = '';

    this.nameModel.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(res => {
        this.loadData();
      });
   }

  ngAfterViewInit() {
    this.loadData();
  }

  onQueryInput() {
    this.nameModel.next(this.organizationName);
  } 

  loadData() {
    merge(0, 0)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.surveyService.findOrganizationSurveys(0, 0, this.organizationName)
        }),
        map(data => {
          this.dataLength = data.organizations['count'];
          return data.organizations['rows'];
        }),
        catchError((err) => {
          return observableOf([]);
        })
      ).subscribe((data) => {
        this.organizations = data;
        this.organizations.map(organization => {
          organization.prodAnswers = organization.answers.filter(answer => answer.question.section == 'production');
          organization.genderAnswers = organization.answers.filter(answer => answer.question.section == 'gender');
          organization.hawAnswers = organization.answers.filter(answer => answer.question.section == 'health-and-wellbeing');
          organization.sustainAnswers = organization.answers.filter(answer => answer.question.section == 'sustainability');
        });
      })

  }

  viewOrganizationSurveys(viewOrganization, organization) {
    this.currentOrganization = organization;

    this.viewOrganizationDialogRef = this.dialog.open(viewOrganization, {
      width: '800px',
      height: 'auto'
    })

    this.viewOrganizationDialogRef.afterClosed().subscribe(d => {
      this.currentOrganization = null;
    })
  }

  cancelOrganizationView() {
    this.viewOrganizationDialogRef.close();
  }
  
}
