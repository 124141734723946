import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl, ValidationErrors } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PassInput } from 'src/app/login/models/pass.input';
import { PASS_ERRORS, PASS_ERRORS_TYPE } from 'src/app/login/models/pass.errors';
import { AlertService } from 'src/app/shared/alerts/services/alert.service';


type StatesUI = "FORM" | "IS_LOADING" | "MESSAGE_FINAL";
type ClassErrorsCss = "idle" | "error" | "success";


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  public formGroup: FormGroup;
  private _checkErrorPassInput$: Subscription;
  public currentState: StatesUI = "FORM";
  public passInput: PassInput;
  public passConfirmInput: PassInput;

  public isHiddenPass: boolean = true;

  public charSpecialClassErrorsCss: ClassErrorsCss = "idle";
  public capitalLetterClassErrorsCss: ClassErrorsCss = "idle"
  public lowerLetterClassErrorsCss: ClassErrorsCss = "idle"
  public numberClassErrorsCss: ClassErrorsCss = "idle"

  // * ✅✅✅

  private unsubscribeAll: Subject<any> = new Subject<any>();
  public form: FormGroup;
  public submitted: boolean = false;
  public password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  public confirmPassword = new FormControl('', [Validators.required, Validators.minLength(6)]);
  //token: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _alertService: AlertService,
    private _activatedRouter: ActivatedRoute,
    private _authService: AuthService,
    // * ✅✅✅
    private router: Router,
    private fb: FormBuilder,
    private matSnackBar: MatSnackBar,
  ) {

    this.passInput = new PassInput();
    this.passInput.FIELD_NAME = "PASS_INPUT";

    this.passConfirmInput = new PassInput();
    this.passConfirmInput.FIELD_NAME = "PASS_CONFIRM_INPUT";

    this.formGroup = this._buildFormGroup(_formBuilder);

    this._checkErrorPassInput$ = this._getCheckErrorPassInput();
    // * ✅✅✅

    this._activatedRouter.paramMap.subscribe(params => {
      //  this.token = params.get('token');
      this.form = fb.group({
        'password': this.password,
        'confirmPassword': this.confirmPassword
      })
    })
  }

  ngOnDestroy(): void {
    this._checkErrorPassInput$.unsubscribe();
  }

  private changeCurrentState(state: StatesUI) {
    this.currentState = state;
  }

  public renderContainer(state: StatesUI) {
    return this.currentState === state;
  }

  private _buildFormGroup(formBuilder: FormBuilder): FormGroup {

    const areTheSamePass = (formGroup: AbstractControl): ValidationErrors | null => {

      const pass = this.passInput.value;
      const confirmPass = this.passConfirmInput.value;

      if (pass !== confirmPass) {
        this.passInput.formControl.setErrors(PASS_ERRORS.PASS_ARE_NOT_SAME);
        this.passConfirmInput.formControl.setErrors(PASS_ERRORS.PASS_ARE_NOT_SAME);
        return PASS_ERRORS.PASS_ARE_NOT_SAME;
      }

      // this.formGroup.updateValueAndValidity();
      this.passInput.formControl.setErrors(null);
      this.passConfirmInput.formControl.setErrors(null);

      return null;

    }

    const formGroup = formBuilder.group({});
    formGroup.addControl(this.passInput.FIELD_NAME, this.passInput.formControl,);
    formGroup.addControl(this.passConfirmInput.FIELD_NAME, this.passConfirmInput.formControl,);
    formGroup.setValidators([areTheSamePass]);
    return formGroup;

  }

  private _getCheckErrorPassInput(): Subscription {

    return this.passInput
      .formControl
      .valueChanges
      .subscribe(
        (value) => {

          const errorsObject = this.passInput.formControl.errors || {};
          const errorsKeys = Object.keys(errorsObject);

          const getErrorClassByType = (errorsKey: PASS_ERRORS_TYPE): ClassErrorsCss => {
            const existErrorKey = errorsKeys.includes(errorsKey);
            return existErrorKey ? "error" : "success";
          }

          this.charSpecialClassErrorsCss = getErrorClassByType("INVALID_CHAR_SPECIAL");
          this.capitalLetterClassErrorsCss = getErrorClassByType("INVALID_CAPITAL_LETTER");
          this.lowerLetterClassErrorsCss = getErrorClassByType("INVALID_LOWER_LETTER");
          this.numberClassErrorsCss = getErrorClassByType("INVALID_A_NUMBER");

        }
      );
  }

  public async resetPass() {

    this.formGroup.markAllAsTouched();
    const isValidForm = this.formGroup.valid;

    if (isValidForm == false) {
      const messageError = "Las contraseñas no coinciden, por favor inténtalo nuevamente.";
      this._alertService.launch({ message: messageError, typeAlert: "error", showIn: "InAllApp" });
      return;
    }

    this.changeCurrentState("IS_LOADING");

    const token = this._activatedRouter.snapshot.queryParamMap.get('token');
    const password = this.passInput.value;

    const changePassResponse = await this._authService.changePass({
      token: token,
      pass: {
        password: password,
      },
    });

    if (changePassResponse.wasSuccess == false) {
      this.changeCurrentState("FORM");
      this._alertService.launch({ typeAlert: "error", message: changePassResponse.message, showIn: "InAllApp" })
      return;
    }

    this.changeCurrentState("MESSAGE_FINAL");

  }


  public goToLogin() {
    this.router.navigate(['/login']);
  }

  // * ✅✅✅

  get f(): any { return this.form.controls; }


  ngOnInit() {
  }

  submitForm(event) {

    this.submitted = true;

    if (this.form.valid) {

      event.preventDefault();
      const token = this._activatedRouter.snapshot.queryParamMap.get('token');

      if (this.form.get('password').value == this.form.get('confirmPassword').value) {
        let req = {
          password: this.form.get('password').value
        };
        this._authService.resetPasswordApi(req, token)
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe(res => {
            if (res.success == true) {
              this.matSnackBar.open("Contraseña cambiada con éxito", 'Cerrar', { duration: 4000 });
              this.router.navigate(['/login']);
            } else {
              this.matSnackBar.open("Error al cambiar la contraseña", 'Cerrar', { duration: 4000 });
              this.form.reset();
            }
          }, err => {
            console.log(err);
            this.matSnackBar.open("Error al cambiar la contraseña", 'Cerrar', { duration: 4000 });
            this.form.reset();
          })
      } else {
        this.matSnackBar.open("Las contraseñas no coinciden", 'Cerrar', { duration: 4000 });
      }
    }
  }

}
