import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  private unsubscribeAll: Subject<any> = new Subject<any>();
  success: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRouter: ActivatedRoute
  ) { }

  ngOnInit() {
    const token = this.activatedRouter.snapshot.queryParamMap.get('token');

    this.authService.verifyEmailApi(token)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(res => {
        if(res.confirmation == true) {
          this.success = true;
        } else {
          this.success = false;
        }
      })
  }

}
