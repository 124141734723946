import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from './httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class DataVisualizationService {
  apiUrl: string;
  constructor(private httpClient: HttpclientService) { 
    this.apiUrl = environment.apiUrl;
  }
  
  getTotalSpeciesApi(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/data-visualization/species?startDate=${startDate}&endDate=${endDate}`)
  }
  getTotalUser(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/data-visualization/users?startDate=${startDate}&endDate=${endDate}`)
  }
  getTotalFisher(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/data-visualization/fisher?startDate=${startDate}&endDate=${endDate}`)
  }
  getTotalBoats(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/data-visualization/boats?startDate=${startDate}&endDate=${endDate}`)
  }
}
