import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from '../httpclient.service';
import { Community } from 'src/app/models/models_fixed/babel.models'

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  private static API: string = environment.apiUrl;

  constructor(private httpClient: HttpclientService) { }


  //============================================================
  // READ
  //============================================================

  public async getAllCommunities(): Promise<Community[]> {

    try {

      const urlFormed = `${CommunityService.API}/community?page=0&pageSize=9999999999`;
      const response = await this.httpClient.getWithAuth(urlFormed).toPromise();
      const responseServer = response as { "count": number; "rows": Community[]; };
      return responseServer.rows;

    } catch (error) {

      return [];

    }

  }

  public async typeAhead(query: string): Promise<Community[]> {
    const urlFormed = `${CommunityService.API}/community/typeahead/${query}`;
    const response = await this.httpClient.getWithAuth(urlFormed).toPromise();
    const communities = response as Community[];
    return communities;
  }

  public async findById(id: number): Promise<Community> {
    const urlFormed = `${CommunityService.API}/community/${id}`;
    const response = await this.httpClient.getWithAuth(urlFormed).toPromise();
    const community = response as Community;
    return community;
  }

  // * ✅✅✅

  typeAheadApi(query: string = ""): Observable<any> {
    return this.httpClient.getWithAuth(`${CommunityService.API}/community/typeahead/${query}`)
  }


  findApi(page: number, pageSize: number, stateId: number): Observable<any> {
    return this.httpClient.getWithAuth(`${CommunityService.API}/community?stateId=${stateId}&page=${page}&pageSize=${pageSize}`);
  }
  createApi(comm: any): Observable<any> {
    return this.httpClient.postWithAuth(`${CommunityService.API}/community`, comm);
  }
  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${CommunityService.API}/community/${id}`);
  }
  updateApi(id: number, comm: any): Observable<any> {
    return this.httpClient.putWithAuth(`${CommunityService.API}/community/${id}`, comm);
  }
  findByIdApi(id: number): Observable<any> {
    return this.httpClient.getWithAuth(`${CommunityService.API}/community/${id}`);
  }

}
