import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from './httpclient.service';


@Injectable({
  providedIn: 'root'
})
export class PermitTypeService {
  apiUrl: string;
  constructor(private httpClient: HttpclientService) {
    this.apiUrl = environment.apiUrl;
  }


  findApi(page: number, pageSize:  number): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/permit_type?page=${page}&pageSize=${pageSize}`);
  }
  createApi(permitType: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/permit_type`, permitType);
  }
  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/permit_type/${id}`);
  }
  updateApi(id: number, permitType: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/permit_type/${id}`, permitType)
  }
  findByIdApi(id: number): Observable <any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/permit_type/${id}`)
  }
}
