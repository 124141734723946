import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Alert } from '../models/alert.interface';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private _alertObservable = new Subject<Alert>();

  constructor() { }

  public getAlertObservable() {
    return this._alertObservable.asObservable();
  }

  public launch(alert: Alert) {
    this._alertObservable.next(alert);
  }

}
