import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from '../../services/auth.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { User } from '../../models/models_fixed/babel.models';
import { ConnectionService } from 'src/app/utils/services/connection.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})

export class MainLayoutComponent implements OnInit {

  userSession: User;

  isOnline: boolean = true;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    public matDialog: MatDialog,
    private connectionService: ConnectionService
  ) {

    this.userSession = this.authService.getCurrentUserLogged;

  }

  ngOnInit() {
    this.connectionService.getOnlineStatus().subscribe(isOnline => {
      this.isOnline = isOnline;
    });
  }

}
