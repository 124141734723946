import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from '../httpclient.service';
import { ListByCommonScientificNameRequest } from './interface.listByCommonScientificName.request';
import { ListByCommonScientificNameResponse } from './interface.listByCommonScientificName.response';
import { Specie } from '../../models/models_fixed/babel.models';
import { DeleteSpecieResponseLocal, DeleteSpecieResponseServer } from './deleteSpecie.response';
import { CreateSpecieRequest } from './create-specie.request';
import { CreateSpecieResponseLocal, CreateSpecieResponseServer } from './create-specie.response';
import { UpdateSpecieRequest } from './interface.update-specie.request';
import { UpdateSpecieResponseLocal, UpdateSpecieResponseServer } from './interface.update-specie.response';


@Injectable({
  providedIn: 'root'
})
export class SpeciesService {


  private static API = environment.apiUrl;
  apiUrl: string;


  constructor(private httpClient: HttpclientService) {
    this.apiUrl = environment.apiUrl;
  }



  //============================================================
  // CREATE
  //============================================================

  public async createSpecie(body: CreateSpecieRequest): Promise<CreateSpecieResponseLocal> {

    const messageSuccess = "Especie agregada con éxito";
    const messageError = "Algo salió mal, no se pudo agregar la especie, inténtalo nuevamente.";

    try {

      // {{local}}/specie
      const response = await this.httpClient
        .postWithAuth(`${this.apiUrl}/specie`, body)
        .toPromise();

      const specieResponseServer = response as CreateSpecieResponseServer;

      return {
        success: specieResponseServer.confirmation,
        message: specieResponseServer.confirmation
          ? messageSuccess
          : messageError,
        specie: specieResponseServer.specie
      }

    } catch (error) {

      return {
        success: false,
        message: messageError
      }

    }

  }

  public async createImage(files: File, id: number) {
    try {

      const formData: FormData = new FormData();
      formData.append('image', files, files.name);

      const response = await this.httpClient.sentFileWithAuth(
        `${this.apiUrl}/specie/${id}/image`,
        formData
      ).toPromise();

      return true

    } catch (error) {

      return false
    }
  }



  //============================================================
  // READ
  //============================================================

  public async listByCommonScientificName(request: ListByCommonScientificNameRequest): Promise<ListByCommonScientificNameResponse> {

    try {

      // format body from request url
      const queryKeys = Object.keys(request);
      const partsQuery = queryKeys.map((key: string) => `${key}=${request[key]}`);
      let urlBody = partsQuery.join('&');

      const response = await this.httpClient
        .getWithAuth(`${this.apiUrl}/specie?${urlBody}`)
        .toPromise();

      return response as ListByCommonScientificNameResponse;

    } catch (error) {

      return {
        count: 0,
        rows: []
      }
    }

  }


  public async getSpecieByID(id: number): Promise<Specie | null> {
    try {

      const response = await this.httpClient
        .getWithAuth(`${this.apiUrl}/specie/${id}`)
        .toPromise();

      return response as Specie;

    } catch (error) {
      return null;
    }
  }

  public async findByKeyword(keyword: string): Promise<Specie[]> {

    try {

      // {{local}}/specie/autocomplete/m
      const urlFormed = `${this.apiUrl}/specie/autocomplete/${keyword}`;
      const response = await this.httpClient.getWithAuth(urlFormed).toPromise();
      return response as Specie[]

    } catch (error) {

      return [];

    }
  }


  //============================================================
  // update
  //============================================================

  public async updateSpecie(id: number, specie: UpdateSpecieRequest): Promise<UpdateSpecieResponseLocal> {

    const messageSuccess = "Datos actualizados con éxito.";
    const messageError = "Algo salió mal, no se pudo guardar la información, inténtalo nuevamente.";

    try {

      const response = await this.httpClient
        .putWithAuth(`${SpeciesService.API}/specie/${id}`, specie)
        .toPromise();

      const updateSpecieResponseServer = response as UpdateSpecieResponseServer;

      return {
        success: updateSpecieResponseServer.confirmation,
        message: updateSpecieResponseServer.confirmation
          ? messageSuccess
          : messageError,
        specie: updateSpecieResponseServer.specieentry,
      }

    } catch (error) {
      return {
        success: false,
        message: messageError,
      }

    }

  }


  //============================================================
  // DELETE
  //============================================================

  public async deleteSpecieById(id: number): Promise<DeleteSpecieResponseLocal> {

    try {

      // {{local}}/specie/581

      const response = await this.httpClient
        .deleteWithAuth(`${this.apiUrl}/specie/${id}`)
        .toPromise();

      const responseServer = response as DeleteSpecieResponseServer;

      const message = responseServer.confirmation
        ? "Especie eliminada."
        : "Algo salió mal, no se pudo eliminar la especie, inténtalo nuevamente."

      return {
        success: responseServer.confirmation,
        message: message
      }

    } catch (error) {

      return {
        success: false,
        message: "Algo salió mal, no se pudo eliminar la especie, inténtalo nuevamente."
      }
    }

  }


  // * ✅✅✅

  findApi(page: number, pageSize: number, search: string = ""): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/specie?page=${page}&pageSize=${pageSize}&search=${search}`);
  }
  getAll(): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/specie?sync=true`);
  }
  createApi(specie: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/specie`, specie);
  }
  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/specie/${id}`);
  }
  updateApi(id: number, specie: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/specie/${id}`, specie)
  }
  findByIdApi(id: number): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/specie/${id}`)
  }

  uploadApi(files, id: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('image', files, files.name);

    return this.httpClient.sentFileWithAuth(`${this.apiUrl}/specie/${id}/image`, formData)
  }

  typeaheadApi(query: string, group: string = ""): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/specie/autocomplete/${query}?group=${group}`);
  }

  archiveApi(id: number): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/specie/${id}/archive`, {})
  }
}
