import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from './httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class PermitsSpeciesService {
  apiUrl: string;
  constructor(private httpClient: HttpclientService) {
    this.apiUrl = environment.apiUrl;
  }

  findApi(page: number, pageSize:  number): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/permit_species?page=${page}&pageSize=${pageSize}`);
  }
  createApi(permit_species: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/permit_species`, permit_species);
  }
  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/permit_species/${id}`);
  }
  updateApi(id: number, permit_species: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/permit_species/${id}`, permit_species)
  }
  findByIdApi(id: number): Observable <any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/permit_species/${id}`)
  }
}
