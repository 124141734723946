import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar } from '@angular/material';
import { merge, observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { CommentService } from 'src/app/services/comment.service';
import { PostService } from 'src/app/services/posts.service';

@Component({
  selector: 'app-flagged-post',
  templateUrl: './flagged-post.component.html',
  styleUrls: ['./flagged-post.component.scss']
})
export class FlaggedPostComponent implements AfterViewInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  data: any
  loadingPosts: boolean;
  searchQuery: string;
  postsLength: number;
  commentLength: number;
  comments: any;
  isLoadingComments: boolean;
  currentPost;
  page: number;
  pageSize: number;
  images: Array<Object>;
  imagesView: Array<Object>;
  viewPostDialogRef;
  deletePostDialogRef;
  editPostIdHolder;
  deletePostIdHolder;
  rez;
  

  constructor(
      private postSvc: PostService,
      private commentSvc: CommentService,
      public dialog: MatDialog,
      private matSnackBar: MatSnackBar
      ) { 
    this.data = [];
    this.comments = [];
    this.images = [];
    this.imagesView = [];
    this.loadingPosts = true;
    this.isLoadingComments = true;
    this.searchQuery = '';
    this.postsLength = 0;
    this.commentLength = 0;
    this.page = 0;
    this.pageSize = 5;
    this.rez = [];
  }

  ngAfterViewInit() {
    this.loadData();
  }


  changePage(event) {
    this.comments = [];
    this.isLoadingComments = true;
    this.loadComments(this.editPostIdHolder, event.pageIndex, this.pageSize);
  }

  loadComments(id, page = 0, pageSize = 5) {
    this.commentSvc.findApi(page, pageSize, id).subscribe(
      (res) => {
        this.comments = res.comments.rows;
        this.commentLength = res.comments.count;
        this.isLoadingComments = false;
      },
      (err) => {
        console.log(err);
      }
    )
  }

  viewPost(viewPostDialog, post) {
    this.currentPost = post;
    this.editPostIdHolder = this.currentPost.id
    this.loadComments(this.currentPost.id);
    this.currentPost.images.forEach(img => {
      this.imagesView.push({
        image: img.path,
        thumbImage: img.path
      })
    })

    this.viewPostDialogRef = this.dialog.open(viewPostDialog, {
      width: '600px',
      height: '80vh',
    })

    this.viewPostDialogRef.afterClosed().subscribe(d => {
      this.isLoadingComments = true;
      this.currentPost = null;
      this.imagesView = [];
      this.images = [];
      this.comments = [];
    })
  }

  cancelPostDialog() {
    this.viewPostDialogRef.close();
  }

  deleteComment(id) {
    this.commentSvc.deleteApi(id).subscribe(
      (res) => {
        if(res.confirmation) {
          this.loadComments(this.editPostIdHolder);
        }
      },
      (err) => {
        console.log(err);
      }
    )
  }

  reviewComment(id) {
    this.commentSvc.updateApi(id, {
      reviewed: true
    }).subscribe(
      res => {
        this.loadComments(this.editPostIdHolder);
      },
      (err) => {
        console.log(err);
      }
    )
  }

  deletePost(deleteDialog, id) {
    this.deletePostIdHolder = id;
    this.deletePostDialogRef = this.dialog.open(deleteDialog, {
      width: '500px'
    });
  }

  cancelDialog() {
    this.deletePostDialogRef.close();
  }
  confirmPostDelete() {
    this.postSvc.deleteApi(this.deletePostIdHolder)
      .subscribe(
        result => {
          if (result.confirmation == true) {
            this.data.forEach((user, index) => {
              if(user.id == this.deletePostIdHolder) {
                this.data.splice(index, 1)
              }
              this.loadData();
            })
            this.matSnackBar.open('Post deleted succesfully', 'Cerrar', {duration: 4000});
            return;
          }
          if (result.confirmation == false) {
            this.matSnackBar.open('Error deleting post.', 'Cerrar', {duration: 4000});
          }
        },
        err => {
          console.log(err);
        }
      )
    this.deletePostDialogRef.close();
  }

  loadData() {
    merge(this.paginator.page, this.paginator.pageSize)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingPosts = true;
          return this.postSvc.findApi(this.paginator.pageIndex, this.paginator.pageSize, this.searchQuery, 'true')
        }),
        map(data => {
          this.loadingPosts = false,
          this.images = [];
          this.postsLength = data["count"];

          return data['rows'];
        }),
        catchError((err) => {
          console.log(err);
          this.loadingPosts = false;
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.data = data;
        this.data.forEach(dt => {
          dt.images.forEach(img => {
            this.images.push({
              post_id: img.post_id,
              image: img.path,
              thumbImage: img.path
            })
          })
        })

        let group = this.images.reduce((r, a) => {
          r[a['post_id']] = [...r[a['post_id']] || [], a];
          return r;
         }, {});

        this.rez = group
      })
  }
  
}
