import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import { Region } from "src/app/models/models_fixed/babel.models";

import { HttpclientService } from './httpclient.service';
import { environment } from '../../environments/environment'
import { map, catchError } from 'rxjs/operators';
import { RegionList } from '../models/region_list';

@Injectable({
  providedIn: 'root'
})
export class RegionService {


  private API: string = environment.apiUrl;
  apiUrl: string;

  constructor(private _httpClient: HttpclientService) {
    this.apiUrl = environment.apiUrl;
  }

  // ============================================================
  // CREATE
  // ============================================================


  // ============================================================
  // READ
  // ============================================================
  public async findByID(id: number): Promise<Region> {
    try {
      // {{local}}/regions/1
      const urlFormed = `${this.API}/regions/${id}`;
      const response = await this._httpClient.getWithAuth(urlFormed).toPromise();
      return response as Region;
    } catch (error) {
      return null;
    }

  }


  public async getAllRegions(label: string): Promise<Region[]> {

    try {

      // {{local}}/regions
      const urlFormed = `${this.API}/regions`;
      const response = await this._httpClient.getWithAuth(urlFormed).toPromise();
      const responseServer = response as { "count": number, "rows": Region[], }
      return responseServer.rows;

    } catch (error) {

      return [];

    }
  }


  // ============================================================
  // UPDATE
  // ============================================================


  // ============================================================
  // DELETE
  // ============================================================

  findApi(page: number, pageSize: number): Observable<any> {
    return this._httpClient.getWithAuth(`${this.apiUrl}/regions?page=${page}&pageSize=${pageSize}`);
  }
  createApi(region: any): Observable<any> {
    return this._httpClient.postWithAuth(`${this.apiUrl}/regions`, region)
  }
  deleteApi(id: number): Observable<any> {
    return this._httpClient.deleteWithAuth(`${this.apiUrl}/regions/${id}`)
  }
  updateApi(id: number, region: any): Observable<any> {
    return this._httpClient.putWithAuth(`${this.apiUrl}/regions/${id}`, region);
  }
  findByIdApi(id: number): Observable<any> {
    return this._httpClient.getWithAuth(`${this.apiUrl}/regions/${id}`);
  }
}
