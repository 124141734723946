import { ErrorSchema, ObjectError } from "../utils/types";



export class ErrorController<T> {

  private errorsObject: Object;

  constructor(_errorsObject: Object) {
    this.errorsObject = _errorsObject;
  }


  public getMessageErrorByKey(key: T): string {
    const keyString = (key as unknown) as string;
    const message = this.errorsObject[keyString] as string;
    return message;
  }


  /**
   * @description `<T>` is an object with `{key_error: message}`
   * @param key_error key from [T]
   * @returns object from `ErrorSchema<T>`
   * ```typescript
   * type ErrorSchema<T> = { key: T, message: string }
   * const errorToReturn: ErrorSchema<T> = {
   *  key: "REQUIRED",
   *  message: "Message about the value es required",
   * }
   * ```
   */
  public getErrorByKey(key_error: T): ErrorSchema<T> {

    const arrayKeys = Object.keys(this.errorsObject) as unknown[];
    const keyErrorToReturn: T = (arrayKeys as T[])
      .find((keyError) => keyError === key_error);

    const keyErrorStringToReturn = (keyErrorToReturn as unknown) as string

    const errorToReturn: ErrorSchema<T> = {
      key: keyErrorToReturn,
      message: this.errorsObject[keyErrorStringToReturn],
    }

    return errorToReturn;
  }


  /**
   * @description `<T>` is an object with `{key_error: message}`
   * @param key_error key from [T]
   * @returns object from ObjectError
   * ```typescript
   * type ObjectError = { [key: string]: string } // {"REQUIRED": "El email es requerido"}
   * ```
   */
  public getObjectErrorByKey(key_error: T): ObjectError {
    const arrayKeys = Object.keys(this.errorsObject) as unknown[];
    const keyErrorToReturn: T = (arrayKeys as T[])
      .find((keyError) => keyError === key_error);

    const keyString = (keyErrorToReturn as unknown) as string;

    return {
      [keyString]: this.errorsObject[keyString]
    };
  }

}