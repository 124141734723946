import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

type StatesDeleteUsersUI = "CONFIRMATION" | "IS_LOADING" | "FINISHED" | "CLOSED";

@Component({
  selector: 'app-modal-logout',
  templateUrl: './modal-logout.component.html',
  styleUrls: ['./modal-logout.component.scss']
})
export class ModalLogoutComponent implements OnInit {

  public currentState: StatesDeleteUsersUI = "CONFIRMATION";

  @Output() buttonAcepted = new EventEmitter<void>();

  constructor(public dialogRef: MatDialogRef<ModalLogoutComponent>) { }

  ngOnInit() {
  }

  public closeModal() { this.dialogRef.close(); }
  public renderContainer(state: StatesDeleteUsersUI) { return this.currentState === state; }

  public logout() {
    this.currentState = 'IS_LOADING';
    this.buttonAcepted.emit();
  }

}
