import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticateRoutingModule } from './authenticate-routing.module';
import { CloseSessionButtonComponent } from './components/close-session-button/close-session-button.component';
import { MatDialogModule, MatIconModule } from '@angular/material';
import { CloseSessionModalComponent } from './components/close-session-modal/close-session-modal.component';
import { ComponentsModule } from '../shared/components/components.module';

@NgModule({
  declarations: [

    // components
    CloseSessionButtonComponent,

    // modals
    CloseSessionModalComponent,

  ],
  exports: [
    CloseSessionButtonComponent,
  ],
  imports: [
    CommonModule,
    AuthenticateRoutingModule,

    // mine
    ComponentsModule,

    // third
    MatIconModule,
    MatDialogModule,
  ],
  entryComponents: [
    CloseSessionModalComponent,
  ]
})
export class AuthenticateModule { }
