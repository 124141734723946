import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from './httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class CertificatesService {
  apiUrl: string;

  constructor(private httpClient: HttpclientService){
    this.apiUrl = environment.apiUrl;
  }

  findApi(page: number, pageSize: number): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/coop/certificates?page=${page}&pageSize=${pageSize}`);
  }
  createApi(certificate: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/coop/certificates`, certificate);
  }

  createSpecies(certificateSpecies: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/coop/certificates/species`, certificateSpecies)
  }
  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/coop/certificates/${id}`);
  }
  updateApi(id: number, certificate: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/coop/certificates/${id}`, certificate)
  }
  findByIdApi(id: number): Observable <any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/coop/certificates/${id}`)
  }
  getFisheryCertification(): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/coop/certificates/fishery`)
  }

}