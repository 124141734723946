import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-yellow',
  templateUrl: './alert-yellow.component.html',
  styleUrls: ['./alert-yellow.component.scss']
})
export class AlertYellowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
