import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import * as Highcharts from 'highcharts';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SurveyService } from 'src/app/services/survey.service';

@Component({
  selector: 'app-survey-analytics',
  templateUrl: './survey-analytics.component.html',
  styleUrls: ['./survey-analytics.component.scss']
})
export class SurveyAnalyticsComponent implements AfterViewInit {

  public questions;
  highcharts = Highcharts;
  charts = {};

  dataLength: number;

  constructor(
    private surveyService: SurveyService
  ) { }

  ngAfterViewInit(): void {
    this.loadData()
  }

  loadData() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.surveyService.getQuestionsAnalytics()
        }),
        map(data => {
          this.dataLength = data.count;
          console.log(`[data.data] -> `, data.data);
          return data.data;
        }),
        catchError((err) => {
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.questions = data;
        this.questions.map(question => {
          let options = question.options.map(option => {
            return [option.answer, option.count]
          })
          this.charts[`${question.id}`] = {
            chart: {
              plotBorderWidth: null,
              plotShadow: false,
            },
            credits: {
              enabled: false
            },
            title: {
              text: ''
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.y}</b>'
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.y}',
                }
              }
            },
            series: [{
              type: 'pie',
              name: 'Peso',
              data: options
            }]

          }
        })

      })




  }



}
