import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-selector-searcher',
  templateUrl: './selector-searcher.component.html',
  styleUrls: ['./selector-searcher.component.scss']
})
export class SelectorSearcherComponent implements OnInit {

  @Input() public label: string = "";
  @Input() public options: string[] = [];

  @Output() public selectOptionEmit = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  public selectOption(optionSelected: string) {
    this.selectOptionEmit.emit(optionSelected);
  }

}
