import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-header-toggle',
  templateUrl: './table-header-toggle.component.html',
  styleUrls: ['./table-header-toggle.component.scss']
})
export class TableHeaderToggleComponent implements OnInit {

  @Input() public label: string = "[NOT-LABEL]";
  @Output() public toggleStateEmit = new EventEmitter();
  public state: boolean = true;

  constructor() { }

  ngOnInit() { }

  public toggleState() {
    this.state = !this.state;
    this.toggleStateEmit.emit(this.state);
  }

}
