import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-searcher-input',
    templateUrl: './searcher-input.component.html',
    styleUrls: ['./searcher-input.component.scss']
})
export class SearcherInputComponent {

    @Input() maxWidthInput?: number;

    @Input() public placeHolder: string = "";
    @Output() queryChange = new EventEmitter<string>();

    public keyword: string = '';

    constructor() { }

    public queryChangeEmit(keyWord: any) {
        this.queryChange.emit(keyWord);
    }

    public cleanInput() {
        this.keyword = '';
        this.queryChangeEmit(this.keyword);
    }

}
