import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from './httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class SolutionService {
  apiUrl: string;
  constructor(
    private httpClient: HttpclientService 
  ) { 
    this.apiUrl = environment.apiUrl;
  }

  createApi(solution: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/solution`, solution);
  }

  findApi(page: number, pageSize: number, query: string): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/solution?page=${page}&pageSize=${pageSize}&query=${query}`);
  }

  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/solution/${id}`)
  }

  updateById(id: number, solution: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/solution/${id}`, solution)
  }

  typeaheadAnpNames(search: string): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/solution/anp-names/typeahead/${search}`)
  }

  typeaheadTags(search: string): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/solution/tags/typeahead/${search}`)
  }

  uploadImages(filesUpload: any[], solutionId: number) {
    const formData: FormData = new FormData();
    filesUpload.map(files => {
      Object.keys(files).forEach(key => {
        formData.append(files[key].name, files[key]);
      })
    })
    return this.httpClient.sentFileWithAuth(`${this.apiUrl}/solution/images/${solutionId}`, formData)
  }

  deleteImage(imageId: number) {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/solution/image/${imageId}`)
  }

}
