import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-network-status-error',
  templateUrl: './network-status-error.component.html',
  styleUrls: ['./network-status-error.component.scss']
})
export class NetworkStatusErrorComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
