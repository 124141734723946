import { Component, OnInit } from '@angular/core';
import { CloseSessionModalComponent } from '../close-session-modal/close-session-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-close-session-button',
  templateUrl: './close-session-button.component.html',
  styleUrls: ['./close-session-button.component.scss']
})
export class CloseSessionButtonComponent implements OnInit {

  private _referenceModal?: MatDialogRef<CloseSessionModalComponent, any>;
  private _modalWasClose$: Subscription;

  constructor(
    public matDialog: MatDialog,
  ) { }

  ngOnInit() {
    if (this._modalWasClose$) this._modalWasClose$.unsubscribe();
  }


  public openAddUserDialog() {

    this._referenceModal = this.matDialog.open(
      CloseSessionModalComponent,
      {
        width: '28rem',
      }
    );

    this._modalWasClose$ = this._referenceModal
      .afterClosed()
      .subscribe((result) => { });

  }

}
