import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { Subscription } from 'rxjs';
import { Alert } from '../../models/alert.interface';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnDestroy {

  public static SECONDS = 3;
  public alert?: Alert;
  public showAlert: boolean = false;
  private alert$: Subscription;

  constructor(
    alertService: AlertService,
  ) {
    this.alert$ = alertService
      .getAlertObservable()
      .subscribe((alert: Alert) => this.launch(alert))
  }

  public ngOnDestroy(): void {
    this.alert$.unsubscribe();
  }

  public launch(alert: Alert): void {

    const canItShowAlert = alert.showIn === 'InAllApp' ? true : false;
    if (canItShowAlert == false) return;

    const secondsToShowAlert = (alert.seconds || AlertComponent.SECONDS) * 1000;

    this.alert = alert;
    this.showAlert = true;

    setTimeout(
      () => {
        this.closeAlert();
      },
      secondsToShowAlert
    );
  }

  public closeAlert() {
    this.showAlert = false;
  }

}
