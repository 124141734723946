import { AfterViewInit, Component, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { PermitService } from 'src/app/services/permit.service';
import { merge, observable, of as observableOf } from 'rxjs';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-fishing-permits',
  templateUrl: './fishing-permits.component.html',
  styleUrls: ['./fishing-permits.component.scss']
})
export class FishingPermitsComponent implements AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    public fishingPermits: [];
    public permitsLength;
    public loadingPermits: boolean;
  constructor(
    private router: Router,
    private fishingPermitService: PermitService,
    public matSnackBar: MatSnackBar
    ) { 
      this.fishingPermits = [];
      this.loadingPermits = true;
      console.log('fisshing');
    }
    ngAfterViewInit(): void {
      console.log('load');
      this.loadData();
    }


  loadData() {
    merge(this.paginator.page, this.paginator.pageSize)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingPermits = true;
          return this.fishingPermitService.findApi(this.paginator.pageIndex, this.paginator.pageSize)
        }),
        map(data => {
          this.loadingPermits = false;
          this.permitsLength = data['count'];

          return data['rows'];
        }),
        catchError((err) => {
          this.loadingPermits = false;
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        console.log(data);
        this.fishingPermits = data;
      })
  }

  onClickAdd() {
    this.router.navigate(['/dashboard/fishing-permits/add'])
  }

  editFishingPermit(fishingPermit) {
    this.router.navigate(['/dashboard/fishing-permits/add'], {
      state: {
        fishingPermit: fishingPermit
      }
    })
  }

  deleteFishingPermit(id) {
    this.fishingPermitService.deleteApi(id)
      .subscribe(
        result => {
          if (result.confirmation == true) {
            this.matSnackBar.open('Fishing permits deleted succesfully', 'Cerrar', {duration: 4000});
            this.loadData();
            return;
          }
          if (result.confirmation == false) {
            this.matSnackBar.open('Error deleting Fishing permits.', 'Cerrar', {duration: 4000});
          }
        },
        err => {
          console.log(err);
        }
      )
  }
}
