import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { CommunityService } from 'src/app/services/community/community.service';
import { CountryService } from 'src/app/services/country/country.service';
import { RegionService } from 'src/app/services/region.service';
import { StateService } from 'src/app/services/states/state.service';

@Component({
  selector: 'app-edit-demography',
  templateUrl: './edit-demography.component.html',
  styleUrls: ['./edit-demography.component.scss']
})
export class EditDemographyComponent implements OnInit {
  public form: FormGroup
  public name = new FormControl('', Validators.required);
  public editOf: string;
  public submitted: boolean;


  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditDemographyComponent>,
    private regionService: RegionService,
    private stateService: StateService,
    private communityService: CommunityService,
    private countryService: CountryService,
    private matSnackBar: MatSnackBar,
    ) {
     this.form = fb.group({
       'name': this.name
     })
     this.editOf = '';
     this.submitted = false;
  }

  ngOnInit() {
    if(this.data && this.data.editOf){
      this.editOf = this.data.editOf;
      this.form.controls['name'].setValue(this.data.obj.name)
    }
  }

  get f(): any { return this.form.controls; }


  close(): void {
    this.dialogRef.close();
  }

  submit() {
    this.submitted = true;
    let name = this.form.get('name').value;
    if(name == "") {
      this.matSnackBar.open("You must enter name.", 'Cerrar', {duration: 4000} )
      return;
    }

    let obj = {
      name: name
    }
    if(this.editOf == 'country') {
      this.countryService.updateApi(this.data.obj.id, obj)
        .subscribe(
          response => {
            if(response.confirmation) {
              this.matSnackBar.open('Updated successfully', 'Cerrar', {duration: 4000});
            }
            this.dialogRef.close();
          },
          err => {
            console.log(err);
            if (err.error.confirmation == false) {
              this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000});
            }
          }
        )
        this.editOf = '';
        return;
    }


    if(this.editOf == 'region') {
      this.regionService.updateApi(this.data.obj.id, obj)
        .subscribe(
          response => {
            if(response.confirmation) {
              this.matSnackBar.open('Updated successfully', 'Cerrar', {duration: 4000});
            }
            this.dialogRef.close();
          },
          err => {
            console.log(err);
            if (err.error.confirmation == false) {
              this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000});
            }
          }
        )
        this.editOf = '';
        return;
    }
    if(this.editOf == 'state') {
      this.stateService.updateApi(this.data.obj.id, obj)
        .subscribe(
          response => {
            if(response.confirmation) {
              this.matSnackBar.open('Updated successfully', 'Cerrar', {duration: 4000});
            }
            this.dialogRef.close();
          },
          err => {
            console.log(err);
            if (err.error.confirmation == false) {
              this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000});
            }
          }
        )
        this.editOf = '';
        return;
    }
    if(this.editOf == 'community') {
      this.communityService.updateApi(this.data.obj.id, obj)
        .subscribe(
          response => {
            if(response.confirmation) {
              this.matSnackBar.open('Updated successfully', 'Cerrar', {duration: 4000});
            }
            this.dialogRef.close();
          },
          err => {
            console.log(err);
            if (err.error.confirmation == false) {
              this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000});
            }
          }
        )
        this.editOf = '';
        return;
    }
    this.form.reset();
  }
  
}
