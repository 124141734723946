import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './components/alert/alert.component';
import { AlertInComponentComponent } from './components/alert-in-component/alert-in-component.component';
import { AlertSuccessComponent } from './components/alert-success/alert-success.component';
import { AlertBlueComponent } from './components/alert-blue/alert-blue.component';
import { AlertYellowComponent } from './components/alert-yellow/alert-yellow.component';

@NgModule({
  declarations: [
    AlertComponent,
    AlertInComponentComponent,
    AlertSuccessComponent,
    AlertBlueComponent,
    AlertYellowComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AlertComponent,
    AlertInComponentComponent,
  ]
})
export class AlertsModule { }
