import { Country } from './country';
import { OrganizationDetails } from './organization_details';
import { Region } from './region_list';
import { State } from './state';

export class Organization {
    id: number;
    name: string;
    email: string;
    phone: string;
    address: string;
    website: string;
    state_id: number;
    country_id: number;
    region_id: number;
    active: boolean;
    state: State;
    country: Country;
    region: Region
    created_at: Date;
    description: string;
    closed_season: boolean;
    quota: boolean;
    claimed: boolean;
    minimum_landing: boolean;
    maximum_landing: boolean;
    voluntary_reserve: boolean;
    voluntary_reserve_id: number;
    img_url: string;
    organizationDetails: OrganizationDetails;

    constructor() {
        this.id = 0;
        this.name = '';
        this.email = '';
        this.phone = '';
        this.address = '';
        this.website = '';
        this.description = '';
        this.img_url = ''
        this.country_id = 0;
        this.state_id = 0;
        this.region_id = 0 
        this.voluntary_reserve_id = 0;
        this.active = true;
        this.state = new State();
        this.country = new Country();
        this.region = new Region();
        this.created_at = new Date();
        this.closed_season = false;
        this.quota = false;
        this.maximum_landing = false;
        this.minimum_landing = false;
        this.voluntary_reserve = false;
        this.organizationDetails = new OrganizationDetails();
    }
}