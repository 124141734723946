import { UserResponseType } from "./type.user-response";


//============================================================
// responses service
//============================================================


// errors

export interface CreateUserResponseErrorLimitOfAdmins {
    success: boolean;
    limit: boolean;
    message: string;
}

export interface CreateUserResponseErrorEmailUserName {
    success: boolean;
    message: string;
}

export interface CreateUserResponseErrorIsMinor {
    message: string[];
    stack: string;
}

export interface UpdateUserResponseErrorInternalServer {
    message: string,
    stack: string,
}


// success

export interface CreateUserResponseOK {
    confirmation: boolean;
    user: User;
}

export interface User {
    fullName: string;
    deleted: boolean;
    id: number;
    username: string;
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    organization_id: number;
    birthday: Date;
    birthplace: null;
    role: string;
    profile_img: null;
    api_token: null;
    active: boolean;
    remember_token: null;
    gender: null;
    community_id: null;
    state_id: null;
    region_id: null;
    country_id: null;
    boat_id: null;
    updatedAt: Date;
    createdAt: Date;
    currency_id: number;
    role_id: null;
    oauth: null;
    imported: null;
    email_verified: null;
    about: null;
    phone: null;
    accept_phone_call: null;
    accept_sms: null;
    display_email: null;
    public: null;
    device_token: null;
    facebook_id: null;
    OrganizationId: number;
    boatId: null;
    communityId: null;
    countryId: null;
    regionId: null;
    stateId: null;
}




//============================================================
// responses local
//============================================================

export interface CreateUserResponse {
    success: boolean;
    responseType: UserResponseType;
    message?: string,
}




//============================================================
// catch diferent responses
//============================================================

export const catchDiferentResponses = (response): CreateUserResponse => {


    const isCreateUserResponseOK = (response): response is CreateUserResponseOK =>
        "confirmation" in response &&
        "user" in response;

    if (isCreateUserResponseOK(response))
        return {
            success: true,
            responseType: "SUCCESS",
        }

    const isErrorEmail = (response): response is { message: string } => "message" in response;
    if (isErrorEmail(response) && response.message[0] === "\"email\" must be a valid email. ")
        return {
            success: false,
            responseType: "INVALID_USERNAME_EMAIL",
            message: "Correo electrónico invalido."
        }

    const isCreateUserResponseErrorIsMinor = (response): response is CreateUserResponseErrorIsMinor =>
        "message" in response &&
        "stack" in response;

    if (isCreateUserResponseErrorIsMinor(response))
        return {
            success: false,
            responseType: "IS_A_MINOR",
            message: "Fecha inválida, esta fecha de nacimiento corresponde a la de un menor de edad."
        }



    const isCreateUserResponseErrorLimitOfAdmins = (response): response is CreateUserResponseErrorLimitOfAdmins =>
        "success" in response &&
        "limit" in response &&
        "message" in response;

    if (isCreateUserResponseErrorLimitOfAdmins(response))
        return {
            success: false,
            responseType: "LIMIT_ADMINS_COOPERATIVA",
            message: "Limite de Administradores de Cooperativa alcanzado."
        }



    const isCreateUserResponseErrorEmailUserName = (response): response is CreateUserResponseErrorEmailUserName =>
        "success" in response &&
        "message" in response;

    if (isCreateUserResponseErrorEmailUserName(response))
        return {
            success: false,
            responseType: "INVALID_USERNAME_EMAIL",
            message: "Correo electrónico ya en uso."
        }



    return {
        success: false,
        responseType: "UNRECOGNIZED_ERROR",
        message: "Algo salió mal, no se pudo agregar al usuario, inténtalo nuevamente."
    }

}