import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

import { HttpclientService } from './httpclient.service';
import { environment } from '../../environments/environment'
import { ForgotPasswordRequest } from '../models/forgot_password_request';
// import { User } from '../models/user';
import { ErrorKeysUserAuthResponse, UserAuthRequest, UserAuthResponseOK } from '../components/login/models/UserAuthRequest.interface';
import { EMAIL_ERRORS_TYPE } from '../components/login/models/email-error-controller';
import { PASS_ERRORS_TYPE } from '../components/login/models/pass-error-controller';
import { User } from '../models/interface.user';
import { User as UserFixed } from '../models/models_fixed/babel.models';

import { SendEmailToResetPassResponseLocal, SendEmailToResetPassResponseServer } from 'src/app/login/service/business-rules/send-email-to-reset-pass.response';
import { ChangePassRequest } from '../components/reset-password/models/ChangePass.request';
import { ChangePassResponseLocal, ChangePassResponseService } from '../components/reset-password/models/ChangePass.response';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static TOKEN_NAME = 'token';
  public static USER_NAME = 'user';

  private apiUrl: string;
  authHeaders: HttpHeaders;

  constructor(
    private _router: Router,
    private http: HttpclientService
  ) {
    this.apiUrl = environment.apiUrl;
    this.authHeaders = this.loadTokenToHeaders();
  }



  //========================================================================================================================
  // log-in
  //========================================================================================================================

  public async logIn(req: UserAuthRequest): Promise<ErrorKeysUserAuthResponse<EMAIL_ERRORS_TYPE, PASS_ERRORS_TYPE> | void> {

    // case 1
    const instanceOfUserAuthResponseOK = (response: any): response is UserAuthResponseOK => "token" in response && "user" in response;
    const responseLikeUserAuthResponseOK = (response: UserAuthResponseOK): void => {
      console.log(`[response.user] -> `, response.user);
      this.storeUserData(response.token, response.user);
      if (response.user.role == "admin") this._router.navigate(['/dashboard/main']);
      if (response.user.role == "co-admin") this._router.navigate(['/dashboard/users-op']);
      return;
    }

    // case 2
    interface ObjResponse { "success": boolean; "message": string }
    const instanOfObjResponse = (response: any): response is ObjResponse => "success" in response && "message" in response;
    const responseLikeObjResponse = (response: ObjResponse): ErrorKeysUserAuthResponse<EMAIL_ERRORS_TYPE, PASS_ERRORS_TYPE> => {
      if (response.success == false) return { "password": "INVALID" }
      return { "username_email": "DEFAULT", "password": "DEFAULT" };
    }

    // case 3
    const errorLikeHttpErrorResponse = (error: HttpErrorResponse): ErrorKeysUserAuthResponse<EMAIL_ERRORS_TYPE, PASS_ERRORS_TYPE> => {
      if (error.status == 401) return { "username_email": "NOT_REGISTERED" };
      return { "username_email": "DEFAULT", "password": "DEFAULT" };
    }

    try {

      const response = await this.http.post(`${this.apiUrl}/auth/login`, req).toPromise();
      // call case 1
      if (instanceOfUserAuthResponseOK(response)) return responseLikeUserAuthResponseOK(response);
      // call case 2
      if (instanOfObjResponse(response)) return responseLikeObjResponse(response);

    } catch (error) {

      // call case 3
      if (error instanceof HttpErrorResponse) return errorLikeHttpErrorResponse(error);

    }

  }

  public get getCurrentUserLogged(): UserFixed {
    try {
      const userObject = localStorage.getItem(AuthService.USER_NAME);
      const userObjectParse = JSON.parse(userObject) as UserFixed;
      return userObjectParse;
    } catch (error) {
      this.verifyUserLogged();
    }
  }

  public verifyUserLogged() {
    this.loggedIn() ? this._router.navigate(['/dashboard']) : this._router.navigate(['/login']);
  }



  //========================================================================================================================
  // manage tokens
  //========================================================================================================================

  private get token(): string { return localStorage.getItem(AuthService.TOKEN_NAME); }

  private storeUserData(token, user) {
    localStorage.setItem(AuthService.TOKEN_NAME, token);
    localStorage.setItem(AuthService.USER_NAME, JSON.stringify(user));
  }

  private loadTokenToHeaders(): HttpHeaders {
    this.authHeaders = new HttpHeaders();
    this.authHeaders.append('Authorization', this.token);
    this.authHeaders.append('Content-Type', 'application/json');
    return this.authHeaders;
  }

  public loggedIn(): boolean {
    const jwtHelperService = new JwtHelperService();
    const isTokenExpired = jwtHelperService.isTokenExpired(localStorage.getItem(AuthService.TOKEN_NAME))
    return (isTokenExpired == false) ? true : false;
  }



  //========================================================================================================================
  // log-out
  //========================================================================================================================

  public logout(): void {
    localStorage.removeItem(AuthService.TOKEN_NAME);
    localStorage.removeItem(AuthService.USER_NAME);
    this._router.navigate(['/'])
  }

  //============================================================
  // forget pass
  //============================================================

  public async sendEmailToResetPass(email: string): Promise<SendEmailToResetPassResponseLocal> {

    const messageError = "Dirección de correo electrónico incorrecta, verifica tus datos e intentalo nuevamente.";
    const messageSuccess = "";

    try {

      const urlFormed = `${this.apiUrl}/auth/forgot-password`;
      const requestBody = { email: email }
      const response = await this.http.post(urlFormed, requestBody).toPromise();
      const responseServer = response as SendEmailToResetPassResponseServer;

      const message = responseServer.success ? messageSuccess : messageError;

      return {
        wasSuccess: responseServer.success,
        message: message,
      };

    } catch (error) {

      return {
        wasSuccess: false,
        message: messageError,
      };

    }

  }

  public async forgotPassword(req: ForgotPasswordRequest): Promise<boolean> {
    const urlFormed = `${this.apiUrl}/auth/forgot-password`;
    const response = await this.http.post(urlFormed, req).toPromise();

    interface response { "confirmation": boolean; "user": any }
    const instanOfResponse = (response: any): response is response => "confirmation" in response;

    if (instanOfResponse(response) && response.confirmation == false) return false;

    return true;
  }

  public async changePass(request: ChangePassRequest): Promise<ChangePassResponseLocal> {

    const messageError = "Algo salió mal, inténtalo nuevamente más tarde."

    try {

      const urlFormed = `${this.apiUrl}/auth/change-password/${request.token}`;
      const response = await this.http.post(urlFormed, request.pass).toPromise();
      const responseService = response as ChangePassResponseService;

      const message = responseService.success ? "" : messageError;

      return {
        wasSuccess: responseService.success,
        message: message,
      }

    } catch (error) {

      return {
        wasSuccess: false,
        message: messageError
      }

    }

  }

  //========================================================================================================================
  // check this :)
  //========================================================================================================================

  resetPasswordApi(req, token): Observable<any> {
    return this.http.post(`${this.apiUrl}/auth/change-password/${token}`, req);
  }

  verifyEmailApi(token): Observable<any> {
    return this.http.get(`${this.apiUrl}/auth/verify-email/${token}`);
  }

  forgotPasswordApi(req: ForgotPasswordRequest): Observable<any> {
    return this.http.post(`${this.apiUrl}/auth/forgot-password`, req)
  }

  getUserData(): User {
    let u = localStorage.getItem(AuthService.USER_NAME);
    let ou = JSON.parse(u);
    if (!u) {
      return null;
    }
    return ou;
  }
}
