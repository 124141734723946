import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OptionMenu } from './interface.options-menu';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material';

@Component({
  selector: 'app-table-header-menu',
  templateUrl: './table-header-menu.component.html',
  styleUrls: ['./table-header-menu.component.scss']
})
export class TableHeaderMenuComponent implements OnInit {

  @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;

  @Input() public label: string = "[NOT-LABEL]";
  @Input() public options: OptionMenu<any>[] = [];
  @Output() optionSelectedEmit = new EventEmitter();

  public isShowingMenu: boolean = false;
  public optionSelectedValue: any = undefined;

  constructor() { }

  ngOnInit(): void { }

  public selectOption(option: any) {
    this.isShowingMenu = false;
    this.optionSelectedValue = option;
    this.optionSelectedEmit.emit(option);
  }

  public showMenu() {
    this.isShowingMenu = true;
    this.trigger.openMenu();
  }

  public hiddenMenu() {
    this.isShowingMenu = false;
  }

  public cleanOptionSelectedValue() {
    this.optionSelectedValue = undefined;
  }

}
