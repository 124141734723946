import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors } from "@angular/forms"
import { EMAIL_ERRORS_TYPE, errorControllerEmail } from './email-error-controller';
import { PASS_ERRORS_TYPE, errorControllerPass } from './pass-error-controller';
import { UserAuthRequest } from "./UserAuthRequest.interface";

export class LoginForm {

  public static EMAIL_FORM_CONTROL_NAME = 'email';
  public static PASS_FORM_CONTROL_NAME = 'password';

  public formGroup: FormGroup;

  constructor(formBuilder: FormBuilder) {

    this.formGroup = formBuilder.group(
      {
        [LoginForm.EMAIL_FORM_CONTROL_NAME]:
          [
            '',
            [
              this.requiredEmail,
              this.patternEmail
            ]
          ],
        [LoginForm.PASS_FORM_CONTROL_NAME]:
          [
            '',
            [
              this.requiredPassword,
              // this.patternPassword,
            ]
          ],
      });
  }


  public get form(): FormGroup {
    return this.formGroup;
  }

  public get userAuthRequest(): UserAuthRequest {
    const userAuthRequest: UserAuthRequest = {
      username_email: this.formGroup.get(LoginForm.EMAIL_FORM_CONTROL_NAME).value,
      password: this.formGroup.get(LoginForm.PASS_FORM_CONTROL_NAME).value,
    }
    return userAuthRequest;
  }


  //============================================================
  // access to forms controls
  //============================================================

  public markAllFormAsTouched() {
    const emailFormControl = this.formGroup.get(LoginForm.EMAIL_FORM_CONTROL_NAME) as FormControl;
    emailFormControl.markAsTouched({ "onlySelf": true });

    const passFormControl = this.formGroup.get(LoginForm.PASS_FORM_CONTROL_NAME) as FormControl;
    passFormControl.markAsTouched({ "onlySelf": true });
  }


  //  email

  public showErrorEmail(): boolean {
    const emailFormControl = this.formGroup.get(LoginForm.EMAIL_FORM_CONTROL_NAME) as FormControl;
    const isTouchedField = emailFormControl.touched ? true : false;
    const isValidField = emailFormControl.valid ? true : false;
    if (isValidField === false) return true;
    if (isTouchedField === false) return false;
    return true;
  }

  public showErrorEmailWithCustomMessage(keyError: EMAIL_ERRORS_TYPE) {
    const emailFormControl = this.formGroup.get(LoginForm.EMAIL_FORM_CONTROL_NAME) as FormControl;
    const error = errorControllerEmail.getObjectErrorByKey(keyError);
    emailFormControl.setErrors(error);
  }

  public getErrorEmail(): string | null {
    const emailFormControl = this.formGroup.get(LoginForm.EMAIL_FORM_CONTROL_NAME) as FormControl;
    const errorsEmail = emailFormControl.errors;
    if (errorsEmail == null) return null;
    const firsError = Object.keys(errorsEmail)[0] as EMAIL_ERRORS_TYPE;
    return errorControllerEmail.getMessageErrorByKey(firsError);
  }

  public getKeyErrorEmail(): EMAIL_ERRORS_TYPE | undefined {
    const emailFormControl = this.formGroup.get(LoginForm.EMAIL_FORM_CONTROL_NAME) as FormControl;
    const errorsEmail = emailFormControl.errors;
    if (errorsEmail == null) return undefined;
    return Object.keys(errorsEmail)[0] as EMAIL_ERRORS_TYPE;
  }


  // password

  public showErrorPass(): boolean {
    const passFormControl = this.formGroup.get(LoginForm.PASS_FORM_CONTROL_NAME) as FormControl;
    const isTouchedField = passFormControl.touched ? true : false;
    const isValidField = passFormControl.valid ? true : false;
    if (isTouchedField === false) return false;
    if (isValidField === false) return true;
    return true;
  }

  public showErrorPassWithCustomMessage(keyError: PASS_ERRORS_TYPE) {
    const passFormControl = this.formGroup.get(LoginForm.PASS_FORM_CONTROL_NAME) as FormControl;
    const error = errorControllerPass.getObjectErrorByKey(keyError);
    passFormControl.setErrors(error);
  }

  public getErrorPass(): string | null {
    const passFormControl = this.formGroup.get(LoginForm.PASS_FORM_CONTROL_NAME) as FormControl;
    const errorsPass = passFormControl.errors;
    if (errorsPass == null) return null;
    const firsError = Object.keys(errorsPass)[0] as PASS_ERRORS_TYPE;
    return errorControllerPass.getMessageErrorByKey(firsError);
  }


  public getKeyErrorPass(): PASS_ERRORS_TYPE | undefined {
    const passFormControl = this.formGroup.get(LoginForm.PASS_FORM_CONTROL_NAME) as FormControl;
    const errorsPass = passFormControl.errors;
    if (errorsPass == null) return undefined;
    return Object.keys(errorsPass)[0] as PASS_ERRORS_TYPE;
  }



  //============================================================
  // validations
  //============================================================


  // email

  public requiredEmail(formControl: AbstractControl): ValidationErrors | null {
    const emailValue = (formControl as FormControl).value;
    if (emailValue == "") return errorControllerEmail.getObjectErrorByKey("REQUIRED");
    return null;
  }

  public patternEmail(formControl: AbstractControl): ValidationErrors | null {
    const regExp = new RegExp("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z0-9.-]+$");
    const emailValue = (formControl as FormControl).value;;
    const theTestIsPassed: boolean = regExp.test(emailValue);
    if (theTestIsPassed == false) return errorControllerEmail.getObjectErrorByKey("INVALID");
    return null;
  }


  // password

  public requiredPassword(formControl: AbstractControl): ValidationErrors | null {
    const passValue = (formControl as FormControl).value;
    if (passValue == "") return errorControllerPass.getObjectErrorByKey("REQUIRED");
    return null;
  }

  public patternPassword(formControl: AbstractControl): ValidationErrors | null {
    const minLength = 6;
    const passValue = ((formControl as FormControl).value) as string;
    const theTestIsPassed: boolean = passValue.length <= minLength;
    if (theTestIsPassed == true) return errorControllerPass.getObjectErrorByKey("INVALID");
    return null;
  }

}