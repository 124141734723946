import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from './httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class PermitService {
  apiUrl: string;
  constructor(private httpClient: HttpclientService) {
    this.apiUrl = environment.apiUrl;
  }


  findApi(page: number, pageSize:  number): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/permit?page=${page}&pageSize=${pageSize}`);
  }
  createApi(permit: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/permit`, permit);
  }
  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/permit/${id}`);
  }
  updateApi(id: number, permit: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/permit/${id}`, permit)
  }
  findByIdApi(id: number): Observable <any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/permit/${id}`)
  }

}
