import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Organization } from 'src/app/models/organization';
import { MatPaginator, MatTable, MatSnackBar, MatDialog } from '@angular/material';
import { CooperativaService } from 'src/app/services/organization.service';
import { merge, of as observableOf, Subject } from 'rxjs';
import { startWith, switchMap, map, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AddEditOrganizationComponent } from '../add-edit-organization/add-edit-organization.component';

@Component({
  selector: 'app-organizations-table',
  templateUrl: './organizations-table.component.html',
  styleUrls: ['./organizations-table.component.scss']
})
export class OrganizationsTableComponent implements AfterViewInit {
  displayedColumns: string[] = ['actions', 'name', 'state', 'email', 'phone', 'address', 'website', 'active', 'created_at', 'fishing_permits_details', 'health_certification_details'];
  data: Organization[];
  
  viewFishingPermitDialogRef;
  deleteOrgDialogRef;
  deleteOrgIdHolder;
  fishingPermits: any;
  healthCertification: any;
  organizationName: string;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  resultsLength = 0;
  isLoadingResults = true;

  nameModel: Subject<string> = new Subject<string>();

  constructor(private orgSvc: CooperativaService, public dialog: MatDialog, private matSnackBar: MatSnackBar) {
    this.data = [];
    this.organizationName = ""

    this.nameModel.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(res => {
        this.loadData();
      });

  }

  ngAfterViewInit() {
    this.loadData();
  }

  loadData() {
    merge(this.paginator.page, this.paginator.pageSize)
     .pipe(
       startWith({}),
       switchMap(() => {
         this.isLoadingResults = true;
         return this.orgSvc.findApi(this.paginator.pageIndex, this.paginator.pageSize, this.organizationName);
       }),
       map(data => {
         this.isLoadingResults = false;
         this.resultsLength = data["count"];
         this.data = data.rows;
         
         console.log(data);
         return data.rows;
       }),
       catchError((err) => {
         console.log(err);
         this.isLoadingResults = false;
         return observableOf([]);
       })
     ).subscribe(data => {
      this.data = data
    }
      );
   }

  openAddOrgDialog():void {
    let addOrgDialogRef = this.dialog.open(AddEditOrganizationComponent, {
      width: '500px',
      height: 'auto',
      data: {isEditComponent: false}
    })

    addOrgDialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  editOrg(id):void {
    let currentOrg;
    this.data.forEach(org => {
      if (org['id'] == id) {
        currentOrg = org
      }
    })
    let editDialogRef = this.dialog.open(AddEditOrganizationComponent, {
      width: '500px',
      height: 'auto',
      data: {isEditComponent: true, org: currentOrg}
    });

    editDialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  onSearchInput() {
    this.nameModel.next(this.organizationName);
  } 

  deleteOrg(deleteDialog, id) {
    this.deleteOrgIdHolder = id;
    this.deleteOrgDialogRef = this.dialog.open(deleteDialog, {
      width: '500px'
    })
  }

  cancelDialog() {
    this.deleteOrgDialogRef.close();
  }

  confirmOrgDelete() {
    this.orgSvc.deleteApi(this.deleteOrgIdHolder)
      .subscribe(
        result => {
          if (result.confirmation == true) {
            this.data.forEach((org, index) => {
              if(org.id == this.deleteOrgIdHolder) {
                this.data.splice(index, 1)
                this.table.renderRows();
              }
            })
            this.matSnackBar.open('Organization deleted succesfully', 'Cerrar', {duration: 4000});
            return;
          }
          if (result.confirmation == false) {
            this.matSnackBar.open('Error deleting Organization.', 'Cerrar', {duration: 4000});
          }
        },
        err => {
          console.log(err);
        }
      )
    this.deleteOrgDialogRef.close();
  }

  viewFishingPermit(viewFishingPermitDialog, org) {
    if(org.fishingPermits.length != 0) {
      this.fishingPermits = org.fishingPermits;

      this.viewFishingPermitDialogRef = this.dialog.open(viewFishingPermitDialog, {
        width: '600px',
        height: 'auto'
      })
      this.viewFishingPermitDialogRef.afterClosed().subscribe(result => {
        this.fishingPermits = [];
      })
    }
  }

  viewHealthCertification(viewHealthCertificationDialog, org) {
      this.healthCertification = org.healthCertification;
      this.viewFishingPermitDialogRef = this.dialog.open(viewHealthCertificationDialog, {
        width: '600px',
        height: 'auto'
      })
      this.viewFishingPermitDialogRef.afterClosed().subscribe(result => {
        this.healthCertification = {};
      }) 
  }

  cancelFishingPermitDialog() {
    this.viewFishingPermitDialogRef.close();
  }

}
