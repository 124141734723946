import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from './httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class EmergencyContactService {
  apiUrl: string;

  constructor(private httpClient: HttpclientService) {
    this.apiUrl = environment.apiUrl;
  }

  findApi(page: number, pageSize: number): Observable<any> {
    return  this.httpClient.getWithAuth(`${this.apiUrl}/emergency-contact?page=${page}&pageSize=${pageSize}&dashboard=true`)
  }

  createApi(emergencyContact: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/emergency-contact`, emergencyContact)
  }

  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/emergency-contact/${id}`)
  }

  updateApi(id: number, emergencyContact: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/emergency-contact/${id}`, emergencyContact);
  }

}
