import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

    public options: AnimationOptions = {
        path: '/assets/images/shared/loader.json',
    };

    constructor() { }

    ngOnInit() { }

    public animationCreated(animationItem: AnimationItem): void {
        // console.log(animationItem);
    }

}
