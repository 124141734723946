import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from '../httpclient.service';
import { TypeHeadResponse } from './interface.typeahead.response';
import { Country } from 'src/app/models/models_fixed/babel.models';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private API: string = environment.apiUrl;

  apiUrl: string;

  constructor(private httpClient: HttpclientService) {
    this.apiUrl = environment.apiUrl;
  }

  // ============================================================
  // CREATE
  // ============================================================


  // ============================================================
  // READ
  // ============================================================

  public async findByID(id: number): Promise<Country | null> {
    try {
      const urlFormed = `${this.API}/country/${id}`;
      const response = await this.httpClient.getWithAuth(urlFormed).toPromise();
      return response as Country;
    } catch (error) {
      return null;
    }
  }

  public async typeahead(countyName: string): Promise<TypeHeadResponse> {
    try {
      const urlFormed = `${this.API}/country/typeahead/${countyName}`;
      const response = await this.httpClient.getWithAuth(urlFormed).toPromise();
      return response as TypeHeadResponse;
    } catch (error) {
      return { count: 0, rows: [] }
    }
  }

  public async getAllCountries(): Promise<Country[]> {

    try {

      // {{local}}/country?page=0&pageSize=99999999
      const urlFormed = `${this.API}/country?page=0&pageSize=99999999`;
      const response = await this.httpClient.getWithAuth(urlFormed).toPromise();
      const responseServer = response as { "count": number, "rows": Country[], }

      return responseServer.rows;

    } catch (error) {

      return [];

    }

  }


  // ============================================================
  // UPDATE
  // ============================================================


  // ============================================================
  // DELETE
  // ============================================================

  // * ✅✅✅

  private static API: string = environment.apiUrl;

  findApi(page: number, pageSize: number): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/country?page=${page}&pageSize=${pageSize}`)
  }
  createApi(country: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/country`, country);
  }
  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/country/${id}`);
  }
  updateApi(id: number, country: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/country/${id}`, country);
  }

}
