import { Component, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { DropdownOption } from 'src/app/models/dropdown_option';
import { CooperativaService } from 'src/app/services/organization.service';
import { RegionService } from 'src/app/services/region.service';
import { StateService } from 'src/app/services/states/state.service';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material';
import { Organization } from 'src/app/models/organization';
import { OrganizationContactService } from 'src/app/services/organization-contact.service';
import { SustainabilityMeasuresService } from 'src/app/services/sustainability-measures.service';
import { CountryService } from 'src/app/services/country/country.service';

@Component({
  selector: 'app-org-settings',
  templateUrl: './org-settings.component.html',
  styleUrls: ['./org-settings.component.scss']
})
export class OrgSettingsComponent implements AfterViewInit {
  public orgForm: FormGroup;
  public name: FormControl;
  public email: FormControl;
  public address: FormControl;
  public description: FormControl;
  public claimed: FormControl;
  public states: DropdownOption[];
  public countries: DropdownOption[];
  public selectedState: string;
  public selectedCountry: string;
  public selectedRegion: string;
  submitted = false;
  userSession: User;
  org: Organization;


  public orgDetails: FormGroup;
  public phones: FormArray;
  public links: FormArray;
  public selectedFileNames: string;
  public selectedFiles?: File;
  public previews: string[] = [];
  public organizationContact: boolean = false;
  public organizationContactIdHolder: number;

  public sustainAbilityForm: FormGroup;
  public closed_season = new FormControl(false);
  public quota = new FormControl(false);
  public minimum_landing = new FormControl(false);
  public maximum_landing = new FormControl(false);
  public voluntary_reserve = new FormControl(false);
  public selectedVoluntaryReserve;
  public voluntaries: [];
  public currentImage: string;


  constructor(
    private _orgSvc: CooperativaService,
    private _fb: FormBuilder,
    private _countrySvc: CountryService,
    private _stateSvc: StateService,
    private _authSvc: AuthService,
    private _orgDetailsSvc: OrganizationContactService,
    private matSnackBar: MatSnackBar,
    private _sustainabilityService: SustainabilityMeasuresService,
  ) {
    this.name = new FormControl('', Validators.required);
    this.email = new FormControl('', Validators.compose([Validators.required, Validators.email]));
    this.address = new FormControl('');
    this.description = new FormControl('');
    this.claimed = new FormControl(true);
    this.orgForm = _fb.group({
      'name': this.name,
      'email': this.email,
      'address': this.address,
      'description': this.description,
      'claimed': this.claimed
    });

    this.orgDetails = _fb.group({
      'phones': new FormArray([new FormControl('')]),
      'links': new FormArray([new FormControl('')])
    })

    this.sustainAbilityForm = _fb.group({
      'closed_season': this.closed_season,
      'quota': this.quota,
      'minimum_landing': this.minimum_landing,
      'maximum_landing': this.maximum_landing,
      'voluntary_reserve': this.voluntary_reserve
    })

    this.states = [];
    this.countries = [];
    this.selectedRegion = '';
    this.selectedState = '';
    this.selectedCountry = '';
    this.selectedVoluntaryReserve = '';
    this.voluntaries = [];
    this.userSession = this._authSvc.getUserData();
    if (!this.userSession) {
      this.matSnackBar.open("Error: user data not exists", 'Cerrar', { duration: 4000 });
    }
    this.org = new Organization();

    this._sustainabilityService.getVoluntaryApi().subscribe(
      response => {
        this.voluntaries = response
      },
      err => {
        console.log(err);
      }
    )
  }

  ngAfterViewInit() {
    this.loadData();
  }

  get f(): any { return this.orgForm.controls; }
  get fs(): any { return this.sustainAbilityForm.controls; }

  loadData() {
    this._orgSvc.findByIdApi(this.userSession.organization_id)
      .subscribe(data => {
        this.org = data;
        this.orgForm.controls['name'].setValue(this.org.name);
        this.orgForm.controls['email'].setValue(this.org.email);
        this.orgForm.controls['address'].setValue(this.org.address);
        this.orgForm.controls['description'].setValue(this.org.description);
        this.orgForm.controls['claimed'].setValue(this.org.claimed);
        this.sustainAbilityForm.controls['closed_season'].setValue(this.org.closed_season);
        this.sustainAbilityForm.controls['quota'].setValue(this.org.quota);
        this.sustainAbilityForm.controls['minimum_landing'].setValue(this.org.minimum_landing);
        this.sustainAbilityForm.controls['maximum_landing'].setValue(this.org.maximum_landing);
        this.sustainAbilityForm.controls['voluntary_reserve'].setValue(this.org.voluntary_reserve);
        this.selectedVoluntaryReserve = this.org.voluntary_reserve_id;
        if(this.org.organizationDetails != null) {
          this.organizationContact = true;
          this.orgDetails.setControl('phones', this._fb.array(this.org.organizationDetails.phone_numbers || []))
          this.orgDetails.setControl('links', this._fb.array(this.org.organizationDetails.links || []))

        }

        this.currentImage = this.org.img_url;

        this._countrySvc.findApi(0,50)
        .subscribe(data => {
          for(let con of data.rows) {
            this.countries.push({title: con.name, value: con.id.toString()})
          }
          this.selectedCountry = this.org.country_id + '';
          this.onCountrySelect(true);

        },
        err => {
          this.matSnackBar.open("Error: "+ err.error.message, 'Close', {duration: 4000})
        }
      )
      }, err => {
        console.log(err);
        if (err.error.confirmation == false) {
          this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000});
        }
      });
  }

  addPhone() {
    const phone =  new FormControl('');
    (<FormArray>this.orgDetails.controls['phones']).push(phone)
  }

  addLink() {
    const link =  new FormControl('');
    (<FormArray>this.orgDetails.controls['links']).push(link)
  }

  removePhone(i){
    (<FormArray>this.orgDetails.get('phones')).removeAt(i);
  }

  removeLink(i) {
    (<FormArray>this.orgDetails.get('links')).removeAt(i);
  }
  onCountrySelect(isLoad: boolean) {
    this.states = [];
    this._stateSvc.findApi(0,50,parseInt(this.selectedCountry))
      .subscribe(data => {
        for (let state of data.rows) {
          this.states.push({ title: state.name, value: state.id.toString() });
        }
        if (isLoad) {
          this.selectedState = this.org.state.id.toString();
        }
      }, err => {
        console.log(err);
        this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000});
      });
  }

  selectFiles(event: any): void {
    this.selectedFiles = event.target.files[0];
    this.selectedFileNames = this.selectedFiles.name;

    this.previews = [];

    if (this.selectedFiles) {
      const reader = new FileReader();

        reader.onload = (e: any) => {
          this.currentImage = e.target.result
        };

        reader.readAsDataURL(this.selectedFiles);
    }
  }

  uploadFiles() {
    //if(!this.organizationContact) {
    //  this.matSnackBar.open("Please add organization contact details");
    //  return;
    //}

    this._orgDetailsSvc.uploadApi(this.selectedFiles, this.org.id).subscribe(
      res => {
      },
      err => {
        this.matSnackBar.open('Error uploading image: ' + err, "Cerrar", {duration: 3000});
      }
    )
  }

  upload(): void {
    this.uploadFiles();
  }

  submit(): void {
    this.submitted = true;
    if (this.orgForm.valid) {
      this.org.name = this.orgForm.get('name').value;
      this.org.email = this.orgForm.get('email').value;
      this.org.address = this.orgForm.get('address').value;
      this.org.description = this.orgForm.get('description').value;
      this.org.claimed = this.orgForm.get('claimed').value;
      if (this.selectedState == '') {
        this.matSnackBar.open("You must select a state.", 'Cerrar', {duration: 4000});
        return;
      }
      this.org.state_id = parseInt(this.selectedState);
      this.org.country_id = parseInt(this.selectedCountry);

      this._orgSvc.updateApi(this.org.id, this.org).subscribe(
        response => {
          if (response.confirmation == true) {
            this.uploadFiles()
            this.matSnackBar.open('Detalles de la organización actualizado con éxito', 'Cerrar', {duration: 4000});
          }
        }, err => {
          console.log(err);
          if (err.error.confirmation == false) {
            this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000});
          }
      });
    }
  }


  submitDetails() {
     let organizationContact = {
       phone_numbers: this.orgDetails.get('phones').value,
       links: this.orgDetails.get('links').value,
       email: this.orgForm.get('email').value,
       organization_id: this.org.id
     }
     if(this.org.organizationDetails != null) {
      this._orgDetailsSvc.updateApi(this.org.organizationDetails.id, organizationContact).subscribe(
        response => {
          if (response.confirmation == true) {
            this.matSnackBar.open('Detalles de la organización actualizado con éxito', 'Cerrar', {duration: 4000});
          }
        }, err => {
          console.log(err);
          if (err.error.confirmation == false) {
            this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000});
          }
        }
      )
      return;
     }

     this._orgDetailsSvc.createApi(organizationContact).subscribe(
      response => {
        if (response.confirmation == true) {
          this.matSnackBar.open('Detalles de la organización actualizado con éxito', 'Cerrar', {duration: 4000});
          this.organizationContact = true;
          this.organizationContactIdHolder = response.data.id;
        }
      }, err => {
        console.log(err);
        if (err.error.confirmation == false) {
          this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000});
        }
      }
    )
  }

  submitDetailsSustainAbility() {
    let sustainabilityMeasures = {
      closed_season: this.sustainAbilityForm.get('closed_season').value,
      quota: this.sustainAbilityForm.get('quota').value,
      minimum_landing: this.sustainAbilityForm.get('minimum_landing').value,
      maximum_landing: this.sustainAbilityForm.get('maximum_landing').value,
      voluntary_reserve: this.sustainAbilityForm.get('voluntary_reserve').value,
      voluntary_reserve_fk: parseInt(this.selectedVoluntaryReserve)
    }
    this._orgSvc.updateApi(this.org.id, sustainabilityMeasures).subscribe(
      response => {
        if(response.confirmation == true) {
          this.matSnackBar.open('Medidas de sostenibilidad actualizadas con éxito', 'Cerrar', {duration: 4000});
        }
      },
      err => {
        if(err.error.confirmation == false) {
          this.matSnackBar.open('Error: ' + err.error.message, 'Cerrar', {duration: 4000});
        }
      }
    )
  }

}
