import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from './httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class SustainabilityMeasuresService {
  apiUrl: string;

  constructor(private httpClient: HttpclientService){
    this.apiUrl = environment.apiUrl;
  }

  findApi(page: number, pageSize:  number): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/coop/sustainability_measures?page=${page}&pageSize=${pageSize}`);
  }
  createApi(sustainabilityMeasures: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/coop/sustainability_measures`, sustainabilityMeasures);
  }
  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/coop/sustainability_measures/${id}`);
  }
  updateApi(id: number, sustainabilityMeasures: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/coop/sustainability_measures/${id}`, sustainabilityMeasures)
  }
  findByIdApi(id: number): Observable <any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/coop/sustainability_measures/${id}`)
  }
  getVoluntaryApi(): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/organization/coop/voluntary`)
  }

}