import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { DataVisualizationService } from 'src/app/services/data-visualization.service';

@Component({
   selector: 'app-data-visualization',
   templateUrl: './data-visualization.component.html',
   styleUrls: ['./data-visualization.component.scss']
})
export class DataVisualizationComponent implements OnInit {
   userDataResult: any[];

   //fisher
   topFisherData: number[];
   topFisherCategories: string[];

   //species
   totalSpeciesDataCatch: any[];
   totalSpeciesDataPrice: any[];
   totalSpeciesDataWeight: any[];

   //boats
   topBoatName: string[];
   totalSpeciesCaughtSeriesData: any[];



   highcharts = Highcharts;
   memberCharts = {};
   fisherCharts = {};
   speciesChartValue = {};
   speciesChartWeight = {};
   speciesChartCaught = {};
   boatsCharts = {};
   flagMemberChart: boolean;
   flagFisherChart: boolean;
   flagSpeciesChart: boolean;
   flagBoatsChart: boolean;
   totalUser: number;

   startDate;
   endDate;



   constructor(private dataSrv: DataVisualizationService) {
      this.userDataResult = [];
      this.topFisherCategories = [];
      this.topFisherData = [];
      this.flagMemberChart = false;
      this.flagFisherChart = false;
      this.flagSpeciesChart = false;
      this.flagBoatsChart = false;
      this.totalUser = 0;
      this.totalSpeciesDataCatch = [];
      this.totalSpeciesDataPrice = [];
      this.totalSpeciesDataWeight = [];
      this.totalSpeciesCaughtSeriesData = [];
   }

   ngOnInit() {
      this.loadData();
   }

   loadData() {
      this.userDataResult = [];
      this.topFisherData = [];
      this.totalUser = 0;
      this.totalSpeciesDataCatch = [];
      this.totalSpeciesDataPrice = [];
      this.totalSpeciesDataWeight = [];
      this.totalSpeciesCaughtSeriesData = [];
      this.dataSrv.getTotalUser(this.startDate, this.endDate).subscribe(
         result => {
            let keys = Object.keys(result);
            keys.forEach((key) => {
               if (key == 'totalMale') {
                  this.userDataResult.push(["Hombres", result[key]]);
               }
               if (key == 'totalFemale') {
                  this.userDataResult.push(["Mujeres", result[key]])
               }
               if (key == 'totalNoSpec') {
                  this.userDataResult.push(["No especificar", result[key]])
               }
               if (key == 'total') {
                  this.totalUser = result[key];
               }

            })
            this.flagMemberChart = true;
            this.memberChart(this.userDataResult);
         },
         err => {
            console.log(err);
         },
      )

      this.dataSrv.getTotalFisher(this.startDate, this.endDate).subscribe(
         res => {
            let object = res.forEach((result) => {
               this.topFisherData.push(parseInt(result['totalSpecies']))
               this.topFisherCategories.push(result['first_name'] + ' ' + result['last_name'])
            })
            this.flagFisherChart = true;
            this.fisherChart();
         },
         err => {
            console.log(err);
         }
      )

      this.dataSrv.getTotalSpeciesApi(this.startDate, this.endDate).subscribe(
         res => {
            res.forEach((result) => {
               let keys = Object.keys(result);
               keys.forEach((key) => {
                  if (key == 'totalCatch') {
                     this.totalSpeciesDataCatch.push([result['common_name'], parseInt(result[key])])
                  }
                  if (key == 'totalPrice') {
                     this.totalSpeciesDataPrice.push([result['common_name'], parseInt(result[key])])
                  }
                  if (key == 'totalWeight') {
                     this.totalSpeciesDataWeight.push([result['common_name'], parseInt(result[key])])
                  }
               })
            })
            this.flagSpeciesChart = true;
            this.specieChart();
         },
         err => {
            console.log(err);
         }
      )

      this.dataSrv.getTotalBoats(this.startDate, this.endDate).subscribe(
         res => {
            this.topBoatName = res.boats;
            this.totalSpeciesCaughtSeriesData = res.data;
            this.flagBoatsChart = true;
            this.boatCharts();
         },
         err => {
            console.log(err);
         }
      )
   }

   resetInputs() {
      this.startDate = '';
      this.endDate = ''
      this.loadData();
   }

   memberChart(data) {
      this.memberCharts = {

         chart: {
            plotBorderWidth: null,
            plotShadow: false,
            renderTo: 'container',
            type: 'pie',
         },
         subtitle: {
            text: 'Número de socios',
            style: {
               fontSize: '18px'
            }
         },
         credits: {
            enabled: false
         },
         title: {
            text: `<strong>${this.totalUser}</strong>`,
            align: 'center',
            verticalAlign: 'middle',
            y: 33
         },
         tooltip: {
            pointFormat: '<b>{point.y}</b>'
         },
         plotOptions: {
            pie: {
               shadow: false,
               center: ['50%', '50%'],
               size: '60%',
               innerSize: '50%',
               dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.y}',
               }
            }
         },
         series: [
            {
               type: 'pie',
               data: this.userDataResult
            }
         ]
      };
   }

   fisherChart() {
      this.fisherCharts = {
         chart: {
            type: 'bar'
         },
         title: {
            text: 'Pescadores con mayores capturas'
         },
         legend: {
            align: 'center',
            verticalAlign: 'bottom',
            x: 0,
            y: 0,
            shadow: true
         },
         xAxis: {
            categories: this.topFisherCategories, title: {
               text: null
            }
         },
         yAxis: {
            min: 0, title: {
               text: '', align: 'high'
            },
            labels: {
               overflow: 'justify'
            }
         },
         plotOptions: {
            bar: {
               dataLabels: {
                  enabled: true
               },
            }
         },
         credits: {
            enabled: false
         },

         series: [
            {
               name: 'Número total de especies capturadas',
               data: this.topFisherData
            },
         ]
      };
   }


   specieChart() {
      this.speciesChartWeight = {
         chart: {
            plotBorderWidth: null,
            plotShadow: false,
         },
         credits: {
            enabled: false
         },
         title: {
            text: ''
         },
         tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b>'
         },
         plotOptions: {
            pie: {
               allowPointSelect: true,
               cursor: 'pointer',
               dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.y}',
               }
            }
         },
         series: [{
            type: 'pie',
            name: 'Peso',
            data: JSON.parse(JSON.stringify(this.totalSpeciesDataWeight))
         }]
      }


      this.speciesChartValue = {
         chart: {
            plotBorderWidth: null,
            plotShadow: false
         },
         credits: {
            enabled: false
         },
         title: {
            text: ''
         },
         tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b>'
         },
         plotOptions: {
            pie: {
               allowPointSelect: true,
               cursor: 'pointer',
               dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.y}',
               }
            }
         },
         series: [{
            type: 'pie',
            name: 'Precio',
            data: JSON.parse(JSON.stringify(this.totalSpeciesDataPrice)),
         }]
      }
      this.speciesChartCaught = {
         chart: {
            plotBorderWidth: null,
            plotShadow: false
         },
         credits: {
            enabled: false
         },
         title: {
            text: ''
         },
         tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b>'
         },
         plotOptions: {
            pie: {
               allowPointSelect: true,
               cursor: 'pointer',
               dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.y}',
               }
            }
         },
         series: [{
            type: 'pie',
            name: 'Captura',
            data: JSON.parse(JSON.stringify(this.totalSpeciesDataCatch))
         }]
      }
   }

   boatCharts() {
      let boatchartTemp = {
         chart: {
            type: 'bar'
         },
         title: {
            text: 'Embarcaciones con mayores capturas'
         },
         legend: {
            align: 'center',
            verticalAlign: 'bottom',
            x: 0,
            y: 0,
         },
         xAxis: {
            categories: this.topBoatName, title: {
               text: null
            }
         },
         yAxis: {
            min: 0, title: {
               text: '', align: 'high'
            },
            labels: {
               overflow: 'justify'
            }
         },
         plotOptions: {
            series: {
               stacking: 'normal'
            }
         },
         credits: {
            enabled: false
         },
         series: []
      };
      this.totalSpeciesCaughtSeriesData.forEach((data) => {
         console.log(`[data.total] -> `, data.total);
         boatchartTemp.series.push({
            name: data.name,
            data: data.total
         })
      })
      this.boatsCharts = boatchartTemp;

   }
}
