import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { CertificatesService } from 'src/app/services/certificates.service';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { SpeciesService } from 'src/app/services/species/species.service';
// import { SpeciesService } from 'src/app/services/species.service';

@Component({
  selector: 'app-add-certificate',
  templateUrl: './add-certificate.component.html',
  styleUrls: ['./add-certificate.component.scss']
})
export class AddCertificateComponent implements OnInit {
  public form: FormGroup;

  public submitted: boolean = false;

  public specieName = new FormControl('');

  public selectedCertificate;
  public species: [];
  selectedSpecies: any[];

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  public isEditComponent: boolean;

  @ViewChild('fruitInput', { static: true }) fruitInput: ElementRef<HTMLInputElement>;

  public certificates: []
  
  constructor(
    private fb: FormBuilder,
    private matSnackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddCertificateComponent>,
    private certificateService: CertificatesService,
    private specieService: SpeciesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.certificates = []
    this.species = [];
    this.selectedSpecies = [];
    this.isEditComponent = false;
    this.form = fb.group({
      'specieName': this.specieName
    })
   }

  ngOnInit() {

    if(this.data && this.data.isEditComponent) {
      this.isEditComponent = true;

      this.selectedCertificate = this.data.certification.fishery.id;
      this.selectedSpecies = this.data.certification.species
      
    }
    this.certificateService.getFisheryCertification()
      .subscribe(
        response => {
          this.certificates = response;
        },
        err => {
          console.log(err);
        }
      )

      let specieName = this.form.get('specieName');

      specieName.valueChanges.pipe(
        startWith(null),
        debounceTime(400),
        distinctUntilChanged(),
      )
      .subscribe(res => {
        this.filterSpecies();
      })
  }

  get f(): any { return this.form.controls; }

  filterSpecies() {
    let specieName = this.form.get('specieName').value

    if(specieName != "" && specieName != null) {
      this.specieService.typeaheadApi(specieName, '').subscribe(
        res => {
          this.species = res
        },
        err => {
          console.log(err);
        }
      )
    }
  }

  submit() {
    this.submitted = true;
    let finishedCertificates = false;

    let certificate = {
        fishery_certification_id: this.selectedCertificate,
        // species_id: []
    }

    // if(this.selectedSpecies.length != 0) {
    //   for(let req of this.selectedSpecies) {
    //     certificate.species_id.push(req.id)
    //   }
    // }

    const selectedSpeciesHaveZeroSpecies = this.selectedSpecies.length == 0;
    if (selectedSpeciesHaveZeroSpecies) {
      this.matSnackBar.open('Deber agregar al menos una especie', 'Cerrar', {duration: 3000})
      return;
    }

    if(this.isEditComponent) {
      this.certificateService.updateApi(this.data.certification.id, certificate).subscribe(
        response => {
          if(response.confirmation == true) {
            if(this.selectedSpecies.length != 0) {
              let request = {
                certification_id: response.certificates.id,
                species_id: [],
              }
  
              for(let req of this.selectedSpecies) {
                request.species_id.push(req.id)
              }
  
              
                this.certificateService.createSpecies(request).subscribe(
                  res => {
                    console.log(res);
                    finishedCertificates = true;
                  },
                  err => {
                    console.log(err);
                    this.matSnackBar.open('Error al asociar especies', 'Cerrar', {duration: 3000})
                    return;
                  }
                );
  
              
            }
            this.matSnackBar.open('Certificación actualizado con éxito', 'Cerrar', {duration: 4000});
          } 
          this.dialogRef.close();
        },
        err => {
          if(err.error.confirmation == false) {
            this.matSnackBar.open('Error: ' + err.error.message, 'Cerrar', {duration: 4000});
          }
        }
      )
      return;
    }
    
     this.certificateService.createApi(certificate).subscribe(
      response => {

        if(response.confirmation) {
          if(this.selectedSpecies.length != 0) {
            let request = {
              certification_id: response.data.id,
              species_id: [],
            }

            for(let req of this.selectedSpecies) {
              request.species_id.push(req.id)
            }

            
              this.certificateService.createSpecies(request).subscribe(
                res => {
                  console.log(res);
                  finishedCertificates = true;
                },
                err => {
                  console.log(err);
                  this.matSnackBar.open('Error al asociar especies', 'Cerrar', {duration: 3000})
                  return;
                }
              );

            
          }
          this.matSnackBar.open('certificate sanitaria agregado con éxito', 'Cerrar', { duration: 3000 })
        }
        this.dialogRef.close();
      },
      err => {
        this.matSnackBar.open('Error: fasfsadfasdfasd' + err.error.message, 'Cerrar', {duration: 4000})
      }
    )
  }

  remove(index): void {
    if (index >= 0) {
      this.selectedSpecies.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if(this.selectedSpecies.length >= 5) {
      this.matSnackBar.open('Maximum of species assigned is 5!', 'Cerrar', {duration: 4000})
      return;
    }
    this.selectedSpecies.push({
      id: event.option.value,
      common_name: event.option.viewValue
    });

    this.fruitInput.nativeElement.value = '';
    this.specieName.setValue(null);
  }

  close(): void {
    this.dialogRef.close();
  }

}
