import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpclientService } from '../httpclient.service';
import { environment } from '../../../environments/environment'
import { ListUsersByKeyWordRequest } from './interface.ListUsersByKeyword.request';
import { ListUsersByKeyWordResponse } from './interface.ListUsersByKeyword.response';
import { CreateUserRequest } from './interface.create-user.request';
import { CreateUserResponse, catchDiferentResponses } from './interface.create-user.response';
import { User } from 'src/app/models/models_fixed/babel.models';
import { UpdateUserResponse, catchUpdateUserResponses } from './interface.update-user.response';
import { ListUsersByFiltersRequest } from './interface.list-users-by-filters.request';
import { ListUsersByFiltersResponse } from './interface.list-users-by-filters.response';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private static API: string = environment.apiUrl;

  constructor(
    private httpClientService: HttpclientService
  ) { }


  //============================================================
  // CREATE
  //============================================================


  public async createUser(user: CreateUserRequest): Promise<CreateUserResponse> {

    try {


      // Need this to fix object before sending to backend
      Object.keys(user).forEach((key, index) => {
        if (user[key] === '' || user[key] === 0 || user[key] == undefined || user[key] == null) {
          delete user[key]
        }
      });

      const response = await this.httpClientService.postWithAuth(`${UserService.API}/user`, user).toPromise();

      return catchDiferentResponses(response);

    } catch (error) {

      return catchDiferentResponses(error.error);

    }

  }

  //============================================================
  // READ
  //============================================================

  public async getListUsersByKeyWord(request: ListUsersByKeyWordRequest): Promise<ListUsersByKeyWordResponse> {

    try {

      const queryCooperativaKeys = Object.keys(request);
      const partsQuery = queryCooperativaKeys.map((key: string) => `${key}=${request[key]}`);
      let urlBody = partsQuery.join('&');

      const urlFormed = `${UserService.API}/user?${urlBody}`;
      const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();

      return response as ListUsersByKeyWordResponse;

    } catch (error) {
      return { count: 0, rows: [] }
    }

  }

  public async getUserById(userID: number): Promise<User | null> {

    try {

      const urlFormed = `${UserService.API}/user/${userID}`;
      const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();
      return response as User;

    } catch (error) {

      console.error(error);
      return null;

    }

  }

  public async listUsersByFilters(request: ListUsersByFiltersRequest): Promise<ListUsersByFiltersResponse> {

    try {

      const queryCooperativaKeys = Object.keys(request);
      const partsQuery = queryCooperativaKeys.map((key: string) => `${key}=${request[key]}`);
      let urlBody = partsQuery.join('&');

      // {{local}}/user?page=0&pageSize=10&role=co-admin
      const urlFormed = `${UserService.API}/user?${urlBody}`;
      const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();

      return response as ListUsersByFiltersResponse;

    } catch (error) {

      return {
        count: 0,
        rows: [],
      }

    }

  }

  //============================================================
  // UPDATE
  //============================================================

  public async updateUser(userID: number, userRequest: User): Promise<UpdateUserResponse> {

    try {

      // Need this to fix object before sending to backend
      Object.keys(userRequest).forEach((key, index) => {
        if (userRequest[key] === '' || userRequest[key] === 0 || userRequest[key] == undefined || userRequest[key] == null) {
          delete userRequest[key]
        }
      });

      const urlFormed = `${UserService.API}/user/${userID}`;

      const response = await this.httpClientService
        .putWithAuth(urlFormed, userRequest)
        .toPromise();

      return catchUpdateUserResponses(response);

    } catch (error) {

      return catchUpdateUserResponses(error.error);

    }
  }

  //============================================================
  // DELETE
  //============================================================

  public async archiveUserByID(id: number, body): Promise<any> {

    try {

      const urlFormed = `${UserService.API}/user/${id}/archive`;
      const response: any = await this.httpClientService
        .putWithAuth(urlFormed, body)
        .toPromise();

      return { success: response.confirmation }

    } catch (error) {

      return { success: false }

    }

  }

  // * ✅✅✅

  findApi(page: number, pageSize: number, querySearch: string = '', orgId: string = ''): Observable<any> {
    return this.httpClientService.getWithAuth(`${UserService.API}/user?page=${page}&pageSize=${pageSize}&querySearch=${querySearch}&orgId=${orgId}`);
  }

  createApi(user: any): Observable<any> {
    return this.httpClientService.postWithAuth(`${UserService.API}/user`, user)
  }

  deleteApi(id: number, body: any): Observable<any> {
    return this.httpClientService.postWithAuth(`${UserService.API}/user/${id}`, body)
  }

  typeaheadApi(name: string): Observable<any> {
    return this.httpClientService.getWithAuth(`${UserService.API}/user/typeahead/${name}`)
  }

  typeaemailApi(email: string): Observable<any> {
    return this.httpClientService.getWithAuth(`${UserService.API}/user/typeaemail/${email}`)
  }

  updateApi(id: number, user: any): Observable<any> {
    return this.httpClientService.putWithAuth(`${UserService.API}/user/${id}`, user)
  }

  archiveApi(id: number, body: any): Observable<any> {
    return this.httpClientService.putWithAuth(`${UserService.API}/user/${id}/archive`, body)
  }



  uploadApi(files): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('user', files, files.name);
    return this.httpClientService.sentFileWithAuth(`${UserService.API}/user/upload`, formData)
  }
}
