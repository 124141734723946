import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { Community } from 'src/app/models/community';
import { CommunityService } from 'src/app/services/community/community.service';
import { EmergencyContactService } from 'src/app/services/emergency-contact.service';

@Component({
  selector: 'app-add-emergency-contact',
  templateUrl: './add-emergency-contact.component.html',
  styleUrls: ['./add-emergency-contact.component.scss']
})
export class AddEmergencyContactComponent implements OnInit {
  public emergencyContactForm: FormGroup;
  

  public name = new FormControl('', Validators.required);
  public home = new FormControl('', Validators.required);
  public type = new FormControl('');
  public grade = new FormControl('');
  public position = new FormControl('');
  public email = new FormControl('', Validators.required);
  public operation = new FormControl('')
  public communityName = new FormControl(undefined, Validators.required);
  public cisco: FormArray;
  public phone: FormArray;

  submitted: boolean;
  communities: Community[];
  isEditComponent: boolean;

  filteredOptions: Observable<string[]>;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddEmergencyContactComponent>,
    private emergencyContactService: EmergencyContactService,
    private matSnackBar: MatSnackBar,
    private communityService: CommunityService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    this.emergencyContactForm = fb.group({
      'name': this.name,
      'home': this.home,
      'type': this.type,
      'grade': this.grade,
      'position': this.position,
      'email': this.email,
      'operation': this.operation,
      'communityName': this.communityName,
      'cisco': new FormArray([new FormControl('')]),
      'phone': new FormArray([new FormControl('')])
    })

    this.submitted = false;
    this.isEditComponent = false;

    
   }

  ngOnInit() {
    let queryName = this.emergencyContactForm.get('communityName');
    queryName.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    )
    .subscribe(res => {
      this.filterCommunity();
    })


    if(this.data && this.data.isEditComponent) {
      this.isEditComponent = true;
      this.emergencyContactForm.controls['name'].setValue(this.data.currentEmergencyContact.name);
      this.emergencyContactForm.controls['home'].setValue(this.data.currentEmergencyContact.home);
      this.emergencyContactForm.controls['type'].setValue(this.data.currentEmergencyContact.type);
      this.emergencyContactForm.controls['grade'].setValue(this.data.currentEmergencyContact.grade);
      this.emergencyContactForm.controls['position'].setValue(this.data.currentEmergencyContact.position);
      this.emergencyContactForm.controls['email'].setValue(this.data.currentEmergencyContact.email);
      this.emergencyContactForm.controls['operation'].setValue(this.data.currentEmergencyContact.operation);
      this.communityService.findByIdApi(this.data.currentEmergencyContact.community_id).subscribe(
        res => {
          this.emergencyContactForm.controls['communityName'].setValue(res.name);
        },
        err => {
          console.log(err);
        }
      )
      this.emergencyContactForm.setControl('cisco', this.fb.array(this.data.currentEmergencyContact.cisco || []))
      this.emergencyContactForm.setControl('phone', this.fb.array(this.data.currentEmergencyContact.phone || []))
    }

  }

  get f(): any { return this.emergencyContactForm.controls; }

  filterCommunity() {
    let communityName = this.emergencyContactForm.get('communityName').value
    if(communityName != "") {
      this.communityService.typeAheadApi(communityName).subscribe(
        res => {
          this.communities = res;
        },
        err => {
          console.log(err);
        }
      )
    }
  }

  addPhone() {
    const phone =  new FormControl('');
    (<FormArray>this.emergencyContactForm.controls['phone']).push(phone)
  }

  addCisco() {
    const cisco =  new FormControl('');
    (<FormArray>this.emergencyContactForm.controls['cisco']).push(cisco)
  }

  removePhone(i){
    (<FormArray>this.emergencyContactForm.get('phone')).removeAt(i);
  }

  removeCisco(i) {
    (<FormArray>this.emergencyContactForm.get('cisco')).removeAt(i);
  }


  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this.submitted = true;
    let name = this.emergencyContactForm.get('name').value;
    let home = this.emergencyContactForm.get('home').value;
    let email = this.emergencyContactForm.get('email').value;
    let communityName = this.emergencyContactForm.get('communityName').value;
    let community_id;

    
    if(communityName != "") {
      for(let com of this.communities) {
        if(com.name === communityName) {
          community_id = com.id;
        }
      }
    }

    if(name == "") {
      this.matSnackBar.open("Ingresa Nombre." , 'Cerrar', {duration: 4000})
      return;
    }

    if(home == "") {
      this.matSnackBar.open("Ingresa Domicilio", 'Cerrar', {duration: 4000})
      return;
    }

    if(email == "") {
      this.matSnackBar.open("Ingresa Correo", 'Cerrar', {duration: 4000})
      return
    }

    

    let emergencyContact = {
      name: name,
      home: home,
      email: email,
      community_id: community_id,
      type: this.emergencyContactForm.get('type').value,
      grade: this.emergencyContactForm.get('grade').value,
      position: this.emergencyContactForm.get('position').value,
      operation: this.emergencyContactForm.get('operation').value,
      cisco: this.emergencyContactForm.get('cisco').value,
      phone: this.emergencyContactForm.get('phone').value 
    }
    if(this.isEditComponent) {
      this.emergencyContactService.updateApi(this.data.currentEmergencyContact.id, emergencyContact).subscribe(
        response => {
          if(response.confirmation) {
            this.matSnackBar.open('Emergency Contact successfully', 'Cerrar', {duration: 4000})
          }

          this.dialogRef.close();
        },
        err => {
          console.log(err);
          if(err.error.confirmation == false) {
            this.matSnackBar.open('Error: ' + err.error.message, 'Cerrar', {duration: 4000})
          }
        }
      )
      return;
    }

    this.emergencyContactService.createApi(emergencyContact).subscribe(
      response => {
        if(response.confirmation) {
          this.matSnackBar.open("Emergency contact added successfully", 'Cerrar', {duration: 4000})
        }
        this.dialogRef.close();
      },
      err => {
        console.log(err);
        if(!err.error.confirmation) {
          this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000} )
        }
      }
    )
  }

}
