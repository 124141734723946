import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from './httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class BadgesService {
  apiUrl: string;

  constructor(private httpClient: HttpclientService){
    this.apiUrl = environment.apiUrl;
  }

  findApi(page: number, pageSize:  number): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/coop/badges?page=${page}&pageSize=${pageSize}`);
  }
  createApi(badge: any): Observable<any> {
    return this.httpClient.postWithAuth(`${this.apiUrl}/coop/badges`, badge);
  }
  deleteApi(id: number): Observable<any> {
    return this.httpClient.deleteWithAuth(`${this.apiUrl}/coop/badges/${id}`);
  }
  updateApi(id: number, badge: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/coop/badges/${id}`, badge)
  }
  findByIdApi(id: number): Observable <any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/coop/badges/${id}`)
  }
  getFaoBadges(): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/coop/badges/fao`)
  }
  getSdgBadges(): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/coop/badges/sdg`)
  }

}