
import { CreateUserResponseErrorEmailUserName, CreateUserResponseErrorIsMinor, CreateUserResponseErrorLimitOfAdmins, UpdateUserResponseErrorInternalServer } from './interface.create-user.response';
import { UserResponseType } from "./type.user-response";


//============================================================
// responses service
//============================================================

// errors

// success
export interface UpdateUserResponseServer {
    confirmation: boolean;
    organizationId: number;
}


//============================================================
// responses local
//============================================================


export interface UpdateUserResponse {
    success: boolean;
    responseType: UserResponseType;
    message?: string,
}


//============================================================
// catch diferent responses
//============================================================

export const catchUpdateUserResponses = (response): UpdateUserResponse => {

    try {


        const updateUserResponseServer = response as UpdateUserResponseServer;
        if (updateUserResponseServer.confirmation == true) {
            return {
                success: true,
                responseType: "SUCCESS",
            }
        }

        if (updateUserResponseServer.confirmation == false) {
            return {
                success: false,
                responseType: "UNRECOGNIZED_UPDATE_ERROR",
            }
        }


        const isUpdateUserResponseErrorInternalServer = (response): response is UpdateUserResponseErrorInternalServer =>
            "message" in response && response.message === "Internal Server Error" &&
            "stack" in response

        if (isUpdateUserResponseErrorInternalServer(response)) {
            return {
                success: false,
                responseType: "UNRECOGNIZED_UPDATE_ERROR",
                message: "Algo salió mal, no se pudo actualizar la información, inténtalo nuevamente."
            }
        }



        const isCreateUserResponseErrorLimitOfAdmins = (response): response is CreateUserResponseErrorLimitOfAdmins =>
            "success" in response &&
            "limit" in response &&
            "message" in response;
        if (isCreateUserResponseErrorLimitOfAdmins(response))
            return {
                success: false,
                responseType: "LIMIT_ADMINS_COOPERATIVA",
                message: "Limite de Administradores de Cooperativa alcanzado."
            }



        const isCreateUserResponseErrorEmailUserName = (response): response is CreateUserResponseErrorEmailUserName =>
            "success" in response &&
            "message" in response;

        if (isCreateUserResponseErrorEmailUserName(response))
            return {
                success: false,
                responseType: "INVALID_USERNAME_EMAIL",
                message: "Correo electrónico ya en uso."
            }



        const isCreateUserResponseErrorIsMinor = (response): response is CreateUserResponseErrorIsMinor =>
            "message" in response &&
            "stack" in response;

        if (isCreateUserResponseErrorIsMinor(response)) {
            return {
                success: false,
                responseType: "IS_A_MINOR",
                message: "Fecha inválida, esta fecha de nacimiento corresponde a la de un menor de edad."
            }
        }



        return {
            success: false,
            responseType: "UNRECOGNIZED_UPDATE_ERROR",
            message: "Algo salió mal, no se pudo actualizar la información, inténtalo nuevamente."
        }

    } catch (error) {


        return {
            success: false,
            responseType: "UNRECOGNIZED_UPDATE_ERROR",
            message: "Algo salió mal, no se pudo actualizar la información, inténtalo nuevamente."
        }

    }

}