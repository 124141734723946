// connection.service.ts
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  private onlineStatus = new Subject<boolean>();

  constructor() {
    this.onlineStatus.next(window.navigator.onLine);
    window.addEventListener('online', () => this.onlineStatus.next(true));
    window.addEventListener('offline', () => this.onlineStatus.next(false));
  }

  getOnlineStatus(): Observable<boolean> {
    return this.onlineStatus.asObservable();
  }
}
