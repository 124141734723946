import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpclientService } from './httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  apiUrl: string;

  constructor(private httpClient: HttpclientService) {
    this.apiUrl = environment.apiUrl;
  }
  
  findApi(): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/survey`);
  }

  updateOrCreateApi(survey: any): Observable<any> {
    return this.httpClient.putWithAuth(`${this.apiUrl}/survey`, survey);
  }

  findOrganizationSurveys(page = 0, pageSize = 50, search = ""): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/survey/answers?page=${page}&pageSize=${pageSize}&search=${search}`)
  }

  getQuestionsAnalytics(page = 0, pageSize = 50): Observable<any> {
    return this.httpClient.getWithAuth(`${this.apiUrl}/survey/questions?page=${page}&pageSize=${pageSize}`)
  }
}
