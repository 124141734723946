import { E } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatListOption, MatSelectionList, MatSelectionListChange, MatSnackBar } from '@angular/material';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { SurveyService } from 'src/app/services/survey.service';
import { SurveyQuestionSelectionEnum } from './enums/survey-question-selection.enum';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss']
})

export class SurveysComponent implements  AfterViewInit {
  public surveys: any[];
  userSession: User;
  productionOptions: string[] = [];
  customSelections: string[] = [];
  customSelections1: string[] = [];
  constructor(
    private surveyService: SurveyService,
    private _authSvc: AuthService,
    private matSnackBar: MatSnackBar,
  ) {

    let us = this._authSvc.getUserData();
    if (!us) {
      this.matSnackBar.open('Error loading user session.', 'Cerrar', {duration: 4000});
      return;
    }
    this.userSession = us;
    this.surveys = [];
  }

  ngAfterViewInit() {
    this.loadData();
  }

  loadData() {
    this.surveyService.findApi().subscribe(
      response => {
        this.surveys = response.map(survey => {
          survey["hide"] = false;
          return survey;
        })

        if(!!this.surveys[0].answer) {
          this.filterSurveysBySelection(this.surveys[0].answer.value, this.surveys, this.surveys[52].answer.value, this.surveys[58].answer.value)
        }

        if(!!this.surveys[52].answer) {
          this.filterSurveysBySelection(this.surveys[0].answer.value, this.surveys, this.surveys[52].answer.value, this.surveys[58].answer.value)
        }

        if(!!this.surveys[58].answer) {
          this.filterSurveysBySelection(this.surveys[0].answer.value, this.surveys, this.surveys[52].answer.value, this.surveys[58].answer.value)
        }
      },
      err => {
        console.log(err);
      }
    )
  }


  addSurveyTexts(survey, value) {
    const surveyAnswer = {
      organization_id: this.userSession.organization_id,
      survey_question_id: survey.id,
      value: [value],
      answer_types: survey.option_types,
      position: survey.position
    }

    
    this.surveyService.updateOrCreateApi(surveyAnswer).subscribe(
      response => { },
      err => { console.log(err) }
    )
  }

  addSurveySingleSelect(survey, value) {
    if (survey.position == 53) {
      this.customSelections = [value]
      this.filterSurveysBySelection(this.productionOptions, this.surveys, this.customSelections, this.customSelections1)
    }


    const surveyAnswer = {
      organization_id: this.userSession.organization_id,
      survey_question_id: survey.id,
      value: [value],
      answer_types: survey.option_types,
      position: survey.position
    }

    this.surveyService.updateOrCreateApi(surveyAnswer).subscribe(
      response => { },
      err => { console.log(err) }
    )
  }

  filterSurveysBySelection(selection: string[], surveys: any[], customSelections: string[], customSelections1: string[]) {

    this.surveys[53]["hide"] = false;
    this.surveys[59]["hide"] = false;
    this.surveys = surveys.map(survey => {  
      if (survey.section == "gender") {
        survey["hide"] = true;
          selection.forEach(selected => {
            switch(true) {
              case selected == SurveyQuestionSelectionEnum.Acopio:
                if ([10,11,12,13].includes(survey.position)) {
                  survey["hide"] = false;
                } 
                break;
              case selected == SurveyQuestionSelectionEnum.Procesamiento:
                if ([14,15,16,17].includes(survey.position)) {
                  survey["hide"] = false;
                } 
                break;
              case selected == SurveyQuestionSelectionEnum.Preembarque:
                if ([22,23,24,25].includes(survey.position)) {
                  survey["hide"] = false;
                } 
                break;
              case selected == SurveyQuestionSelectionEnum.Embarque:
                if ([26,27,28,29].includes(survey.position)) {
                  survey["hide"] = false;
                } 
                break;
              case selected == SurveyQuestionSelectionEnum.Administración:
                if ([30,31,32,33].includes(survey.position)) {
                  survey["hide"] = false;
                } 
                break;
              case selected == SurveyQuestionSelectionEnum.Comercialización:
                if ([34,35,36,37].includes(survey.position)) {
                  survey["hide"] = false;
                } 
                break;
              case selected == SurveyQuestionSelectionEnum.Monitoreo:
                if ([38,39,40,41].includes(survey.position)) {
                  survey["hide"] = false;
                } 
                break;
              case selected == SurveyQuestionSelectionEnum.Vigilancia:
                if ([42, 43, 44, 45].includes(survey.position)) {
                  survey["hide"] = false;
                } 
                break;
              
              default: 
                if ([5,6,7,8,9].includes(survey.position)) {
                 survey["hide"] = false;
                }
            }
            if ([5,6,7,8,9].includes(survey.position)) {
              survey["hide"] = false;
             }
          })

      }
      return survey;
    })

    if(customSelections.includes(SurveyQuestionSelectionEnum.CustomSelection1)) {
      this.surveys[53]["hide"] = true;
    }
   
    if(customSelections1.includes(SurveyQuestionSelectionEnum.CustomSelection2)) {
      this.surveys[59]["hide"] = true;
    }
  }

  addSurveyMultipleSelect(survey, list) {
    
    let selectedOptions = list.selectedOptions.selected.map(item => item.value);

    if (survey.position == 1) {
      this.productionOptions = selectedOptions;
      this.filterSurveysBySelection(this.productionOptions, this.surveys, this.customSelections, this.customSelections1)
    } 
    if (survey.position == 59) {
      this.customSelections1 = selectedOptions;
      this.filterSurveysBySelection(this.productionOptions, this.surveys, this.customSelections, this.customSelections1)
    }

    const surveyAnswer = {
      organization_id: this.userSession.organization_id,
      survey_question_id: survey.id,
      value: selectedOptions,
      answer_types: survey.option_types,
      position: survey.position
    }

    this.surveyService.updateOrCreateApi(surveyAnswer).subscribe(
      response => { },
      err => { console.log(err) }
    )
  }

}
