export class Region {
    id: number;
    name: string;

    constructor() {
        this.id = 0;
        this.name = '';
    }
}

export class RegionList {
    count: number;
    rows: Region[];

    constructor() {
        this.count = 0;
        this.rows = [];
    }
}