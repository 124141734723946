import { Injectable } from '@angular/core';
import { HttpclientService } from './httpclient.service';
import { environment } from '../../environments/environment'
import { Observable, throwError as observableThrowError, throwError } from 'rxjs';
import { Organization } from '../models/organization';
import { catchError, map } from 'rxjs/operators';
import { State } from '../models/state';
import { Country } from '../models/country';
import { Organization as ORGANIZATION } from '../models/models_fixed/babel.models';
import { QueryCooperativa, ResponseSearchByQuery } from '../cooperativas/models/interface.query-cooperativa';
import { CooperativeToCreate } from '../cooperativas/components/cooperativa-form/models/cooperative-to-create';
import { CreateCooperativaResponseOK, ResponseCreateCooperativa } from './cooperativa/interface.resonse.create';
import { ResponseUpdateCooperativaLocal, ResponseUpdateCooperativaServer } from './cooperativa/interface.response.update';
import { ResponseDeleteCooperativa, ResponseDeleteCooperativaServer } from './cooperativa/interface.response.delete';
import { OrganizationWCID } from '../models/models_fixed/interface.organization.-withcommunity';
import { ListByNameRequest } from './cooperativa/Interface.ListByName.request';
import { ListByNameResponse } from './cooperativa/Interface.ListByName.response';
import { DownloadCSVResponseLocal } from '../cooperativas/service/bussines/interface.download-CSV.response';
import { ListCooperativasByFiltersRequest } from './cooperativa/Interface.list-cooperativas-by-filters.request';
import { HttpErrorResponse } from '@angular/common/http';


// ! TODO - CHANGE THE POSITION TO FILE

@Injectable({
  providedIn: 'root'
})
export class CooperativaService {

  private API: string = environment.apiUrl;

  constructor(private httpClientService: HttpclientService) { }

  //============================================================
  // CREATE
  //============================================================

  public async create(CooperativeToCreate: CooperativeToCreate): Promise<ResponseCreateCooperativa> {

    const messageError = "Algo salió mal, no se pudo crear la cooperativa, inténtalo nuevamente.";
    const messageOK = "Cooperativa agregada con éxito.";

    try {

      // Need this to fix object before sending to backend
      Object.keys(CooperativeToCreate).forEach(
        (key, index) => {
          if (CooperativeToCreate[key] === '' || CooperativeToCreate[key] === 0 || CooperativeToCreate[key] == undefined || CooperativeToCreate[key] == null) {
            delete CooperativeToCreate[key]
          }
        }
      );

      const urlFormed = `${this.API}/organization`;
      const response = await this.httpClientService.postWithAuth(urlFormed, CooperativeToCreate).toPromise();
      const createCooperativaResponseOK = response as CreateCooperativaResponseOK;

      const wasSuccess = createCooperativaResponseOK.confirmation;
      const message = wasSuccess ? messageOK : messageError;

      return { wasSuccess: wasSuccess, message: message };

    } catch (error) {

      const responseError = error.error as { success: boolean, message: string }

      let message = messageError;
      if (responseError.message == "The email is invalid") message = "Correo electrónico en uso.";

      return { wasSuccess: false, message: message };

    }

  }

  //============================================================
  // READ
  //============================================================

  public async getListByFilters(filters: ListCooperativasByFiltersRequest): Promise<ListByNameResponse> {

    try {

      const queryCooperativaKeys = Object.keys(filters);
      const partsQuery = queryCooperativaKeys.map((key: string) => `${key}=${filters[key]}`);
      let urlBody = partsQuery.join('&');

      const urlFormed = `${this.API}/organization?${urlBody}`;

      const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();

      return response as ListByNameResponse;

    } catch (error) {

      return {
        count: 0,
        rows: [],
      }

    }

  }

  public async findByTypeAheadApi(query: string): Promise<ORGANIZATION[]> {
    try {
      const urlFormed = `${this.API}/organization/typeahead/${query}`;
      const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();
      return response as ORGANIZATION[];
    } catch (error) {
      return []
    }
  }

  public async findByID(id: number): Promise<ORGANIZATION> {
    try {
      const urlFormed = `${this.API}/organization/${id}`;
      const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();
      return response as ORGANIZATION;
    } catch (error) {
      return null;
    }
  }

  public async searchByQuery(query: QueryCooperativa): Promise<ResponseSearchByQuery> {
    try {

      const queryCooperativaKeys = Object.keys(query);
      const partsQuery = queryCooperativaKeys.map((key: string) => `${key}=${query[key]}`);
      let urlBody = partsQuery.join('&');

      const urlFormed = `${this.API}/organization/directory?${urlBody}`;
      const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();

      return response as ResponseSearchByQuery;

    } catch (error) {
      return { count: 0, rows: [] };
    }
  }

  public async getListByName(request: ListByNameRequest): Promise<ListByNameResponse> {
    try {

      const queryCooperativaKeys = Object.keys(request);
      const partsQuery = queryCooperativaKeys.map((key: string) => `${key}=${request[key]}`);
      let urlBody = partsQuery.join('&');

      const urlFormed = `${this.API}/organization?${urlBody}`;

      const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();

      return response as ListByNameResponse;

    } catch (error) {
      return { count: 0, rows: [] }
    }
  }

  public async getAllOrganization(): Promise<{ count: number, rows: ORGANIZATION[] }> {

    try {

      // {{local}}/organization?pageSize=99999999999&page=0&active=true
      const urlFormed = `${this.API}/organization?pageSize=99999999999&page=0&active=true`;
      const response = await this.httpClientService.getWithAuth(urlFormed).toPromise();
      const responseServer = response as { count: number, rows: ORGANIZATION[] };

      return responseServer

    } catch (error) {

      return { count: 0, rows: [] }

    }

  }

  //============================================================
  // UPDATE
  //============================================================

  public async update(id: number, organization: ORGANIZATION): Promise<ResponseUpdateCooperativaLocal> {

    const messageOK = "Datos actualizados con éxito.";
    const messageError = "Algo salió mal, no se pudo actualizar la información, inténtalo nuevamente.";

    try {
      const urlFormed = `${this.API}/organization/${id}`;
      const updateResponse = await this.httpClientService.putWithAuth(urlFormed, organization).toPromise();

      const responseServer = updateResponse as ResponseUpdateCooperativaServer;

      return {
        wasSuccess: responseServer.confirmation,
        message: responseServer.confirmation ? messageOK : messageError
      };

    } catch (error) {

      return { wasSuccess: false, message: messageError };

    }
  }

  //============================================================
  // DELETE
  //============================================================

  public async delete(id: number): Promise<ResponseDeleteCooperativa> {

    const messageOK = "Cooperativa eliminada.";
    const messageError = "Algo salió mal, no se pudo eliminar la cooperativa, inténtalo nuevamente.";

    try {
      const urlFormed = `${this.API}/organization/${id}`;
      const response = await this.httpClientService.deleteWithAuth(urlFormed).toPromise();
      const responseDeleteCooperativaServer = response as ResponseDeleteCooperativaServer;

      const wasSuccess = responseDeleteCooperativaServer.confirmation;
      const message = wasSuccess ? messageOK : messageError;
      return { wasSuccess, message }
    } catch (error) {
      return { wasSuccess: false, message: messageError };
    }
  }


  //============================================================
  // CSV
  //============================================================

  public async downloadCSV(): Promise<DownloadCSVResponseLocal> {

    const messageSuccess = "El archivo se descargó con éxito.";
    const messageError = "Algo salió mal, no se pudo descargar la información, inténtalo nuevamente.";

    try {

      // {{local}}/organization/export/csv
      const urlFormed = `${this.API}/organization/export/csv`;
      const fileBlob: Blob = await new Promise(
        (resolve, reject) => {

          this.httpClientService.getFileWithAuth(urlFormed)
            .subscribe(
              (res) => {
                const blob = new Blob(
                  [
                    "\ufeff",
                    res.body
                  ],
                  {
                    type: 'text/csv;charset=utf-8'
                  }
                );
                resolve(blob);
              },

              (err) => {
                reject("Not generated")
              }

            );

        }
      );

      const message = fileBlob ? messageSuccess : messageError;

      return {
        wasSuccess: true,
        message: message,
        fileBlob: fileBlob,
      }

    } catch (error) {

      console.log(`[error] -> `, error);

      return {
        wasSuccess: false,
        message: messageError,
      }

    }
  }

  // * ✅✅✅

  findApi(page: number, pageSize: number, search: string = ""): Observable<any> {
    return this.httpClientService.getWithAuth(`${this.API}/organization?page=${page}&pageSize=${pageSize}&search=${search}`);
  }

  createApi(org: any): Observable<any> {
    return this.httpClientService.postWithAuth(`${this.API}/organization`, org)
  }

  deleteApi(id: number): Observable<any> {
    return this.httpClientService.deleteWithAuth(`${this.API}/organization/${id}`)
  }

  updateApi(id: number, org: any): Observable<any> {
    return this.httpClientService.putWithAuth(`${this.API}/organization/${id}`, org)
  }

  typeaheadApi(query: string): Observable<any> {
    return this.httpClientService.getWithAuth(`${this.API}/organization/typeahead/${query}`);
  }

  findByIdApi(id: number): Observable<Organization> {
    return this.httpClientService.getWithAuth(`${this.API}/organization/${id}`)
      .pipe(map(resp => {
        let state: State = new State();
        let country: Country = new Country();
        let org: Organization = new Organization();

        org.id = resp['id'];
        org.email = resp['email'];
        org.name = resp['name'];
        org.phone = resp['phone'];
        org.state_id = resp['state_id'];
        org.country_id = resp['country_id'];
        org.region_id = resp['region_id']
        org.website = resp['website'];
        org.active = resp['active'];
        org.claimed = resp['claimed'];
        org.address = resp['address'];
        org.created_at = resp['created_at'];
        org.description = resp['description'];
        org.closed_season = resp['closed_season'];
        org.quota = resp['quota'];
        org.minimum_landing = resp['minimum_landing'];
        org.maximum_landing = resp['maximum_landing'];
        org.voluntary_reserve = resp['voluntary_reserve'];
        org.voluntary_reserve_id = resp['voluntary_reserve_fk'];
        org.img_url = resp['img_url']

        state.id = resp['state']['id'];
        state.name = resp['state']['name'];
        state.countryId = resp['state']['countryId'];

        country.id = resp['country']['id'];
        country.name = resp['country']['name'];
        country.regionId = resp['country']['regionId'];
        org.state = state;
        org.country = country
        org.organizationDetails = resp['OrganizationDetails']
        return org;
      }));
  }



  findByIdApiWithCommunityId(id: number): Observable<OrganizationWCID> {
    return this.httpClientService.getWithAuth(`${this.API}/organization/${id}`)
      .pipe(map(resp => {
        let state: State = new State();
        let country: Country = new Country();
        let org: OrganizationWCID = {
          id: resp['id'],
          name: resp['name'],
          email: resp['email'],
          phone: resp['phone'],
          address: resp['address'],
          website: resp['website'],
          state_id: resp['state_id'],
          country_id: resp['country_id'],
          active: resp['active'],
          description: resp['description'],
          claimed: resp['claimed'],
          created_at: new Date(resp['created_at']),
          closed_season: resp['closed_season'],
          quota: resp['quota'],
          minimum_landing: resp['minimum_landing'],
          maximum_landing: resp['maximum_landing'],
          voluntary_reserve: resp['voluntary_reserve'],
          voluntary_reserve_fk: resp['voluntary_reserve_fk'],
          img_url: resp['img_url'],
          region_id: resp['region_id'],
          community_id: resp['community_id'],
          createdAt: new Date(resp['createdAt']),
          updatedAt: new Date(resp['updatedAt']),
          stateId: resp['state']['id'],
          countryId: resp['country']['id']
        };



        return org;
      }));
  }
}
