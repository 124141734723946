import {HttpClient} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import { BoatService } from '../../../services/boat.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddBoatComponent } from '../add-boat/add-boat.component';
import { MatTable } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DropdownOption } from 'src/app/models/dropdown_option';
import { CooperativaService } from 'src/app/services/organization.service';
import { ADMIN_ROLE } from 'src/app/models/roles';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-boats-table',
  templateUrl: './boats-table.component.html',
  styleUrls: ['./boats-table.component.scss']
})
export class BoatsTableComponent implements AfterViewInit {
  displayedColumns: string[] = ['actions', 'title_number', 'org_name', 'registration_number', 'name', 'rnpa_code', 'motorbrand',
    'motor_hp', 'fishing_permits_details', 'harbor_master_permits_details'];
  data:any = [];
  fishingPermits: any;
  harborMasterPermit: any;

  resultsLength = 0;
  isLoadingResults = true;

  deleteBoatIdHolder;
  deleteDialogRef;
  viewFishingPermitDialogRef;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  userSession: User;
  selectedOrg: string;
  boatName : string;
  registrationNumber: string;
  public orgs: DropdownOption[];
  nameModel: Subject<string> = new Subject<string>();
  regNumberModel: Subject<string> = new Subject<string>();

  public dialogTitle = '¿Estás seguro de que quieres eliminar este embarcaciones?';
  public archiveDialogButton = "Archive";

  constructor(
    private _httpClient: HttpClient,
    private boatService: BoatService,
    public dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private _authSvc: AuthService,
    private _orgSvc: CooperativaService
  ) {
    this.selectedOrg = '';
    this.boatName = '';
    this.registrationNumber = '';
    let us = this._authSvc.getUserData();
    if (!us) {
      this.matSnackBar.open('Error loading user session.', 'Cerrar', {duration: 4000});
      return;
    }
    this.userSession = us;
    this.orgs = [{ title: 'Todas', value: '' }];

    if (this.userSession.role === ADMIN_ROLE) {
      this._orgSvc.findApi(0,100)
        .subscribe(res => {
          for (let org of res.rows) {
            this.orgs.push({ title: org.name, value: org.id.toString() });
          }
        }, err => {
          console.log(err);
          if (err.error.confirmation == false) {
            this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', {duration: 4000});
          }
        });
    }

    this.nameModel.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(res => {
        this.loadData();
      });

    this.regNumberModel.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(res => {
        this.loadData();
      }); 
  }
    
  openAddDialog():void {
    const dialogRef = this.dialog.open(AddBoatComponent, {
      width: '500px',
      height: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  delete(deletedialog, id, isArchive: boolean) {
    this.dialogTitle = '¿Estás seguro de que quieres eliminar este embarcaciones?';
    this.archiveDialogButton = "Archive";
    if (!isArchive) {
      this.dialogTitle =  '¿Estás seguro de que quieres deliminar este embarcaciones?';
      this.archiveDialogButton = 'Unarchive'
    }

    this.deleteBoatIdHolder = id;
    this.deleteDialogRef = this.dialog.open(deletedialog, {
      width: '500px',
    });

    this.deleteDialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  removeBoat(removeDialog, id) {
    this.deleteBoatIdHolder = id;
    this.deleteDialogRef = this.dialog.open(removeDialog, {
      width: '500px',
    });

    this.deleteDialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }


  edit(id):void {
    let currentBoat;
    this.data.forEach(boat => {
      if (boat['id'] == id) {
        currentBoat = boat;
      }
    });
    let editDialogRef = this.dialog.open(AddBoatComponent, {
      width: '500px',
      height: 'auto',
      data: {isEditComponent: true, boat: currentBoat}
    });

    editDialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }
  confirmDelete() {
    this.boatService.archiveApi(this.deleteBoatIdHolder)
      .subscribe(
        result => {
          if (result.confirmation == true) {
            this.loadData()
            this.matSnackBar.open('Boat deleted succesfully', 'Cerrar', {duration: 4000});
            return;
          }
          if (result.confirmation == false) {
            this.matSnackBar.open('Error deleting boat.', 'Cerrar', {duration: 4000});
          }
        },
        err => {
          console.log(err);
        }
      )
    this.deleteDialogRef.close();
  }

  confirmRemove() {
    this.boatService.deleteApi(this.deleteBoatIdHolder)
      .subscribe(
        result => {
          if (result.confirmation == true) {
            this.loadData()
            this.matSnackBar.open('Boat deleted succesfully', 'Cerrar', {duration: 4000});
            return;
          }
          if (result.confirmation == false) {
            this.matSnackBar.open('Error deleting boat.', 'Cerrar', {duration: 4000});
          }
        },
        err => {
          console.log(err);
        }
      )
    this.deleteDialogRef.close();
  }

  cancelDeleteDialog() {
    this.deleteDialogRef.close();
  }

  ngAfterViewInit() {
    this.loadData();
  }

  onOrgSelection() {
    this.loadData();
  }
  onQueryInput() {
    this.nameModel.next(this.boatName);
    this.regNumberModel.next(this.registrationNumber);
  } 


  viewFishingPermit(viewFishingPermitDialog, boat) {
    if(boat.fishingPermits.length != 0) {
      this.fishingPermits = boat.fishingPermits;

      this.viewFishingPermitDialogRef = this.dialog.open(viewFishingPermitDialog, {
        width: '600px',
        height: 'auto'
      })
      this.viewFishingPermitDialogRef.afterClosed().subscribe(result => {
        this.fishingPermits = [];
      })
    }
  }
  viewHarborMasterPermit(harborMasterPermitView, boat) {

      this.harborMasterPermit = boat.harborMaster;

      this.viewFishingPermitDialogRef = this.dialog.open(harborMasterPermitView, {
        width: '600px',
        height: 'auto'
      })
      this.viewFishingPermitDialogRef.afterClosed().subscribe(result => {
        this.harborMasterPermit = {};
      })
    
  }

  cancelFishingPermitDialog() {
    this.viewFishingPermitDialogRef.close();
  }

  

  loadData() {
   merge(this.paginator.page, this.paginator.pageSize)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        return this.boatService.findApi(this.paginator.pageIndex, this.paginator.pageSize, this.selectedOrg, this.boatName, this.registrationNumber);
      }),
      map(data => {
        this.isLoadingResults = false;
        this.resultsLength = data["count"];

        return data.rows;
      }),
      catchError(() => {
        this.isLoadingResults = false;
        return observableOf([]);
      })
    ).subscribe(data => this.data = data);

  }
}
