import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  public static CURRENT_PAGE_DEFAULT = 0;
  public static ITEMS_PEER_PAGE_DEFAULT = 10;

  @Input() public totalItemsFound: number = 0;
  @Input() public itemsPeerPage: number = 10;
  @Input() public currentPage: number = 0;

  public totalPages: number = 0;

  @Output() public itemsPeerPageEventEmitter = new EventEmitter<number>();
  @Output() public currentPageEmitter = new EventEmitter<number>();


  constructor() { }

  ngOnInit() {
    this._updatePaginator(this.itemsPeerPage, this.totalItemsFound);
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.totalItemsFound) {
      this.totalItemsFound = changes.totalItemsFound.currentValue;
      this.currentPage = 0;
    }
    if (changes.itemsPeerPage) this.itemsPeerPage = changes.itemsPeerPage.currentValue;
    if (changes.currentPage) this.currentPage = changes.currentPage.currentValue;

    this._updatePaginator(this.itemsPeerPage, this.totalItemsFound);

  }

  private _updatePaginator(itemsPeerPage: number, totalItemsFound: number) {

    const residue = totalItemsFound % itemsPeerPage
    this.totalPages = Math.trunc(totalItemsFound / itemsPeerPage);
    this.totalPages += residue > 0 ? 1 : 0;

    if (this.currentPage > this.totalPages) {
      this.currentPage = 0;
    }
  }

  public changeItemPeerPage(numberItems: number) {
    this.itemsPeerPageEventEmitter.emit(numberItems);
  }

  public nextPage(page: number = 1) {

    if ((this.currentPage + 1) == this.totalPages) return;
    this.currentPage += page;
    this.currentPageEmitter.emit(this.currentPage);
  }

  public previousPage(page: number = -1) {

    if (this.currentPage == 0) return;
    this.currentPage += page;
    this.currentPageEmitter.emit(this.currentPage);
  }

}