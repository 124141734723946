import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatTable } from '@angular/material';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HealthCertificationService } from 'src/app/services/health-certification.service';
import { CooperativaService } from 'src/app/services/organization.service';
import { AddEditHealthCertificationComponent } from './add-edit-health-certification/add-edit-health-certification.component';

@Component({
  selector: 'app-health-certification',
  templateUrl: './health-certification.component.html',
  styleUrls: ['./health-certification.component.scss']
})
export class HealthCertificationComponent implements AfterViewInit {
  public data: any = [];
  public displayedColumns: string[] = ['actions', 'organization', 'expiration_date', 'renewal_reminder', 'renewal_reminder_date']

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  public isLoadingResults: boolean;
  public resultsLength: number;
  public deleteCertificationIdHolder;
  public deleteDialogRef;

  constructor(
    private _healthCertService: HealthCertificationService,
    public dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private _orgService: CooperativaService,
  ) {
    this.isLoadingResults = true;
    this.resultsLength = 0;
  }
  ngAfterViewInit(): void {
    this.loadData();
  }

  loadData() {
    merge(this.paginator.page, this.paginator.pageSize)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this._healthCertService.findApi(this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data["count"];
          return data.rows;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe((data) => {
        this.data = data;
      })
  }

  edit(id) {
    let currentCertification;
    this.data.forEach(certification => {
      if (certification['id'] == id) {
        currentCertification = certification
      }
    })

    let editDialogRef = this.dialog.open(AddEditHealthCertificationComponent, {
      width: '500px',
      height: 'auto',
      data: { isEditComponent: true, certification: currentCertification }
    })

    editDialogRef.afterClosed().subscribe(result => {
      this.loadData();
    })
  }

  delete(deletedialog, id) {
    this.deleteCertificationIdHolder = id;
    this.deleteDialogRef = this.dialog.open(deletedialog, {
      width: '500px'
    })

    this.deleteDialogRef.afterClosed().subscribe(
      result => {
        this.loadData();
      }
    )
  }

  confirmDelete() {
    this._healthCertService.deleteApi(this.deleteCertificationIdHolder)
      .subscribe(
        result => {
          if (result.confirmation) {
            this.data.foreach((certification, index) => {
              if (certification.id == this.deleteCertificationIdHolder) {
                this.data.splice(index, 1);
                this.table.renderRows();
              }
            })
            this.matSnackBar.open('Certificación sanitaria borrado con éxito', 'Cerrar', { duration: 4000 });
            return;
          }
          if (!result.confirmation) {
            this.matSnackBar.open('Error al borrar certificación sanitaria', 'Cerrar', { duration: 4000 });
          }
        },
        err => {
          console.log(err);
        }
      )
    this.deleteDialogRef.close();
  }

  cancelDeleteDialog() {
    this.deleteDialogRef.close();
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(AddEditHealthCertificationComponent, {
      width: '500px',
      height: 'auto'
    })

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    })
  }

}
