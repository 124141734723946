import { Component, Inject } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoatService } from '../../../services/boat.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CO_ADMIN_ROLE, ADMIN_ROLE, USER_ROLE } from 'src/app/models/roles';
import { DropdownOption } from 'src/app/models/dropdown_option';
import { CooperativaService } from 'src/app/services/organization.service';
import { UserService } from 'src/app/services/users/user.service';
import { Organization } from 'src/app/models/organization';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CountryService } from 'src/app/services/country/country.service';
import { StateService } from 'src/app/services/states/state.service';

@Component({
  selector: 'app-add-boat',
  templateUrl: './add-boat.component.html',
  styleUrls: ['./add-boat.component.scss']
})
export class AddBoatComponent {
  public form: FormGroup;
  public name = new FormControl('', Validators.required);
  public registration_number = new FormControl('', Validators.required);
  public country = new FormControl('', Validators.required);
  public title_number = new FormControl('');
  public expiry = new FormControl('');
  public rnpa_code = new FormControl('');
  public motorbrand = new FormControl('');
  public motor_hp = new FormControl(0);
  public proprietary = new FormControl('');
  public length = new FormControl(0);
  public capacity = new FormControl(0);
  public specialty = new FormControl('');
  public orgName = new FormControl('');
  public organizations: Organization[];
  public selectedOrg: string;
  public countries: DropdownOption[];

  submitted = false;
  isEditComponent: boolean;
  userSession: User;
  public users: DropdownOption[];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddBoatComponent>,
    private boatService: BoatService,
    private matSnackBar: MatSnackBar,
    private authSvc: AuthService,
    private orgSvc: CooperativaService,
    private stateSvc: StateService,
    private countrySvc: CountryService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _userSvc: UserService
  ) {
    this.form = fb.group({
      'name': this.name,
      'registration_number': this.registration_number,
      'title_number': this.title_number,
      'expiry': this.expiry,
      'rnpa_code': this.rnpa_code,
      'motorbrand': this.motorbrand,
      'motor_hp': this.motor_hp,
      'proprietary': this.proprietary,
      'length': this.length,
      'capacity': this.capacity,
      'specialty': this.specialty,
      'orgName': this.orgName,
      'country': this.country
    });
    this.userSession = new User();
    let us = this.authSvc.getUserData();
    if (!us) {
      this.matSnackBar.open('Error loading user session.', 'Cerrar', { duration: 4000 });
    }
    this.userSession = us;
    this.selectedOrg = '';
    this.isEditComponent = false;
    this.users = [{ title: 'Ninguno', value: '' }];
    this.organizations = [];
    this.countries = [];

    if (this.userSession.role === ADMIN_ROLE) {
      let orgName = this.form.get('orgName')
      orgName.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
        .subscribe(res => {
          this.filterOrganization()
        })

    }
    this.countrySvc.findApi(0, 100)
      .subscribe(res => {
        res.rows.forEach((country) => {
          this.countries.push({ title: country.name, value: country.id.toString() })
        })

        if (this.isEditComponent) {
          this.form.controls['country'].setValue(this.data.boat.country_id + '')
        }
      },
        err => {
          console.log(err);
          this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', { duration: 4000 })
        }
      )

    this._userSvc.findApi(0, 100)
      .subscribe(res => {
        for (let user of res.rows) {
          if (user.role == USER_ROLE) {
            this.users.push({ title: user.first_name + ' ' + user.last_name, value: user.id.toString() });
          }
        }
      }, err => {
        console.log(err);
        if (err.error.confirmation == false) {
          this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', { duration: 4000 });
        }
      });
  }

  ngOnInit() {
    if (this.data && this.data.isEditComponent) {
      this.isEditComponent = true;
      this.form.controls['name'].setValue(this.data.boat.name);
      this.form.controls['registration_number'].setValue(this.data.boat.registration_number);
      this.form.controls['title_number'].setValue(this.data.boat.title_number);
      this.form.controls['expiry'].setValue(this.data.boat.expiry);
      this.form.controls['rnpa_code'].setValue(this.data.boat.rnpa_code);
      this.form.controls['motorbrand'].setValue(this.data.boat.motorbrand);
      this.form.controls['motor_hp'].setValue(this.data.boat.motor_hp);
      this.form.controls['proprietary'].setValue(this.data.boat.proprietary);
      this.form.controls['length'].setValue(this.data.boat.length);
      this.form.controls['capacity'].setValue(this.data.boat.capacity);
      this.form.controls['specialty'].setValue(this.data.boat.specialty);
      if (this.data.boat.organization != null) {
        this.form.controls['orgName'].setValue(this.data.boat.organization.name);
      }
      if (this.data.boat.country != null) {
        this.form.controls['country'].setValue(this.data.boat.country)
      }

    }
  }

  close(): void {
    this.dialogRef.close();
  }

  get f(): any { return this.form.controls; }

  filterOrganization() {
    let orgName = this.form.get('orgName').value
    if (orgName != "") {
      this.orgSvc.typeaheadApi(orgName).subscribe(
        res => {
          this.organizations = res;
        },
        err => {
          console.log(err)
        }
      )
    }
  }

  submit(): void {
    this.submitted = true;
    let name = this.form.get('name').value;
    let registrationNumber = this.form.get('registration_number').value;
    let orgName = this.form.get('orgName').value;
    let country = this.form.get('country').value;
    let orgId = null;

    if (orgName != "") {
      for (let org of this.organizations) {
        if (org.name === orgName) {
          orgId = org.id;
        }
      }
    }

    if (name == "") {
      this.matSnackBar.open("You must enter a boat name.", 'Cerrar', { duration: 4000 })
      return;
    }

    if (registrationNumber == "") {
      this.matSnackBar.open("You must enter a registration number.", 'Cerrar', { duration: 4000 })
      return;
    }

    if (country == "") {
      this.matSnackBar.open("You must select country.", 'Cerrar', { duration: 4000 });
      return;
    }

    if (this.form.valid) {
      if (this.userSession.role === CO_ADMIN_ROLE) {
        if (this.userSession.organization_id && this.userSession.organization_id > 0) {
          orgId = this.userSession.organization_id;
        }
      }


      let boat = {
        name: name,
        registration_number: registrationNumber,
        title_number: this.form.get('title_number').value,
        expiry: this.form.get('expiry').value,
        rnpa_code: this.form.get('rnpa_code').value,
        motorbrand: this.form.get('motorbrand').value,
        motor_hp: this.form.get('motor_hp').value,
        proprietary: this.form.get('proprietary').value,
        length: this.form.get('length').value,
        capacity: this.form.get('capacity').value,
        specialty: this.form.get('specialty').value,
        organization_id: orgId,
        country_id: country
      };

      if (this.isEditComponent) {
        this.boatService.updateApi(this.data.boat.id, boat).subscribe(
          response => {
            if (response.confirmation == true) {
              this.matSnackBar.open('Boat updated succesfully', 'Cerrar', { duration: 4000 });
            }
            this.dialogRef.close();
          },
          err => {
            if (err.error.confirmation == true) return;
            if (err.error.message == "rnpa_code must be unique") {
              this.matSnackBar.open("El código RNP debe ser único", 'Cerrar', { duration: 4000 });
              return;
            }
            if (err.error.message == "name must be unique") {
              this.matSnackBar.open("El nombre y la matricula deben ser únicos", 'Cerrar', { duration: 4000 });
              return;
            }
            this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', { duration: 4000 });
          }
        )
        return;
      }

      this.boatService.createApi(boat).subscribe(
        response => {
          if (response.confirmation == true) {
            this.matSnackBar.open('Boat added succesfully', 'Cerrar', { duration: 4000 });
          }
          this.dialogRef.close();
        },
        err => {
          if (err.error.confirmation == true) return;
          if (err.error.message == "rnpa_code must be unique") {
            this.matSnackBar.open("El código RNP debe ser único", 'Cerrar', { duration: 4000 });
            return;
          }
          if (err.error.message == "name must be unique") {
            this.matSnackBar.open("El nombre y la matricula deben ser únicos", 'Cerrar', { duration: 4000 });
            return;
          }
          this.matSnackBar.open("Error: " + err.error.message, 'Cerrar', { duration: 4000 });
        }
      )
    }
  }

}
